
const fieldsMap = [
    {
      id: 1,
      title: "General",
      show: false,
      body: [
        {
          title: "General",
          showTitle: false,
          body: [
            [
              /* {
                key: 12,
                width: 100,
                value: "",
              }, */
              {
                componentType: "multichoice-rel",
                labelId: 12,
                nameId: 6, // lookupTargetFieldId
                forFieldId: 11, // lookupReferenceFieldId
                tableId: "bs7zzssxt", // masterTableId
                key: 11,
                width: 25,
                value: "",
              },
              {
                key: 13,
                width: 100,
                value: "",
              },
            ],
            /* [
              {
                key: 64,
                width: 100,
                value: "",
                editable: true,
              },
            ], */

            [
              {
                componentType: "multichoice-rel",
                labelId: 64,
                nameId: 6, // lookupTargetFieldId
                forFieldId: 9, // lookupReferenceFieldId
                tableId: "bs7zzssuu", // masterTableId
                key: 9,
                width: 25,
                value: "",
              },
            ],
            
            [
              /* {
                key: 14,
                width: 100,
                value: "",
              }, */
              {
                key: 67,
                width: 100,
                value: "",
              },
              {
                key: 68,
                width: 100,
                value: "",
              },
            ],
          ],
        },

        {
          title: '',
          showTitle: false,
          body: [
            [
              {
                key: 71,
                width: 100,
                value: '',
              },
            ],
          ],
        },
  
        {
          title: '',
          showTitle: false,
          body: [
            [
              {
                key: 8,
                width: 100,
                value: '',
              },
            ],
          ],
        },
  
        {
          title: '',
          showTitle: false,
          body: [
            [
              {
                key: 15,
                width: 100,
                value: '',
              },
            ],
          ],
        },

        {
          title: '',
          showTitle: false,
          body: [
            [
              {
                key: 90,
                width: 100,
                value: '',
              },
            ],
          ],
        },
      ],
    },
  ];


export default {
    fieldsMap,
}