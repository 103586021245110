<template>
  <div>
    <div>
      <div>Rank:</div>
      <Multiselect  style="width: 245px; margin: 0"
        v-model="this.filterRanks"
        :options="this.ranks"
        :searchable="true"
        :multiple="true"
        mode="tags"
      />
    </div>
    <div>
      <div>Sizes:</div>
      <Multiselect
        v-model="this.filterSizes"
        :options="this.sizes"
        :searchable="true"
        :multiple="true"
        mode="tags"
        label="name"
        valueProp="id"
      />
    </div>
    <div class="report-body-wrap" v-if="owners.length != 0">
      <div class="report-body">
        <table class="report-table">
          <thead>
            <tr class="report-table__head">
              <th>Owner</th>
              <!-- <th>Quoted</th> -->
              <template v-for="index in quoteColumns" :key="index">
                <th style="display: flex; align-items: center;" v-if="index == quoteColumns && quoteColumns < 5">
                  <div>
                    <div>{{ this.getQuoteColumnDate(index) }}</div>
                    <div>Quoted</div>
                  </div>
                  <div v-if="!this.savingQuoteColumn" 
                    v-on:click="() => {
                      this.addQuoteColumn(index);
                    }"
                    style="padding: 10px; background: #2d3757;
                    border-radius: 2px;
                    color: #ffffff;
                    margin-left: 10px;
                    font-size: 1.2em;
                    cursor: pointer;">
                    +
                  </div>
                  <div v-else>
                    <CustomLoader />
                  </div>
                </th>
                <th v-else>
                  <div>{{ this.getQuoteColumnDate(index) }}</div>
                  <div>Quoted</div>
                </th>
              </template>
              <th>Offer</th>
              <th>Notes</th>
              <th>Date Updated</th>
              <th>Contacts</th>
              <th>Concatenated Email</th>
              <!-- <th>Send</th> -->
              <!-- <th v-for="item in fields" :key="item.id">
              <div>{{ item.label }}</div>
            </th> -->
            </tr>
          </thead>
          <tbody>
            <template v-for="(owner, index) in prepOwners" :key="index">
              <tr>
                <td>{{ owner.name }}</td>
                <template v-if="this.getBestOffer(owner) !== null">
                  
                  <!-- <td>
                    <div style="text-align: center">
                      <input
                        type="checkbox"
                        name=""
                        id=""
                        
                        v-model="this.getBestOffer(owner).approved"
                      />
                    </div>
                  </td> -->
                  <template v-for="indexQuote in quoteColumns" :key="indexQuote">
                    <td>
                      <div style="text-align: center">
                        <input
                          type="checkbox"
                          name=""
                          id=""
                          :checked="getApprovedValue(owner, indexQuote)"
                          @change="updateApprovedCkeckboxValue($event, owner, indexQuote)"
                        />
                      </div>
                      <div style="text-align: center;">
                        {{ priceFormatOffer(getQuotedPrice(owner, indexQuote)) }}
                      </div>
                    </td>
                  </template>
                  <td>
                    <div style="display: flex; justify-content: flex-start; align-items: center;">
                      <template  v-if="this.getQuotedFid(owner) == 27">
                        <div>
                          <!-- <div style="font-size: 12px;">Offer 1</div> -->
                          <div>
                            <CurrencyInput
                              v-model="getQuotedOfferByFid(owner, 27).offer"
                              :options="{ currency: 'USD', precision: 2 }"
                              class="q-currency-input"
                            />
                          </div>
                        </div>
                      </template>

                      <template v-else-if="this.getQuotedFid(owner) == 37">
                        <div>
                          <div>
                            <CurrencyInput
                              v-model="getQuotedOfferByFid(owner, 37).offer"
                              :options="{ currency: 'USD', precision: 2 }"
                              class="q-currency-input"
                            />
                          </div>
                        </div>
                      </template>

                      <template v-else-if="this.getQuotedFid(owner) == 38">
                        <div>
                          <div>
                            <CurrencyInput
                              v-model="getQuotedOfferByFid(owner, 38).offer"
                              :options="{ currency: 'USD', precision: 2 }"
                              class="q-currency-input"
                            />
                          </div>
                        </div>
                      </template>

                      <template v-else-if="this.getQuotedFid(owner) == 39">
                        <div>
                          <div>
                            <CurrencyInput
                              v-model="getQuotedOfferByFid(owner, 39).offer"
                              :options="{ currency: 'USD', precision: 2 }"
                              class="q-currency-input"
                            />
                          </div>
                        </div>
                      </template>

                      <template v-else-if="this.getQuotedFid(owner) == 40">
                        <div>
                          <div>
                            <CurrencyInput
                              v-model="getQuotedOfferByFid(owner, 40).offer"
                              :options="{ currency: 'USD', precision: 2 }"
                              class="q-currency-input"
                            />
                          </div>
                        </div>
                      </template>

                      <template v-else-if="this.getQuotedFid(owner) == 'new'">
                        <div>
                          <div>
                            <CurrencyInput
                              v-model="getQuotedOfferByFid(owner, 40).offer"
                              :options="{ currency: 'USD', precision: 2 }"
                              class="q-currency-input"
                            />
                          </div>
                        </div>
                      </template>

                      <template v-else>
                        <div>
                          <div>
                            <CurrencyInput
                              v-model="getQuotedOfferByFid(owner, 27).offer"
                              :options="{ currency: 'USD', precision: 2 }"
                              class="q-currency-input"
                            />
                          </div>
                        </div>
                      </template>
                  
                      <div v-if="this.prepOffersLog(owner.offers).length >= 2" class="btn-log field-title__btn-log" @click="(e) => {}">
                        <span>!</span>
                        <div class="btn-log__body">
                          <div>
                            <div :key="index" v-for="(value, index) in this.prepOffersLog(owner.offers)">
                              <div>{{ value }}</div>
                              <div>---------------------------</div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <!-- <div class="btn-log field-title__btn-log" @click="(e) => {}">
                        <span>!</span>
                        <div class="btn-log__body">
                          <div v-if="this.prepOffersLog(owner.offers) != 0">
                            <div :key="index" v-for="(value, index) in this.prepOffersLog(owner.offers)">
                              <div>{{ value }}</div>
                              <div>---------------------------</div>
                            </div>
                          </div>
                          <div v-else>(empty)</div>
                        </div>
                      </div> -->

                    </div>
                  </td>
                  <!-- <td>{{ this.getBestOffer(owner).notes }}</td> -->
                  <td>
                    <textarea class="" v-model="this.getBestOffer(owner).notes" name="" id="" cols="30" rows="2"></textarea>
                  </td>
                  
                  <td>
                      <template  v-if="this.getQuotedFid(owner) == 27">
                        <DatePicker
                          :format="(t) => formatDateTimeFunc(t)"
                          :previewFormat="
                            (t) => formatDateTimeFunc(t)
                          "
                          v-model="getQuotedOfferByFid(owner, 27).date_updated"
                          :hideInputIcon="true"
                          inputClassName="dp-custom-input"
                        />
                      </template>

                      <template v-else-if="this.getQuotedFid(owner) == 37">
                        <DatePicker
                          :format="(t) => formatDateTimeFunc(t)"
                          :previewFormat="
                            (t) => formatDateTimeFunc(t)
                          "
                          v-model="getQuotedOfferByFid(owner, 37).date_updated"
                          :hideInputIcon="true"
                          inputClassName="dp-custom-input"
                        />
                      </template>

                      <template v-else-if="this.getQuotedFid(owner) == 38">
                        <DatePicker
                          :format="(t) => formatDateTimeFunc(t)"
                          :previewFormat="
                            (t) => formatDateTimeFunc(t)
                          "
                          v-model="getQuotedOfferByFid(owner, 38).date_updated"
                          :hideInputIcon="true"
                          inputClassName="dp-custom-input"
                        />
                      </template>

                      <template v-else-if="this.getQuotedFid(owner) == 39">
                        <DatePicker
                          :format="(t) => formatDateTimeFunc(t)"
                          :previewFormat="
                            (t) => formatDateTimeFunc(t)
                          "
                          v-model="getQuotedOfferByFid(owner, 39).date_updated"
                          :hideInputIcon="true"
                          inputClassName="dp-custom-input"
                        />
                      </template>

                      <template v-else-if="this.getQuotedFid(owner) == 40">
                        <DatePicker
                          :format="(t) => formatDateTimeFunc(t)"
                          :previewFormat="
                            (t) => formatDateTimeFunc(t)
                          "
                          v-model="getQuotedOfferByFid(owner, 40).date_updated"
                          :hideInputIcon="true"
                          inputClassName="dp-custom-input"
                        />
                      </template>

                      <template v-else-if="this.getQuotedFid(owner) == 'new'">
                        <DatePicker
                          :format="(t) => formatDateTimeFunc(t)"
                          :previewFormat="
                            (t) => formatDateTimeFunc(t)
                          "
                          v-model="getQuotedOfferByFid(owner, 40).date_updated"
                          :hideInputIcon="true"
                          inputClassName="dp-custom-input"
                        />
                      </template>

                      <template v-else>
                        <DatePicker
                          :format="(t) => formatDateTimeFunc(t)"
                          :previewFormat="
                            (t) => formatDateTimeFunc(t)
                          "
                          v-model="getQuotedOfferByFid(owner, 27).date_updated"
                          :hideInputIcon="true"
                          inputClassName="dp-custom-input"
                        />
                      </template>
                  
                    <!-- <DatePicker v-else
                      :format="(t) => formatDateTimeFunc(t)"
                      :previewFormat="
                        (t) => formatDateTimeFunc(t)
                      "
                      v-model="this.getBestOffer(owner).date_updated"
                      :hideInputIcon="true"
                      inputClassName="dp-custom-input"
                    /> -->
                  </td>
                  <!-- <td>{{ this.getBestOffer(owner).date_updated }}</td> -->
                  <td>
                    <template v-for="(value, i) in this.individualsName(owner)" :key="i">
                      <div>
                        {{ value }}
                      </div>
                    </template>
                  </td>
                  <td @click="() => {
                    this.copyText(this.individualsEmails(owner));
                  }" style="cursor: pointer;">
                    <template v-for="(value, i) in this.individualsEmails(owner)" :key="i">
                      <div>
                        {{ value }}
                      </div>
                    </template>
                  </td>
                  <!--<td class="send-done-wrap">
                    <div class="imgs-wrap">
                      <img src="../../assets/send-icon.svg" alt="send-icon" class="send-icon">
                      <img src="../../assets/check-done-icon.svg" alt="check-done-icon" class="check-done-icon">
                    </div>
                  </td>-->
                </template>
                <template v-else>
                  <!--<td>
                    <div style="text-align: center">
                      <input
                        type="checkbox"
                        name=""
                        id=""
                        true-value="true"
                        false-value="false"
                        @change="addNewOffer($event, owner.id)"
                      />
                    </div>  
                  </td> -->

                  <template v-for="indexQuote in quoteColumns" :key="indexQuote">
                    <td>
                      <template v-if="indexQuote == 1">
                        <div style="text-align: center">
                          <input
                            type="checkbox"
                            name=""
                            id=""
                            true-value="true"
                            false-value="false"
                            :checked="getApprovedValue(owner, indexQuote)"
                            @change="updateApprovedCkeckboxValue($event, owner, indexQuote, true)"
                          />
                        </div>
                      </template>
                      <template v-else>
                        <div style="text-align: center">
                          <input
                            type="checkbox"
                            name=""
                            id=""
                            disabled
                          />
                        </div>
                      </template>
                    </td>
                  </template>
                  
                  <template v-if="showNewFields(owner.id)">
                    <td>
                      <CurrencyInput
                        v-model="this.newOffers[owner.id].quotes[0].offer"
                        :options="{ currency: 'USD', precision: 2 }"
                      />
                    </td>
                    <td>
                      <textarea class="" v-model="this.newOffers[owner.id].notes" name="" id="" cols="30" rows="2"></textarea>
                    </td>
                    <td>
                      <DatePicker
                        :format="(t) => formatDateTimeFunc(t)"
                        :previewFormat="
                          (t) => formatDateTimeFunc(t)
                        "
                        v-model="this.newOffers[owner.id].date_updated"
                        :hideInputIcon="true"
                        inputClassName="dp-custom-input"
                      />
                      <!-- v-model="value.value"
                        :disabled="true" -->
                    </td>
                    
                  </template>
                  <template v-else>
                    <td>
                      <div class="field__empty">
                        <span>
                          -
                        </span>
                      </div>
                    </td>
                    <td>-</td>
                    <td>-</td>
                  </template>
                  <td>
                    <template v-for="(value, i) in this.individualsName(owner)" :key="i">
                      <div>
                        {{ value }}
                      </div>
                    </template>
                  </td>
                  <td @click="() => {
                    this.copyText(this.individualsEmails(owner));
                  }" style="cursor: pointer;">
                    <template v-for="(value, i) in this.individualsEmails(owner)" :key="i">
                      <div>
                        {{ value }}
                      </div>
                    </template>
                  </td>
                  <!--<td class="send-done-wrap">
                    <div class="imgs-wrap">
                      <img src="../../assets/send-icon.svg" alt="send-icon" class="send-icon">
                      <img src="../../assets/check-done-icon.svg" alt="check-done-icon" class="check-done-icon">
                    </div>
                  </td>-->
                </template>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

import Multiselect from "@vueform/multiselect";
import Cargo from "@/api/tradingPlatform/cargo";
import Size from "@/api/tradingPlatform/size";
import Voyage from "@/api/tradingPlatform/voyage";
import Offer from "@/api/tradingPlatform/offer";
import CurrencyInput from "@/components/CurrencyInput.vue";

import CustomLoader from "@/components/loader/CustomLoader.vue";

export default {
  name: "OwnersForCargoField",
  components: {
    Multiselect,
    CurrencyInput,
    CustomLoader,
  },
  props: {
    rid: Number,
  },
  data: function () {
    return {
      clst: [],
      owners: [],
      cargo: {},
      zoneId: null,
      zone: "",
      filterBase: {},
      sizes: [],
      // windowTop: Number,
      filterSizes: [],
      prepOwners: [],
      ranks: [0, 1, 2, 3],
      filterRanks: [0, 1, 2, 3],
      newOffers: {},
      quoteColumns: 1,
      addedNewQuoteColumn: false,
      savingQuoteColumn: false,
      newOfferQuotes: {},
      // quotesOrder: [27, 37, 38, 39, 40],
      quotesOrder: [
        {
          date: 11,
          quoted: 27,
          displayDate: moment(new Date()).format("MMM DD"),
        },
        {
          date: 41,
          quoted: 37,
          displayDate: moment(new Date()).format("MMM DD"),
        },
        {
          date: 42,
          quoted: 38,
          displayDate: moment(new Date()).format("MMM DD"),
        },
        {
          date: 43,
          quoted: 39,
          displayDate: moment(new Date()).format("MMM DD"),
        },
        {
          date: 44,
          quoted: 40,
          displayDate: moment(new Date()).format("MMM DD"),
        },
      ],

    };
  },
  async mounted() {
    this.getData();
    this.getSizesForFilter();
    this.getDefaultSizesForFilter();
    // window.addEventListener("scroll", this.onScroll, true);
  },
  // beforeUnmount() {
  //   window.removeEventListener("scroll", this.onScroll, true)
  // },
  watch: {
    filterSizes() {
      this.filterApply();
    },
    filterRanks() {
      this.filterApply();
    },
  },
  computed: {
    quotes(owner) {
      return this.getBestOffer(owner).quotes;
    },
  },

  methods: {
    // scrollToMultiselect() {
    //   window.console.log(this.windowTop)
    //   if(this.windowTop >= 675 && this.windowTop <= 755) {
    //     window.scrollTo({left:0, top: 755});
    //   }
    // },
    // onScroll(e) {
    //   this.windowTop = e.target.scrollTop;
    // },

    prepOffersLog(offers) {
      let res = [];
      let resArray = [];
      for (const key in offers) {
        const offer = offers[key];
        for(const offerLogKey in offer["offer_logs"]) {

          const offerLog = offer["offer_logs"][offerLogKey];
          // res.push(this.currencyFormat(offerLog.offer) + " : " + this.formatDateTimeForLog(offerLog.date_updated));
          resArray.push({
            offer: offerLog.offer,
            date_updated: offerLog.date_updated,
          });
        }

        for(const offerQuoteKey in offer["quotes"]) {
          const offerQuote = offer["quotes"][offerQuoteKey];
          for (const offerQuoteLogKey in offerQuote["logs"]) {
            const offerQuoteLog = offerQuote["logs"][offerQuoteLogKey];
            // res.push(this.currencyFormat(offerQuoteLog.offer) + " : " + this.formatDateTimeForLog(offerQuoteLog.date_updated));
            resArray.push({
              offer: offerQuoteLog.offer,
              date_updated: offerQuoteLog.date_updated,
            });
          }
        }

        /* if(offer.offer != null && offer.date_updated != null) {
          // res.push(this.currencyFormat(offer.offer) + " : " + this.formatDateTimeForLog(offer.date_updated));
        } */
      }
      

      resArray.sort((a, b) => {
          var dateA = new Date(a.date_updated);
          var dateB = new Date(b.date_updated);
          return dateA - dateB;
      });

      resArray.forEach((item) => {
        res.push(this.currencyFormat(item.offer) + " : " + this.formatDateTimeForLog(item.date_updated));
        // res.push(this.currencyFormat(item.offer) + " : " + item.date_updated);
      });
      return res;
    },
    
    copyText(textToCopy) {
        let text = textToCopy.join("; ");
        console.log("test2");
        // Створюємо невидимий textarea для копіювання тексту
        const textarea = document.createElement("textarea");
        textarea.style.position = "fixed";
        textarea.style.top = 0;
        textarea.style.left = 0;
        textarea.style.width = "2em";
        textarea.style.height = "2em";
        textarea.style.padding = 0;
        textarea.style.border = "none";
        textarea.style.outline = "none";
        textarea.style.boxShadow = "none";
        textarea.style.background = "transparent";
        textarea.value = text;
        document.body.appendChild(textarea);

        textarea.select();
        document.execCommand("copy");
        document.body.removeChild(textarea);
        this.emitter.emit("messageNotificationSent", "Copied!");
        // this.isCopied = true;
        // setTimeout(() => {
        //   this.isCopied = false;
        // }, 2000);
    },
    addNewOffer(e, ownerId) {
      console.log(e.target.checked);
      console.log(ownerId in this.newOffers);
      if(e.target.checked == true && !(ownerId in this.newOffers)) {
        console.log("add");
        this.newOffers[ownerId] = {
          approved: true, 
          cargo_id: this.cargo.id,
          owner_id: ownerId,
          notes: "",
          offer: null,
          quotes: [
            {
              approved: true,
              fid: 27,
              offer: null,
            }
          ],
          // offer: null,
          // date_updated: null,
        };
        // this.newOffers.set(ownerId, { approved: true });
      } else if(e.target.checked == false && (ownerId in this.newOffers)) {
        delete this.newOffers[ownerId];
      }
    },
    showNewFields(ownerId) {
      return (ownerId in this.newOffers);
    },
    formatDateTimeFunc(date) {
      if (date) {
        return (
          moment(date)
            // .tz("US/Pacific")
            .format("MM-DD-yyyy h:mm a")
        ); // America/New_York
      } else {
        return "";
      }
    },

    formatDateTimeForLog(date) {
      if (date) {
        return (
          moment(date).isSame(new Date(), 'year')
          ? moment(date).format("MMM DD")
          : moment(date).format("MMM DD yyyy")
        ); // America/New_York
      } else {
        return "";
      }
    },

    getData() {
      Cargo.getCargoByRid(this.rid).then((response) => {
        this.cargo = response.data["data"];

        Offer.getOffersByCargoId(this.cargo["id"]);

        if(this.cargo["filter_json"]) {
          this.filterBase = JSON.parse(this.cargo["filter_json"]);
          this.filterSizes = this.filterBase["sizes"];
          this.filterRanks = this.filterBase["ranks"];
        }
        if (this.cargo && this.cargo["voyage"] && this.cargo["voyage"]["load_port"] && this.cargo["voyage"]["load_port"]["zone"]) {
          this.zoneId = this.cargo["voyage"]["load_port"]["zone"]["id"];
          this.zone = this.cargo["voyage"]["load_port"]["zone"]["name"];
        }

        for(const k in this.quotesOrder) {
          for (const key in this.cargo.quotes) {
            console.log("======123===3345534");
            console.log(this.cargo.quotes[key]);
            console.log(this.quotesOrder[k].quoted);
            if(this.cargo.quotes[key]["fid"] == this.quotesOrder[k].quoted) {
              this.quoteColumns = +k+1;
            }
          }
        }
        this.filterApply();
      });
      Cargo.getOwnersByCargoRid(this.rid).then((response) => {
        this.owners = response.data["data"];
        this.prepOwners = response.data["data"];
        this.filterApply();
      });

      
      
      
    },
    getBestOffer(owner) {
      console.log(owner.offers);
      let offer = null;
      if (owner.offers.length != 0) {
        let price = owner.offers[0].offer;
        offer = owner.offers[0];
        for (const key in owner.offers) {
          if (Object.hasOwnProperty.call(owner.offers, key)) {
            const element = owner.offers[key];
            if (element.offer > price) {
              offer = element;
              price = element.offer;
            }
          }
        }
      }

      if(offer) {
        offer.approved = offer.approved ? true : false;

        if(offer.quotes.length > this.quoteColumns) {
          this.quoteColumns = offer.quotes.length;
        }
      }
      
      return offer;
    },

    getQuotedOffer(owner) {
      let offer = this.getBestOffer(owner);
      if(offer) {
        if(!("quotes" in offer)) return false;
        for(let i = this.quotesOrder.length-1; i >= 0; i--) {
          console.log("quotesOrder: " + this.quotesOrder[i].quoted);
          const quote = offer.quotes.find((el) => {
            console.log(el.fid);
            if(!el) return false;
            console.log((el.fid == this.quotesOrder[i].quoted && !!el.approved));
            return el.fid == this.quotesOrder[i].quoted && !!el.approved;
          });
          
          if(quote) {
            return quote
          }
        }
      }
      return offer;
    },

    getQuotedOfferByFid(owner, fid) {
      let offer = this.getBestOffer(owner);

      // if(this.addedNewQuoteColumn) {
      //   console.log("===!getQuotedOfferByFid");
      //   if(!(offer.id in this.newOfferQuotes)) this.newOfferQuotes[offer.id] = {};
      //   if(!((this.quotesOrder[this.quoteColumns-1].quoted) in this.newOfferQuotes[offer.id])) this.newOfferQuotes[offer.id][this.quotesOrder[this.quoteColumns-1].quoted] = {
      //     offer: null,
      //     date_updated: null,
      //     fid: this.quotesOrder[this.quoteColumns-1].quoted,
      //   }
      //   return this.newOfferQuotes[offer.id][this.quotesOrder[this.quoteColumns-1].quoted];
      // }
      // console.log("===!getQuotedOfferByFid2");

      if(offer) {
        if(!("quotes" in offer)) return false;

        // get last approved quote
        let quote = offer.quotes.find((el) => {
          console.log(el.fid);
          if(!el) return false;
          console.log((el.fid == fid && !!el.approved));
          return el.fid == fid && !!el.approved;
        });
        
        if(quote) {
          return quote
        } else {
          quote = offer.quotes.find((el) => {
            if(!el) return false;
            return el.fid == fid;
          });
          if(quote) {
            return quote
          }
        }
      }
      return offer;
    },

    getQuotedRid(owner) {
      let offer = this.getBestOffer(owner);
      if(offer) {
        if(!("quotes" in offer)) return false;
        for(let i = this.quotesOrder.length-1; i >= 0; i--) {
          const quote = offer.quotes.find((el) => {
            console.log(el.fid);
            if(!el) return false;
            console.log((el.fid == this.quotesOrder[i].quoted && !!el.approved));
            return el.fid == this.quotesOrder[i].quoted && !!el.approved;
          });
          
          if(quote) {
            return quote.rid
          }
        }
      }
      return null;
    },

    getQuotedFid(owner) {
      let offer = this.getBestOffer(owner);
      console.log("getQuotedFid");
      console.log(owner.name);
      // if(this.addedNewQuoteColumn) return "new";
      if(offer) {
        if(!("quotes" in offer)) return false;
        let fid = this.quotesOrder[this.quoteColumns-1].quoted;
        for(let i = this.quotesOrder.length-1; i >= 0; i--) {
          const quote = offer.quotes.find((el) => {
            console.log(el.fid);
            if(!el) return false;
            console.log((el.fid == fid && !!el.approved));
            return el.fid == fid /* && !!el.approved */;
          });
          
          if(quote) {
            console.log(quote);
            console.log(quote.fid);

            return quote.fid
          } else {
            offer.quotes.push({
              approved: 0,
              fid: fid,
              date_updated: null,
              offer: null,
            });
            return fid;
          }
        }
      }
      return null;
    },

    getApprovedValue(owner, index) {
      if(!this.getBestOffer(owner)) return false;
      if(!("quotes" in this.getBestOffer(owner))) return false;
      const quote = this.getBestOffer(owner).quotes.find((el) => {
        if(!el) return false;
        return el.fid == this.quotesOrder[index - 1].quoted;
      });

      return quote ? quote.approved : false;
    },

    getQuotedPrice(owner, index) {
      if(!this.getBestOffer(owner)) return "";
      if(!("quotes" in this.getBestOffer(owner))) return "";
      const quote = this.getBestOffer(owner).quotes.find((el) => {
        if(!el) return false;
        return (el.fid == this.quotesOrder[index - 1].quoted);
      });

      return quote ? quote.offer : "";
    },

    updateApprovedCkeckboxValue(e, owner, index, first = false) {
      if(first) {
        this.addNewOffer(e, owner.id);
      }

      const newIndex = index - 1;
      const quote = this.getBestOffer(owner).quotes.find((el) => {
        if(!el) return false;
        return ("fid" in el) && el.fid == this.quotesOrder[newIndex].quoted;
      });

      if(quote) {
        quote.approved = e.target.checked;
      } else {
        this.getBestOffer(owner).quotes[newIndex] = {
          approved: e.target.checked,
          offer_id: this.getBestOffer(owner).id,
          fid: this.quotesOrder[newIndex].quoted,
        }
      }
    },

    addQuoteColumn(index) {
      console.log(this.quotesOrder[index]);
      this.savingQuoteColumn = true;

      Cargo.addQuoteForCargoByID(this.cargo.id, this.quotesOrder[index].quoted).then((/* response */) => {
        this.savingQuoteColumn = false;
        if(this.quoteColumns < 5) {
          this.quoteColumns++;
          this.addedNewQuoteColumn = true;
        } else {
          alert("maximum: 5 columns")
        }
      }).catch(() => {
        this.savingQuoteColumn = false;
      });

      // if(this.quoteColumns < 5) {
      //   this.quoteColumns++;
      //   this.addedNewQuoteColumn = true;
      // } else {
      //   alert("maximum: 5 columns")
      // }
    },

    getQuoteColumnDate(index) {
      let result = null;
      let minDate = Infinity;

      if(this.cargo.quotes) {
        for (const key in this.cargo.quotes) {
          if(this.cargo.quotes[key]["fid"] == this.quotesOrder[index - 1].quoted) {
            result = this.cargo.quotes[key];
          }
        }
      }

      if(!result) {
        for (const key in this.owners) {
          if (Object.hasOwnProperty.call(this.owners, key)) {
            const owner = this.owners[key];
            for (const key in owner.offers) {
              if (Object.hasOwnProperty.call(owner.offers, key)) {
                const offer = owner.offers[key];

                for(let quoteKey in offer.quotes) {
                  let quote = offer.quotes[quoteKey];
                  if (quote.fid == this.quotesOrder[index - 1].quoted) {
                    var createDate = new Date(quote.date_create);
                    if (createDate < minDate) {
                      minDate = createDate;
                      result = quote;
                    }
                  }
                }
              }
            }
          }
        }
      }

      if(result) {
        return moment(result.date_create).isSame(new Date(), 'year')
          ? moment(result.date_create).format("MMM DD")
          : moment(result.date_create).format("MMM DD yyyy");
      } else {
        /* return moment(new Date()).isSame(new Date(), 'year')
          ? moment(new Date()).format("MMM DD")
          : moment(new Date()).format("MMM DD yyyy"); */
          return moment(this.cargo.date_create).isSame(new Date(), 'year')
          ? moment(this.cargo.date_create).format("MMM DD")
          : moment(this.cargo.date_create).format("MMM DD yyyy");
      }
    },
    
    calculateQuoteColumnDates() {

    },

    async sendToQB() {
      console.log("FGGGGGGGG");
      let offers = [];
      for (const key in this.owners) {
        if (Object.hasOwnProperty.call(this.owners, key)) {
          const owner = this.owners[key];
          for (const key in owner.offers) {
            if (Object.hasOwnProperty.call(owner.offers, key)) {
              const element = owner.offers[key];
              if("date_updated" in element && element["date_updated"]) {
                // element["date_updated"] = moment(element["date_updated"]).toISOString();
                element["date_updated"] = moment(element["date_updated"]).format('YYYY-MM-DDTHH:mm:ss.SSS');
              }

              offers.push(element);
            }
          }
        }
      }
      console.log(offers);

      Offer.addOffers(this.newOffers).then((response) => {
        console.log("response updateOffers");
        console.log(response);
      });

      Offer.updateOffers(offers).then((response) => {
        console.log("response updateOffers");
        console.log(response);
      });

      // Offer.addOfferQuotes(this.newOfferQuotes).then((response) => {
      //   console.log("response addOfferQuotes");
      //   console.log(response);
      // });

      Cargo.updateFilterForOwnerByCargoRid(this.rid, {
        sizes: this.filterSizes.reduce((accumulator, value) => {
          if (!accumulator.includes(value)) {
            accumulator.push(value);
          }
          return accumulator;
        }, []),
        ranks: this.filterRanks.reduce((accumulator, value) => {
          if (!accumulator.includes(value)) {
            accumulator.push(value);
          }
          return accumulator;
        }, []),
      });
    },
    priceFormat(price) {
      return this.currencyFormat(price);
    },
    priceFormatOffer(price) {
      return this.currencyFormat(price);
    },
    currencyFormat(value) {
      // currencyFormat(value) {
      if (value) {
        /* let formatedValue = value.toLocaleString("en-US", {
            style: 'currency',
          }); */
        let formatter = new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
          maximumFractionDigits: 2,
        });
        return formatter.format(value);
      } else {
        return "";
      }
    },
    getSizesForFilter() {
      Size.getAll().then((response) => {
        this.sizes = response.data["data"];
      });
    },
    getDefaultSizesForFilter() {
      Voyage.getVoyageByCargoRid(this.rid).then((response) => {
        let sizes = response.data["data"]["sizes"];
        sizes.forEach((element) => {
          if(!this.filterSizes.includes(element.id))
            this.filterSizes.push(element.id);
        });
        this.filterApply();
      });
    },
    individualsName(owner) {
      let res = [];
      for (const key in owner["owner_size_zones"]) {
        if (key in owner["owner_size_zones"] && owner["owner_size_zones"][key]["zone"]["id"] == this.zoneId) {
          const individuals = owner["owner_size_zones"][key]["individuals"];
          if(this.filterRanks.length != 0 && this.filterRanks.indexOf(owner["owner_size_zones"][key]["rank"]) === -1) continue;
          if(owner["owner_size_zones"][key]["zone_id"] != this.zoneId) continue;
          if(this.filterSizes.length != 0 && this.filterSizes.indexOf(owner["owner_size_zones"][key]["owner_size"]["size_id"]) === -1) continue;

          for (const kInd in individuals) {
            if (kInd in individuals) {
              const individual = individuals[kInd];
              
              if(individual["first_name"] || individual["surname"]) {
                res.push(individual["first_name"] + " " + individual["surname"]);
              } else {
                res.push("-");
              }
            }
          }

          
        }
      }
      return res;
    },
    individualsEmails(owner) {
      let res = [];
      let groupEmils = [];
      for (const key in owner["owner_size_zones"]) {
        if (key in owner["owner_size_zones"] && owner["owner_size_zones"][key]["zone"]["id"] == this.zoneId) {
          const individuals = owner["owner_size_zones"][key]["individuals"];

          if(this.filterRanks.length != 0 && this.filterRanks.indexOf(owner["owner_size_zones"][key]["rank"]) === -1) continue;
          if(owner["owner_size_zones"][key]["zone_id"] != this.zoneId) continue;
          if(this.filterSizes.length != 0 && this.filterSizes.indexOf(owner["owner_size_zones"][key]["owner_size"]["size_id"]) === -1) continue;

          for (const kInd in individuals) {
            if (kInd in individuals) {
              const individual = individuals[kInd];
              let emails = [];

              if(individual["email"]) {
                emails.push(individual["email"]);
              }

              if(individual["group_email"]) {
                if(groupEmils.indexOf(individual["group_email"]) === -1) {
                  emails.push(individual["group_email"]);
                  groupEmils.push(individual["group_email"]);
                }
                
              }

              if(emails.length == 0) {
                res.push("-");
              } else {
                res.push(emails
                  .map(item => item.replace(";", ""))
                  .join("; ")
                  );
              }
            }
          }
          
        }
      }
      return res;
    },
    filterApply() {
      if (this.filterSizes == undefined) this.filterSizes = [];
      if (this.filterRanks == undefined) this.filterRanks = [];
      if (this.filterSizes.length == 0 && this.filterRanks.length == 0) {
        this.prepOwners = this.owners;
        return;
      }

      let tmpPrepOwwners = [];
      for(let ownerKey in this.owners) {
        const owner = this.owners[ownerKey];
        for (const key in owner["owner_size_zones"]) {
          if (owner["owner_size_zones"][key]["zone"]["id"] == this.zoneId) {
            // const individuals = owner["owner_size_zones"][key]["individuals"];
            // if(this.filterRanks.length != 0 && this.filterRanks.indexOf(owner["owner_size_zones"][key]["rank"]) === -1) continue;
            if(owner["owner_size_zones"][key]["zone_id"] != this.zoneId) continue;
            if(this.filterSizes.length != 0 && this.filterSizes.indexOf(owner["owner_size_zones"][key]["owner_size"]["size_id"]) === -1) continue;

            tmpPrepOwwners.push(owner);
            break;
            
          }
        }
      }
      this.prepOwners = tmpPrepOwwners;
    },
  },
};
</script>
  
<style scoped>
/*.multiselect-container {*/
/*  min-height: 300px; !* Встановіть потрібну висоту *!*/
/*  overflow-y: auto;  !* Додайте вертикальну прокрутку *!*/
/*}*/

.multiselect-container {
  max-height: none !important;
}

a {
  color: #42b983;
}

td {
  border-right: 1px solid #d3dfed;
  border-bottom: 1px solid #d3dfed;
  /* padding: 15px; */
}
.report-body {
  position: relative;
  margin-top: 10px;
  padding-bottom: 10px;
  width: max-content;
}
.report-body-wrap {
  width: max-content;
}

thead {
  background: #f6faff;
}

tbody {
  border-top: 1px solid #d3dfed;
  border-left: 1px solid #d3dfed;
}

.report-table {
  table-layout: fixed;
  border-collapse: collapse;
  position: relative;
}

.report-table thead tr {
  position: relative;
}

th {
  position: sticky;
  z-index: 1;
  border: 0;
  padding: 15px 10px;
  color: rgb(255, 255, 255);
  -webkit-font-smoothing: antialiased;
  cursor: default;
  vertical-align: middle;

  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  color: #2d3757;
}

.report-table td div {
  white-space: nowrap;
}

.report-table__head {
  position: sticky;
  z-index: 1;
}

td {
  padding: 5px;
}

.send-done-wrap:hover .send-icon {
  transform: scale(1.2);
}

.imgs-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}

.send-icon,
.check-done-icon {
  width: 25px;
  height: 25px;
  display: block;
}

.send-icon {
  cursor: pointer;
  transition: transform .2s linear;
}

.q-currency-input {
  width: 100px;
}

</style>

<style scoped>
textarea {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #2c3e50;
  padding: 2px;
  width: 100%;
  min-width: 160px;
  box-sizing: border-box;
}

.btn-log {
  position: relative;
}

.btn-log__body {
  display: none;
  width: max-content;
  position: absolute;
  background: #2d3757;
  color: #fff;
  z-index: 5;
  padding: 5px;
  left: 0;
  bottom: 20px;
  border-radius: 2px;
  box-shadow: -1px -1px 10px 0px #2d375780;
  border: 1px solid #d3dfed;
  max-height: 300px;
  overflow-y: auto;
  flex-direction: column-reverse;
}

.btn-log:hover .btn-log__body {
  display: flex;
}

.field-title__btn-log {
  cursor: pointer;
  margin: 5px;
  border-radius: 50px;
  background:  #2d3757;
  color: #fff;
  padding: 2px 7px;
  z-index: 100;
}

.field__empty {
  min-height: 43px; 
  display: flex; 
  align-items: center;
}

</style>