<template>
  <div class="notif__item">
    <span>{{this.text}}</span>
  </div>
</template>

<script>
export default {
  name: 'NotificationItem',
  props: {
    text: {
      type: String,
      require: true,
    },
  },
  methods: {
    // deleteFile(event) {
    //   this.$emit('deleteFile', this.index);
    // }
  },
}
</script>

<style scoped>
.notif__item {
  box-sizing: border-box;
  width: 100%;
  padding: 15px;
  background: rgb(230, 229, 229);
  border: 3px solid rgb(182, 182, 182);
  margin-top: 15px;
}
</style>
