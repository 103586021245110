<template>
  <div class="modal">
    <div class="modal__mask">
      <div class="modal__wrapper">
        <div class="modal__container">
          <div class="modal__header">
            <div class="modal__title">{{ modalName }}</div>
            <div class="modal__close"></div>
            <div
              class="modal__close"
              v-on:click="
                () => {
                  this.$emit('showStatus', false);
                }
              "
            ></div>
          </div>

          <div class="modal__body">
            <div class="switch-bar modal__switch-bar">
              <!-- <div class="switch-bar__item switch-bar__item_active">General</div> -->
              <!-- <div class="switch-bar__item">Freight</div>
                <div class="switch-bar__item">Reminders</div> -->
            </div>
            <div class="switcher-content">
              <div class="switcher-content__choose">
                <div
                  class="switcher-content__item"
                  v-bind:class="{
                    'switcher-content__item_100': value.width == 100,
                    'switcher-content__item_25': value.width == 25,
                    'display-none': isHidden(value),
                  }"
                  v-for="(value, index) in mapping"
                  :key="index"
                >
                  <!-- <div class="switcher-content__title">{{ value }}</div> -->
                  <div class="switcher-content__title">
                    {{ this.objAllFields[value.key].label }}
                  </div>
                  <div class="switcher-content__data">
                    <!-- {{this.parentReportData[value.key]}} -->
                    <!-- {{this.parentReportData[value.key].value}} -->
                    <!-- v-model="this.parentReportData[value.key].value" -->
                    <div
                      v-if="
                        this.objAllFields[value.key].fieldType == 'checkbox'
                      "
                    >
                      <input
                        v-model="mapping[index].value"
                        type="checkbox"
                        class="switcher-content__data-input checkbox"
                      />
                    </div>
                    <div
                      v-else-if="
                        this.objAllFields[value.key].fieldType ==
                        'text-multi-line'
                      "
                    >
                      <textarea
                        v-model="mapping[index].value"
                        name=""
                        id=""
                        cols="30"
                        rows="10"
                        class="switcher-content__data-input"
                      ></textarea>
                    </div>
                    <div
                      v-else-if="
                        this.objAllFields[value.key].fieldType == 'date'
                      "
                    >
                      <DatePicker
                        v-model="mapping[index].value"
                        :enableTimePicker="false"
                      />
                    </div>
                    <div
                      class="report-table__item-text-multiple-choice-wrap"
                      v-else-if="
                        this.objAllFields[value.key].fieldType ==
                        'text-multiple-choice'
                      "
                    >
                      <Multiselect
                        v-model="mapping[index].value"
                        :options="
                          this.objAllFields[value.key].properties.choices
                        "
                        clearOnSelect="false"
                        placeholder="Choose"
                        :close-on-select="false"
                      ></Multiselect>
                    </div>
                    <div v-else-if="value.key == 10">
                      <input
                        v-model="mapping[index].value"
                        disabled
                        type="text"
                        class="switcher-content__data-input"
                      />
                    </div>
                    <div v-else>
                      <input
                        v-model="mapping[index].value"
                        type="text"
                        class="switcher-content__data-input"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- <table class="report-table">
                <tbody>
                  <tr v-for="(value, index) in parentReportData" :key="index">
                    <td>
                      <b>{{ this.objParentFields[index].label }}</b>
                    </td>
                    <td>
                      <textarea :value="value.value"></textarea>
                    </td>
                  </tr>
                </tbody>
              </table> -->
          </div>

          <div class="modal__footer">
            <div
              class="modal__close-button"
              v-on:click="
                () => {
                  this.$emit('showStatus', false);
                }
              "
            >
              Cancel
            </div>
            <div
              class="modal__save-button"
              v-on:click="
                () => this.sendToQB()
              "
            >
              <CustomLoader v-if="sendingToQB"/>
              <span v-else>Save</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "@vueform/multiselect";
import CustomLoader from "@/components/loader/CustomLoader.vue";

export default {
  name: "AddTaskPopUp",
  components: {
    Multiselect,
    CustomLoader,
  },
  props: {
    table: String,
    selectedReport: String,
    open: Boolean,
    rid: Number,
  },
  emits: ["showStatus"],
  data: function () {
    return {
      reports: [],
      modalName: "Add Task",
      allFields: [],
      masterTableId: "br3iujhxx",

      sendingToQB: false,

      mapping: [
        /* {
          key: 6,
          width: 50,
        },
        {
          key: 11,
          width: 50,
        },
        {
          key: 12,
          width: 50,
        }, */
      ],
    };
  },
  watch: {},
  mounted() {
    this.getDataTaskFields();
  },
  computed: {
    objAllFields() {
      let res = {};
      // this.fields.forEach((val) => {
      //   res[val["id"]] = val;
      // });
      this.allFields.forEach((val) => {
        res[val["id"]] = val;
      });
      return res;
    },
  },
  methods: {
    isHidden(val) {
      if (val.hasOwnProperty.call(val, "hidden")) {
        if(val.hidden) return true;
      }
      return false;
    },
    getDataTaskFields() {
      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      fetch(
        process.env.VUE_APP_API_DOMAIN +
        // "http://127.0.0.1:8000/api" +
          "/v1/qb/table/" +
          this.masterTableId +
          "/info",
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          console.log(result);
          this.allFields = result["body"]["allFields"];
          this.mapping = [
            {
              key: 6,
              width: 50,
              value: "",
            },
            {
              key: 11,
              width: 50,
              value: "",
            },
            {
              key: 12,
              width: 50,
              value: "",
            },
            {
              key: 13,
              width: 50,
              value: "",
            },
            {
              key: 7,
              width: 50,
              value: "",
            },
            {
              key: 8,
              width: 50,
              value: "",
            },
            {
              key: 9,
              width: 50,
              value: "",
            },
            {
              key: 10,
              width: 50,
              value: this.rid,
              hidden: true,
            },
            {
              key: 14,
              width: 50,
              value: "",
            },
          ];
        })
        .catch((error) => console.log("error", error));
    },
    sendToQB() {
      if(!this.sendingToQB) {
        this.sendingToQB = true;
        let prop = {};
        prop["to"] = this.masterTableId;
        prop["data"] = [];

        let dataTmp = {};
        this.mapping.forEach((el) => {
          if(el.value != "") {
            dataTmp[el.key] = el.value;
          }
        })
        prop["data"].push(dataTmp);
        console.log(prop);
        this.saveDataForQB(prop);

      }
      // this.$emit('showStatus', false);
    },

    async saveDataForQB(params) {
      var requestOptions = {
        method: "POST",
        redirect: "follow",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(params), // to, data
      };

      fetch(
        process.env.VUE_APP_API_DOMAIN + "/v1/qb/records/save",
        // process.env.VUE_APP_API_DOMAIN1 + "/v1/qb/records/save",
        // "http://127.0.0.1:8000/v1/qb/records/save",
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          console.log(result);
          this.$emit('showStatus', false);
          // this.clients = result;
        })
        .catch((error) => {
          this.sendingToQB = false;
          console.log("error", error);
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.tables-panel {
  max-width: 100%;
  overflow-y: hidden;
  overflow-x: auto;
  padding-top: 20px;
}
.tables-items {
  display: flex;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

/* .modal {
  position: absolute;
} */

.modal__mask {
  position: fixed;
  z-index: 5;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* display: table; */
  display: flex;
  justify-content: center;
  /* align-items: center; */
  transition: opacity 0.3s ease;
  padding: 20px;
}

.modal__wrapper {
  margin: 40px;
  margin-bottom: 0;
  overflow-y: auto;
  max-height: 100%;
  /* padding: 20px; */
}

.modal__container {
  /* overflow-y: scroll; */
  /* width: 300px; */
  margin: 0px auto;
  padding: 20px 0;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
  max-width: 800px;
}

.modal__header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal__header {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #d3dfed;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 20px;
}

.modal__title {
  padding: 5px 20px;
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  color: #2d3757;
}

.modal__close {
  position: absolute;
  top: 12px;
  right: 30px;
  cursor: pointer;
  width: 16px;
  height: 16px;
  background: url(@/assets/close.svg) no-repeat center center;
}

.modal__body {
  margin: 20px 0;
  padding-left: 30px;
  padding-right: 30px;
}

.modal__default-button {
  float: right;
}

.modal__footer {
  display: flex;
  justify-content: space-between;

  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;

  color: #2d3757;
  padding-left: 30px;
  padding-right: 30px;
}

.modal__close-button {
  border: 1px solid #2d3757;
  border-radius: 2px;
  padding: 14px 55px;
  margin-right: 15px;
  cursor: pointer;
}

.modal__save-button {
  background: #2d3757;
  border-radius: 2px;
  padding: 14px 55px;
  color: #ffffff;
  margin-left: 15px;
  cursor: pointer;
}

.preloader {
  display: flex;
  justify-content: center;
}

.report-body::-webkit-scrollbar {
  width: 5px;
  height: 10px;
  background-color: #f9f9fd;
}

.report-body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #2d375744;
}

.report-body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 20px;
  /* border: 3px solid #000; */
  background-color: #e3e9f1;
}

.item-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 5px;
}

.item-modal__title {
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-decoration-line: underline;
  color: #2d3757;
}

.item-modal__img {
  margin-left: 12px;
  height: 20px;
  width: 20px;
  background: url(@/assets/share.svg) no-repeat center center;
}

.switcher-content__choose {
  display: flex;
  flex-wrap: wrap;
}

.switcher-content__item {
  box-sizing: border-box;
  padding: 20px 20px;
  width: 50%;
  min-width: 100px;
}

.switcher-content__item_100 {
  width: 100%;
}

.switcher-content__item_25 {
  width: 25%;
}

.switcher-content__title {
  padding: 8px 0;
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;

  color: #6c6f7d;
}

.switcher-content__data-input {
  border: 1px solid #d3dfed;
  border-radius: 2px;
  padding: 12px;
  width: 100%;
}

.switcher-content__data-input.checkbox {
  width: auto;
}

.display-none {
  display: none;
}
</style>
