<template>
  <div>
    <input
      type="text"
      v-model="displayValue"
      @blur="isInputActive = false"
      @focus="isInputActive = true"
      class="switcher-content__data-input"
      v-bind:disabled="this.disabled"
    />
  </div>
</template>

<script>
export default {
  name: "PercentInput",
  data() {
    return {
      isInputActive: false,
    };
  },
  emits: ["update:modelValue"],
  props: {
    modelValue: Number, // Vue 2: value
    // value: Number, // Vue 2: value
    // options: Object,
    disabled: Boolean,
  },
  /* setup(props, { emit }) {
    console.log("setup");
      function emitUpdates(event) {
        console.log("emitUpdates");
          emit("input", event.target.value);
          this.$emit('update:modelValue', event.target.value);
      }

      return {
          emitUpdates
      };
  }, */
  computed: {
    displayValue: {
      get: function () {
        if (this.isInputActive) {
          return this.modelValue * 100;
        } else {
          /* return (
            "$ " +
            this.value.toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")
          ); */
          return this.modelValue * 100 + "%";
        }
      },
      set: function (modifiedValue) {
        /*eslint no-useless-escape: "error"*/
        // let newValue = parseFloat(
        //   modifiedValue.replace(/[^\d\.]/g, "") /* eslint-disable-line */
        // ); /* eslint-disable-line */
        // Ensure that it is not NaN
        let newValue = (modifiedValue / 100).toFixed(2);
        if (isNaN(newValue)) {
          newValue = 0;
        }
        console.log(newValue);
        this.$emit("update:modelValue", newValue);
      },
    },
  },
};
</script>

<style scoped>
.switcher-content__data-input {
  border: 1px solid #d3dfed;
  border-radius: 2px;
  padding: 12px;
  font-size: 16px;
  font-family: "Lato";
  width: 55px;
}

.disabled {
  background: #eee;
}
</style>