<template>
  <div class="home">
    <div class="reports">
      <div class="reports__wrap">
        <div class="reports__body">
          <div class="report__item" v-for="reportItem in reportsData" :key="reportItem" >
            <div class="report__wrap" v-if="this.showReport(reportItem.filterName)">
              <!-- <div class="report__title">
                <h2>{{reportItem.name}}</h2>
              </div> -->
              <QbReport
                :table=reportItem.tableId
                :report=reportItem.reportId
                :title=reportItem.name
                :filter="this.filterCollect"
                :showBodyProp=reportItem.showBody
                :stickyColumns="this.getStickyColumns(reportItem)"
              />
            </div>
          </div>

          <!-- <div class="report__item" v-if="this.showReport('Short')">
            <div class="report__title">
              <h2>Short</h2>
            </div>
            <QbReport
              :table="'brxisk55z'"
              :report="'27'"
              :filter="this.filterCollect"
            />
          </div> -->
        </div>
      </div>
    </div>

  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
// import QbTables from "@/components/tables/QbTables.vue";
// import QbReports from "@/components/reports/QbReports.vue";
import QbReport from "@/components/report/OwnersReport.vue";
// import CustomLoader from "@/components/loader/CustomLoader.vue";
import { mapState, mapActions } from "vuex";

// import { reactive } from 'vue'
export default {
  name: "OwnersView",
  components: {
    // QbTables,
    // QbReports,
    QbReport,
    // CustomLoader,
  },
  data() {
    return {
      table: "",
      report: "",
      chartererNameFilter: [],
      loadPortFilter: [],
      ownerFilter: [],
      vesselNameFilter: [],
      statusFilter: [],
      chartererNameFilterValue: [],
      loadPortFilterValue: [],
      ownerFilterValue: [],
      vesselNameFilterValue: [],
      statusFilterValue: [],
      filterApplying: false,
      filterCollect: {},

      showModalAddBimcoHolidays: false,

      reportsData: [ // mapping for all reports
        {
          name: "Owners",
          filterName: "Owners",
          tableId: "bs7zzssmr",
          reportId: "7",
          showBody: true,
          stickyAdminColumns: 0,
          stickyColumns: 0,
        },
      ],
      // {
      //   id: ""
      // }

      lastActiveTime: (new Date()).getTime(),
      updatePageAfter: 60*5*1000,
    };
  },
  created() {
    // if(!this.$store.holidays) this.refresh();
  },
  beforeMount() {
    console.log("beforeMount");
    if (!localStorage.token) {
      this.$router.push("/signin");
      // this.name = localStorage.name;
    }
  },
  mounted() {
    this.getFiltersData();
    // this.checkUserActive();
    // this.$store.dispatch('holidays/getHolidays')
    // this.getQBReport();
  },
  computed: {
    // ...mapState(["holidayRecords"]),
    ...mapState("holidays", {
       holidayReportData: state => state.holidayReportData,

    }),
    /* ...mapState({
        // name: state=>state.name,
        // weatherData: state=>state.yearData
    }), */
    role() {
      if(localStorage.role) {
        return localStorage.role;
      } else {
        return "";
      }
    },
    isAdmin() {
      if(this.role == "Admin") {
        return true;
      } else {
        return false;
      }
    }
    // filterCollect() {
    //   let res = {};
    //   res["97"] = this.chartererNameFilterValue;
    //   res["149"] = this.loadPortFilterValue;
    //   res["42"] = this.statusFilterValue;
    //   return res;
    // },
  },
  methods: {
    ...mapActions('holidays', ["getHolidays", "getCount"]),

    checkUserActive() {
      let app = document.querySelector(
        "#app"
      );

      app.addEventListener('mousemove', this.mouseMonitor);

      setInterval(this.updatePage, 5000);
    },

    async mouseMonitor() {
      this.lastActiveTime = (new Date()).getTime();
    },

    async updatePage() {
      if((this.lastActiveTime + this.updatePageAfter) < (new Date()).getTime()) {
        // location.reload();
      }
    },

    showStatusAddBimcoHolidaysPopUp(status) {
      this.showModalAddBimcoHolidays = status;
    },

    getStickyColumns(reportItem) {
      return this.isAdmin ? reportItem.stickyAdminColumns : reportItem.stickyColumns;
    },
    
    showReport(name) {
      console.log(name);
      if (this.statusFilterValue.length == 0) {
        return true;
      } else {
        for (const key in this.statusFilterValue) {
          console.log("====");
          console.log(this.statusFilterValue[key]);
          console.log(name.toLowerCase());
          console.log(this.statusFilterValue[key]
              .toLowerCase()
              .indexOf(name.toLowerCase()) != -1);
          if (
            this.statusFilterValue[key]
              .toLowerCase()
              .indexOf(name.toLowerCase()) != -1
          ) {
            return true;
          }
          // return false;
          // if (Object.hasOwnProperty.call(statusFilterValuel, key)) {
          //   const element = statusFil[key];

          // }
        }
        return false;
      }
      /* statusFilterValuel.forEach(el => {
        if(statusFilterValuel.indexOf(name) != -1) {
          return true;
        }
      });
      if(statusFilterValuel.indexOf(name) != -1) {
        return true;
      } */
    },
    logout() {
      localStorage.token = "";
      this.$router.push("/signin");
    },
    filterApply() {
      let res = {};
      res["373"] = this.chartererNameFilterValue;
      res["149"] = this.loadPortFilterValue;
      res["42"] = this.statusFilterValue;
      res["92"] = this.ownerFilterValue;
      res["96"] = this.vesselNameFilterValue;
      this.filterCollect = res;
    },
    getInfoTable(table) {
      console.log("table");
      console.log(table);
      this.table = table.id;
      this.report = "";
      // this.table.id = table.id;
      // this.table.name = table.name;
    },
    getInfoReport(report) {
      console.log("report");
      console.log(report);
      this.report = report.id;
      // this.table.id = table.id;
      // this.table.name = table.name;
    },
    getQBReport() {
      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      fetch(
        process.env.VUE_APP_API_DOMAIN +
          "/v1/qb/reports/27/run?table_id=brxisk55z",
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          console.log(result);
          // this.clients = result;
        })
        .catch((error) => console.log("error", error));
      fetch(
        process.env.VUE_APP_API_DOMAIN +
          "/v1/qb/reports/28/run?table_id=brxisk55z",
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          console.log(result);
          // this.clients = result;
        })
        .catch((error) => console.log("error", error));
      fetch(
        process.env.VUE_APP_API_DOMAIN +
          "/v1/qb/reports/29/run?table_id=brxisk55z",
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          console.log(result);
          // this.clients = result;
        })
        .catch((error) => console.log("error", error));
    },

    getFiltersData() {
      if (!this.holidayReportData.length) {
          this.getHolidays(6);
      }
      // if(!this.$store.holidays.holidayReportData.length) this.getHolidays(6);
      var requestOptions = {
        method: "GET",
        redirect: "follow",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.token,
        },
      };

      fetch(process.env.VUE_APP_API_DOMAIN + "/v1/qb/filter", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          console.log(result);
          // this.clients = result;
          this.chartererNameFilter = result["body"]["data"]["373"];
          this.loadPortFilter = result["body"]["data"]["149"];
          this.statusFilter = result["body"]["data"]["42"];
          this.ownerFilter = result["body"]["data"]["92"];
          this.vesselNameFilter = result["body"]["data"]["96"];
        })
        .catch((error) => console.log("error", error));
    },
  },
};
</script>

<style scoped>
.header {
  box-sizing: border-box;
  height: 80px;
  background: #2d3757;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 30px;
}

.header__logo {
  max-height: 100%;
  text-align: left;
}

.header__bar {
  color: #fff;
  display: flex;
}

.header-bar__item {
  margin: 0 15px;
}

.test {
  border-radius: 10px;
  border: 1px solid #000;
  padding: 5px 10px;
  margin-right: 10px;
}

.filter {
  box-sizing: border-box;
  padding: 15px 30px;
}

.filter__wrap {
  box-sizing: border-box;
  background: #f6faff;
  border: 1px solid #d3dfed;
  border-radius: 2px;
  padding: 30px 20px;
}

.filter__body {
  display: flex;
  align-items: center;
}

.filter__img {
  height: 24px;
  width: 24px;
  background: url(@/assets/filter.svg) no-repeat center center;
}

.filter__title {
  display: flex;
  align-items: center;
  padding-left: 15px;
}

.filter__title-text {
  padding-left: 15px;
  padding-right: 30px;
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #2d3757;
}

.filter__items {
  display: flex;
  flex: 1 1 auto;
  align-items: flex-end;
}

.filter__item {
  padding: 0px 10px;
  flex: 1 1 auto;
  max-width: 33%;
}

.filter__body .multiselect {
  width: 100%;
  z-index: 3;
}

.btn-apply {
  /* display: flex; */
  justify-content: end;
  margin-bottom: 2px;
}

.btn-apply__body {
  /* background: rgb(106, 141, 255); */
  color: #fff;
  /* font-size: 1.25em; */
  padding: 10px 30px;
  margin-right: 20px;
  cursor: pointer;

  background: #2d3757;
  border-radius: 2px;

  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  /* line-height: 19px; */

  color: #ffffff;
}

.filter__item-name {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;

  color: #6C6F7D;
  margin: 8px;

}

.reports {
  padding: 0 30px;
}

.report__title {
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 48px;
  color: #2d3757;
  padding-top: 30px;
  padding-bottom: 20px;
}

.log-out {
  cursor: pointer;
}

.header-bar__item {
  margin: 0 15px;
  cursor: pointer;
  padding: 10px 30px;
  display: flex;
}

.header-bar__item:hover {
  margin: 0 15px;
  cursor: pointer;
  background: #50629B;
}

.header-bar__item-img {
  height: 16px;
  width: 16px;
  background: url(@/assets/icon-calendar.svg) no-repeat center center;
  margin-right: 8px;
}

.header-bar__item-img_flag {
  background: url(@/assets/icon-flag.svg) no-repeat center center;
}
.header-bar__item-img_list {
  background: url(@/assets/icon-list.svg) no-repeat center center;
}
.header-bar__item-img_calendar {
  background: url(@/assets/icon-calendar.svg) no-repeat center center;
}
.header-bar__item-img_user {
  background: url(@/assets/icon-user.svg) no-repeat center center;
}

@media only screen and (min-width: 600px) {}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .filter__items {
    flex-wrap: wrap;
    flex: 0 1 auto;

  }

  .filter__item {
    padding: 0px 10px;
    flex: 0 1 auto;
    max-width: auto;
    width: 250px;
    margin: 5px 5px;
  }
}


@media only screen and (max-width: 768px) {}

@media only screen and (max-width: 1200px) {
  .filter__items {
    display: flex;
    flex: 1 1 auto;
    align-items: flex-end;
    flex-wrap: wrap;
  }

  .filter__item {
    padding: 0px 10px;
    flex: 1 1 auto;
    max-width: 100%;
    width: 250px;
    margin: 5px 5px;
  }
}


</style>

<style>
body::-webkit-scrollbar {
  width: 16px;
  height: 10px;
  background-color: #f9f9fd;
}

body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #2d375744;
}

body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 20px;
  /* border: 3px solid #000; */
  background-color: #e3e9f1;
}
</style>
<style src="@vueform/multiselect/themes/default.css"></style>
