
const fieldsMap = [
    {
      id: 1,
      title: "General",
      show: false,
      body: [
        {
          title: "General",
          showTitle: false,
          body: [
            [
              {
                componentType: "multichoice-rel",
                labelId: 15,
                nameId: 6, // lookupTargetFieldId
                forFieldId: 14, // lookupReferenceFieldId
                tableId: "bs7zzssmr", // masterTableId
                key: 14,
                width: 25,
                value: "",
              },
              {
                key: 27,
                width: 100,
                value: "",
              },
              {
                key: 11,
                width: 100,
                value: "",
              },
            ],
            [
              {
                key: 7,
                width: 100,
                value: "",
              },
            ],
            [
              {
                key: 8,
                width: 100,
                value: "",
              },
            ],

            [
              {
                key: 9,
                width: 100,
                value: "",
              },
            ],

            [
              {
                key: 18,
                width: 100,
                value: "",
              },
            ],
          ],
        },
      ],
    },
  ];


export default {
    fieldsMap,
}