<template>
  <div class="tables-panel">
    <div class="tables-items">
      <QbReportsItem v-for="report in reports" :report="report" :selectedReport="selectedReport" :key="report.id" @getInfo="getInfoReport"/>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import QbReportsItem from "./components/QbReportsItem.vue";

export default {
  name: "QbReports",
  components: {
    QbReportsItem,
  },
  props: {
    table: String,
    selectedReport: String,
  },
  emits: ["getInfo"],
  data: function () {
    return {
      reports: []
    };
  },
  watch: {
    table() {
      console.log(this.table);
      this.getQBTables();
    },
  },
  mounted() {
    this.getQBTables();
  },
  computed: {
    ...mapGetters(["tables"]),
  },
  methods: {
    ...mapActions(["createTable"]),

    getInfoReport(report) {
      console.log(report);
      this.$emit("getInfo", report);
    },

    getQBTables() {
      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      fetch(
        process.env.VUE_APP_API_DOMAIN + "/v1/qb/reports?table_id=" + this.table,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          console.log(result);
          this.reports = result["body"];
          // this.clients = result;
        })
        .catch((error) => console.log("error", error));
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.tables-panel {
  max-width: 100%;
  overflow-y: hidden;
  overflow-x: auto;
  padding-top: 20px;
}
.tables-items {
  display: flex;
  
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
