import Api from "../Api";

export default {
    reportById(table, reportId, filter, clist = []) {
        var headers = {
            method: "POST",
            redirect: "follow",
            headers: { "Content-Type": "application/json", Authorization: "Bearer " + localStorage.token,  },
          };

        let data = JSON.stringify({
            filter: filter,
            token: localStorage.token,
            clist: clist,
        })
        
        return Api.post("/v1/qb/trading/reports/" + reportId + "/run?table_id=" + table, 
        data, headers);
    },
    getOwnersByCargoRid(rid) {
        var headers = {
            method: "POST",
            redirect: "follow",
            headers: { "Content-Type": "application/json" },
          };

        let data = JSON.stringify({
            token: localStorage.token,
        })
        
        return Api.post("/v1/cargoes/" + rid + "/offers", 
        data, headers);
    },

    updateFilterForOwnerByCargoRid(rid, filter) {
        var headers = {
            method: "POST",
            redirect: "follow",
            headers: { "Content-Type": "application/json" },
          };

        let data = JSON.stringify({
            token: localStorage.token,
            filter: filter,
        })
        
        return Api.post("/v1/cargoes/" + rid + "/filter", 
        data, headers);
    },

    getCargoByRid(rid) {
        var headers = {
            method: "GET",
            redirect: "follow",
            headers: { "Content-Type": "application/json" },
          };

        let data = JSON.stringify({
            token: localStorage.token,
        })
        
        return Api.get("/v1/cargoes/" + rid, 
        data, headers);
    },

    deleteByRid(rid) {
        var headers = {
            method: "DELETE",
            redirect: "follow",
            headers: { "Content-Type": "application/json" },
          };

        let data = JSON.stringify({
            token: localStorage.token,
        })
        
        return Api.delete("/v1/cargoes/rid/" + rid, 
        data, headers);
    },

    getCargoSummaryByRid(rid) {
        var headers = {
            method: "GET",
            redirect: "follow",
            headers: { "Content-Type": "application/json" },
          };

        let data = JSON.stringify({
            token: localStorage.token,
        })
        
        return Api.get("/v1/cargoes/" + rid + "/offers/summary", 
        data, headers);
    },

    addQuoteForCargoByID(id, fid) {
        var headers = {
            method: "POST",
            redirect: "follow",
            headers: { "Content-Type": "application/json" },
          };

        let data = JSON.stringify({
            token: localStorage.token,
            fid: fid,
        })
        
        return Api.post("/v1/cargoes/" + id + "/quote", 
        data, headers);
    },

}