<template>
  <div>
    <div class="add-individual-wrap">
      <VueMultiselect
          v-model="selectedIndividual"
          :options="filteredIndividuals"
          :custom-label="customLabel"
          :option-height="40"
          track-by="id"
          :disabled-options="true"
      >
        <template v-slot:option="slotProps">
          <span
              :class="{ 'option-disabled': isIndividualSelected(slotProps.option) }"
          >
            {{ customLabel(slotProps.option) }}
          </span>
        </template>
      </VueMultiselect>
      <button
          class="add-individual-btn"
          :disabled="!selectedIndividual"
          @click="addIndividual"
      >
        + Add Individual
      </button>
    </div>
  </div>
</template>

<script>
import VueMultiselect from "vue-multiselect";

export default {
  name: "AddIndividual",
  components: {
    VueMultiselect
  },
  props: {
    individuals: Array,
    selectedIndividuals: Array
  },
  data() {
    return {
      selectedIndividual: null
    };
  },
  computed: {
    filteredIndividuals() {
      return this.individuals.filter(
          individual => !this.selectedIndividuals.some(
              selected => selected.id === individual.id
          )
      );
    }
  },
  methods: {
    customLabel(individual) {
      return `${individual.first_name} ${individual.surname}`;
    },
    addIndividual() {
      this.$emit("addIndividual", this.selectedIndividual);
      this.selectedIndividual = null;
    },
    isIndividualSelected(individual) {
      return this.selectedIndividuals.some(
          selected => selected.id === individual.id
      );
    }
  }
};
</script>

<style scoped src="vue-multiselect/dist/vue-multiselect.css"></style>
<style scoped>
/*.option-disabled {*/
/*  color: #aaa;*/
/*  pointer-events: none;*/
/*  cursor: not-allowed;*/
/*}*/

.add-individual-wrap {
  text-align: center;
}
.add-individual-btn {
  background: #2d3757;
  color: #f6faff;
  padding: 4px 16px;
  margin-top: 4px;
  cursor: pointer;
}

.add-individual-btn[disabled] {
  background: #aaa;
  cursor: not-allowed;
}
</style>