import Api from "../Api";

export default {
    update(items) {
        var headers = {
            headers: { "Content-Type": "application/json" },
        };

        let data = JSON.stringify({
            token: localStorage.token,
            data: items,
        })
        return Api.put("/v1/ownerSizeZone", 
        data, headers);
    },
}