<template>
  <div class="delete-confirmation-overlay">
    <div class="delete-confirmation">
      <p class="confirmation-text" v-if="isReport">
        Are you sure you want to delete this report?
      </p>
      <p class="confirmation-text" v-if="!isReport">
        Are you sure you want to delete this record?
      </p>
      <div class="confirmation-buttons">
        <button
          class="confirm-button"
          @click="confirmDelete"
          :disabled="deleting"
        >
          <span v-if="!deleting">Delete</span>
          <div v-else class="spinner">
            <div class="spinner-circle"></div>
          </div>
        </button>
        <button
          class="cancel-button"
          @click="cancelDelete"
          :disabled="deleting"
        >
          Cancel
        </button>
      </div>
    </div>
  </div>
</template>
  
<script>
export default {
  components: {},
  data() {
    return {
      deleting: false,
    };
  },
  methods: {
    async confirmDelete() {
      this.deleting = true;
      try {
        await this.onConfirm();
      } finally {
        this.deleting = false;
      }
    },
    cancelDelete() {
      if (this.onCancel) {
        this.onCancel();
      }
    },
  },
  props: {
    onConfirm: Function,
    onCancel: Function,
    isReport: Boolean,
  },
};
</script>
  
<style scoped>
.delete-confirmation-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.delete-confirmation {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  max-width: 300px;
  text-align: center;
}

.confirmation-text {
  margin-bottom: 20px;
}

.confirmation-buttons {
  display: flex;
  justify-content: center;
}

.confirm-button,
.cancel-button {
  padding: 8px 16px;
  margin: 0 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.confirm-button {
  background-color: #f44336;
  color: #fff;
  height: 31px;
  width: 70px;
}

.cancel-button {
  background-color: #ccc;
}

.progress-container {
  display: flex;
  align-items: center;
}

.progress-text {
  margin-left: 10px;
}

.spinner {
  display: flex;
  align-items: center;
}

.spinner-circle {
  width: 15px;
  height: 15px;
  margin: auto;
  border: 2px solid #f3f3f3;
  border-top: 2px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
  