<template>
  <div>
    <table class="report-table" v-if="reportData.length > 0">
      <thead>
        <tr class="report-table__head">
          <th v-for="item in this.clst" :key="item">
            <div>
              {{ this.prepNameForColumnTitle(objFields[item].label) }}
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(row, index) in reportData" :key="index">
          <td v-for="item in this.clst" :key="item">
            <div
              class="report-table__item"
              v-if="this.objFields[item].fieldType == 'checkbox'"
            >
              <input
                type="checkbox"
                v-model="row[item].value"
                @update:modelValue="
                  () => handleCheckbox(row[item].value, index, item)
                "
              />
              <!-- {{ row[item].value }} -->
            </div>
            <div v-else>
              <div class="report-table__item">
                {{ row[item].value }}
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div v-else class="bh_none">
      <span>Bimco Holidays: NONE</span>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "HolidaysReport",
  components: {},
  props: {
    // allFields: Array,
    reportDataOriginal: Array,
    // clist: Array,
    countryFilterValue: Array,
    idsForShowFields: Array,
    stateFilterValue: Array,
    startHolidayDateFilterValue: Object,
    endHolidayDateFilterValue: Object,
    timeForFilterRequire: {
      type: Boolean,
      default: true
    }
  },
  emits: ["showStatus"],
  data: function () {
    return {
      reportData: [],
      clst: [],
    };
  },
  watch: {
    countryFilterValue() {
      this.filterApply();
    },
    stateFilterValue() {
      this.filterApply();
    },
    startHolidayDateFilterValue() {
      this.filterApply();
    },
    endHolidayDateFilterValue() {
      this.filterApply();
    },
    // reportDataOriginal() {
    //   this.reportData = [];
    //   this.reportDataOriginal.forEach((el) => {
    //     this.reportData.push(el);
    //   });
    //   // this.reportData = this.reportDataOriginal;
    // },
  },
  mounted() {
    this.getHolidays(6);

    this.reportData = [];
    this.reportDataOriginal.forEach((el) => {
      this.reportData.push(el);
    });
    this.filterApply();

    this.clst = this.newClist();
  },
  computed: {
    ...mapGetters("holidays", ["holidayRecords", "holidayClist"]),

    clist() {
      return this.holidayClist;
    },
    objFields() {
      let res = {};
      // this.fields.forEach((val) => {
      //   res[val["id"]] = val;
      // });
      this.holidayRecords.forEach((val) => {
        res[val["id"]] = val;
      });
      return res;
    },

    // allFields() {
    //   return this.getReportById(this.report).allFields;
    // },

  },
  methods: {
    ...mapActions('holidays', ["getHolidays", "getCount"]),

    newClist() {
      if(!this.idsForShowFields?.length) return this.clist;

      let res = [];
      for (const k in this.clist) {
          if(this.idsForShowFields.includes(this.clist[k])) {
            res.push(this.clist[k]);
          }
      }

      return res;
    },
    prepNameForColumnTitle(label) {
      let countDash = label.split("-").length - 1;
      if (countDash > 1) {
        let tmp = label.split("-");
        tmp.shift();
        return tmp.join("-").replace(/formula/g, "");
      } else {
        return label.replace(/formula/gi, "");
      }
    },
    filterApply() {
      // let res = {};
      // res["373"] = this.chartererNameFilterValue;
      // res["149"] = this.loadPortFilterValue;
      // res["42"] = this.statusFilterValue;
      // this.filterCollect = res;

      if (
        !!this.countryFilterValue.length ||
        !!this.stateFilterValue.length ||
        !!this.startHolidayDateFilterValue ||
        !!this.endHolidayDateFilterValue
      ) {
        this.reportData = [];
        console.log("filter");
        console.log(this.countryFilterValue);
        console.log(this.stateFilterValue);
        console.log(this.reportDataOriginal);
        for (const key in this.reportDataOriginal) {
          if (Object.hasOwnProperty.call(this.reportDataOriginal, key)) {
            const element = this.reportDataOriginal[key];

            if (
              (!this.countryFilterValue.length ||
                this.countryFilterValue.includes(element[28]["value"])) &&
              (!this.stateFilterValue.length ||
                this.stateFilterValue.includes(element[32]["value"]))
              // this.startHolidayDateFilterValue.includes(element[18]["value"]))
            ) {

              if(this.timeForFilterRequire) {
                if((!!this.startHolidayDateFilterValue &&
                moment(element[9]["value"])
                  .add(1, "days")
                  .isSameOrAfter(this.startHolidayDateFilterValue)) &&
                (!!this.endHolidayDateFilterValue &&
                moment(element[9]["value"]).isSameOrBefore(
                  this.endHolidayDateFilterValue
                ))) {
                  this.reportData.push(element);
                }
              } else {
                if((!this.startHolidayDateFilterValue ||
                moment(element[9]["value"])
                  .add(1, "days")
                  .isSameOrAfter(this.startHolidayDateFilterValue)) &&
                (!this.endHolidayDateFilterValue ||
                moment(element[9]["value"]).isSameOrBefore(
                  this.endHolidayDateFilterValue
                ))) {
                  this.reportData.push(element);
                }
              }
            }
          }
        }
      } else {
        this.reportData = [];
        for (const key in this.reportDataOriginal) {
          if (Object.hasOwnProperty.call(this.reportDataOriginal, key)) {
            const element = this.reportDataOriginal[key];
            this.reportData.push(element);
          }
        }
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.tables-panel {
  max-width: 100%;
  overflow-y: hidden;
  overflow-x: auto;
  padding-top: 20px;
}
.tables-items {
  display: flex;
}
.report-panel {
  max-width: 100%;
  overflow-y: hidden;
  overflow-x: auto;
}

.report-body {
  /* height: 100%; */
  /* height: 700px; */
  overflow-y: auto;
  overflow-x: auto;
  position: relative;
  margin-top: 10px;
  /* display: flex; */
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

td {
  border-right: 1px solid #d3dfed;
  border-bottom: 1px solid #d3dfed;
  /* padding: 15px; */
}

thead {
  background: #f6faff;
}

tbody {
  border-top: 1px solid #d3dfed;
  border-left: 1px solid #d3dfed;
}

.report-table {
  /* margin-top: 50px; */
  /* width: 100%; */
  table-layout: fixed;
  border-collapse: collapse;
  /* height: 90vh; */
  position: relative;
}

/* .report-table thead {
  background-color: #333;
  color: #fdfdfd;
  position: sticky;
} */

.report-table thead tr {
  /* display: block; */
  position: relative;
}

th {
  position: sticky;
  z-index: 1;
  /* border-top: 0px;
  border-right: 1px solid rgb(215, 214, 217);
  border-bottom: 1px solid rgb(215, 214, 217); */
  border: 0;
  padding: 5px 10px;
  color: rgb(255, 255, 255);
  -webkit-font-smoothing: antialiased;
  /* background-color: rgb(120, 117, 124); */
  /* background: #fff; */
  cursor: default;
  vertical-align: middle;

  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  color: #2d3757;
  /* text-align: left; */
}

.report-table__head {
  position: sticky;
  z-index: 1;
}

.btn-save {
  display: flex;
  justify-content: end;
}

.btn-save__body {
  background: rgb(106, 141, 255);
  color: #fff;
  font-size: 1.25em;
  padding: 10px 30px;
  margin-right: 20px;
  cursor: pointer;
}
/* .modal {
  position: absolute;
} */

.modal__mask {
  position: fixed;
  z-index: 5;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* display: table; */
  display: flex;
  justify-content: center;
  /* align-items: center; */
  transition: opacity 0.3s ease;
  padding: 20px;
}

.modal__wrapper {
  margin: 40px;
  margin-bottom: 0;
  overflow-y: auto;
  max-height: 100%;
  /* padding: 20px; */
}

.modal__container {
  /* overflow-y: scroll; */
  /* width: 300px; */
  margin: 0px auto;
  padding: 20px 0;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
  width: 1000px;
}

.modal__header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal__header {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #d3dfed;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 20px;
}

.modal__title {
  padding: 5px 20px;
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  color: #2d3757;
}

.modal__close {
  position: absolute;
  top: 12px;
  right: 30px;
  cursor: pointer;
  width: 16px;
  height: 16px;
  background: url(@/assets/close.svg) no-repeat center center;
}

.modal__body {
  margin: 20px 0;
  padding-left: 30px;
  padding-right: 30px;
}

.modal__default-button {
  float: right;
}

.modal__footer {
  display: flex;
  justify-content: space-between;

  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;

  color: #2d3757;
  padding-left: 30px;
  padding-right: 30px;
}

.modal__close-button {
  border: 1px solid #2d3757;
  border-radius: 2px;
  padding: 14px 55px;
  margin-right: 15px;
  cursor: pointer;
}

.modal__save-button {
  background: #2d3757;
  border-radius: 2px;
  padding: 14px 55px;
  color: #ffffff;
  margin-left: 15px;
  cursor: pointer;
}

.preloader {
  display: flex;
  justify-content: center;
}

.report-body::-webkit-scrollbar {
  width: 5px;
  height: 10px;
  background-color: #f9f9fd;
}

.report-body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #2d375744;
}

.report-body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 20px;
  /* border: 3px solid #000; */
  background-color: #e3e9f1;
}

.item-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 5px;
}

.item-modal__title {
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-decoration-line: underline;
  color: #2d3757;
}

.report-table__item {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #2d3757;
  display: block;
  height: 100%;
  width: 100%;
  padding: 15px;
  box-sizing: border-box;
  white-space: nowrap;
}

.item-modal__img {
  margin-left: 12px;
  height: 20px;
  width: 20px;
  background: url(@/assets/share.svg) no-repeat center center;
}

/* filter */

.filter {
  box-sizing: border-box;
  /* padding: 15px; */
  padding-bottom: 15px;
}

.filter__wrap {
  box-sizing: border-box;
  background: #f6faff;
  border: 1px solid #d3dfed;
  border-radius: 2px;
  padding: 10px 10px;
}

.filter__body {
  display: flex;
  align-items: center;
}

.filter__img {
  height: 24px;
  width: 24px;
  background: url(@/assets/filter.svg) no-repeat center center;
}

.filter__title {
  display: flex;
  align-items: center;
  padding-left: 5px;
}

.filter__title-text {
  padding-left: 15px;
  padding-right: 30px;
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #2d3757;
}

.filter__items {
  display: flex;
  flex: 1 1 auto;
  flex-wrap: wrap;
}

.filter__item {
  padding: 10px 10px;
  flex: 1 1 auto;
  width: 40%;
}

.filter__item-title {
  padding-left: 10px;
  padding-bottom: 5px;
  font-size: 14px;
  color: #2d3757;
  font-weight: bold;
}

.filter__body .multiselect {
  width: 100%;
  z-index: 3;
}

.btn-apply {
  /* display: flex; */
  justify-content: end;
}

.btn-apply__body {
  /* background: rgb(106, 141, 255); */
  color: #fff;
  /* font-size: 1.25em; */
  padding: 10px 30px;
  margin-right: 20px;
  cursor: pointer;

  background: #2d3757;
  border-radius: 2px;

  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  /* line-height: 19px; */

  color: #ffffff;
}

.bh_none {
  font-weight: bold;
}
</style>
