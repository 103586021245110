import * as Mutation from './mutation-types'
// import { getEventIndexById } from './utils'

export default {
  [Mutation.CREATE_TABLE] (state, table) {
    return state.tables.push(table)
  },
  [Mutation.GET_HOLIDAYS] (state, holidayRecord) {
    ["data"]
    return state.holidayRecords.push(holidayRecord)
  },
  // [Mutation.SET_HOLIDAYS] (state, holidayRecords) {
  [Mutation.SET_HOLIDAYS] (state, body) {
    // return state.holidayRecords = holidayRecords;
    state.holidayClist = body["clist"];
    state.holidayReportData = body["data"];

    let countries = body["filters"]["country"];
    for (const key in countries) {
      const element = countries[key];
      state.holidayFilters.country.push(element);
    }

    let states = body["filters"]["state"];
    for (const key in states) {
      const element = states[key];
      state.holidayFilters.state.push(element);
    }

    /* state.holidayFilters.country = body["filters"]["country"];
    state.holidayFilters.state = body["filters"]["state"]; */
    
    return state.holidayRecords = body["allFields"];
    // console.log(holidayRecords);
    // return state.holidayRecords.push({"test": 1});
  },
  [Mutation.COUNT_HOLIDAYS] (state) {
    return state.holidayRecords.length;
  },
  // [Mutation.SET_REF] (state, event) {
  //   return state.refCalendar = event
  // },
  // [Mutation.UPDATE_EVENT] (state) {
  //   state.events = [];
  //   // const index = getEventIndexById(state, updatedEvent.id)

  //   // if (index === -1) {
  //   //   return console.warn(`Unable to update event (id ${updatedEvent.id})`)
  //   // }
    
  //   // return state.events.splice(index, 1, {
  //   //   ...state.events[index],
  //   //   title: updatedEvent.title,
  //   //   start: updatedEvent.start,
  //   //   end: updatedEvent.end,
  //   //   date: updatedEvent.date
  //   // })
  // },
  // [Mutation.DELETE_EVENT] (state, eventId) {
  //   const index = getEventIndexById(state, eventId)

  //   if (index === -1) {
  //     return console.warn(`Unable to delete event (id ${eventId})`)
  //   }
    
  //   return state.events.splice(index, 1)
  // },
  // [Mutation.SET_WEEKENDS_ENABLED] (state, enabled) {
  //   state.weekendsVisible = enabled
  // },
  // [Mutation.COUNT_EVENT] (state) {
  //   return state.events.length;
  // },
}
