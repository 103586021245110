import Api from "./Api";

export default {
    reportById(table, reportId, filter) {
        var headers = {
            method: "POST",
            redirect: "follow",
            headers: { 
                "Content-Type": "application/json", 
                Authorization: "Bearer " + localStorage.token, 
            },
            
          };

        let data = JSON.stringify({
            filter: filter,
            token: localStorage.token,
        })
        
        return Api.post("/v1/qb/reports/" + reportId + "/run?table_id=" + table, 
        data, headers);
    }
}