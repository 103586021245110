<template>
  <div>
    <div v-if="owner != null">
      <div class="report-body">
        <template v-for="(item, index) in owner.owner_size_zones" :key="index">
          <table class="report-table" style="margin-top: 8px">
            <thead>
            <tr class="report-table__head">
              <th>Size</th>
              <th>Zone</th>
              <th>Rank</th>
              <!-- <th v-for="item in fields" :key="item.id">
            <div>{{ item.label }}</div>
          </th> -->
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>{{ item.owner_size.size.name }}</td>
              <td>{{ item.zone.name }}</td>
              <!-- <td>{{ item.rank }}</td> -->
              <td>
                <VueMultiselect
                    v-model="item.rank"
                    :options="this.ranks"
                ></VueMultiselect>
              </td>

            </tr>
            </tbody>
          </table>

          <table class="report-table sub-table">
            <thead>
            <tr class="report-table__sub-head">
              <th>First Name</th>
              <th>Surname</th>
              <th>Email</th>
              <th>Telephone</th>
              <th>Skype</th>
              <th  class="unlink-field-header"></th>
              <!-- <th v-for="item in fields" :key="item.id">
                <div>{{ item.label }}</div>
              </th> -->
            </tr>
            </thead>
            <tbody>
            <template
                v-for="(individual, individualIndex) in item.individuals"
                :key="individualIndex"
            >
              <tr>
                <td>{{ individual.first_name }}</td>
                <td>{{ individual.surname }}</td>
                <td>{{ individual.email }}</td>
                <td>{{ individual.telephone }}</td>
                <td>{{ individual.skype }}</td>
                <td class="unlink-field" @click="(e) => unlinkIndividual(index, individualIndex)">
                    <span>
                      X
                    </span>
                </td>
              </tr>
            </template>

            <tr>
              <td colspan="5">
                <!-- <div class="add-individual-wrap">
                  <Multiselect
                    v-model="individual"
                    :options="this.individuals"
                    :searchable="true"
                    label="first_name"
                    trackBy="id"
                    track-by="id"
                    valueProp="id"
                  ></Multiselect>
                  <button class="add-individual-btn">+ Add Individual</button>
                </div> -->

                <AddIndividual
                    :individuals="individuals"
                    :selectedIndividuals="item.individuals"
                    @addIndividual="individual => addIndividual(individual, index)"
                ></AddIndividual>

<!--                                <AddIndividual :individuals="this.individuals" :ownerSizeZoneIndex="index" @addIndividual="this.addIndividual"></AddIndividual>-->
              </td>
            </tr>
            </tbody>
          </table>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
// import Multiselect from "@vueform/multiselect";
import AddIndividual from "./components/AddIndividual";
import Owner from "@/api/tradingPlatform/owner";
import OwnerSizeZone from "@/api/tradingPlatform/ownerSizeZone";
import VueMultiselect from "vue-multiselect";
import IndividualApi from '@/api/tradingPlatform/individual';

export default {
  name: "IndividualsForOwner",
  components: {
    // Multiselect,
    AddIndividual,
    VueMultiselect,
  },
  emits: ["collectIndividuals"],
  props: {
    rid: Number,
    individuals: Array,
  },
  data: function () {
    return {
      owner: null,
      individual: {},
      ranks: [0, 1, 2, 3],
    };
  },
  async mounted() {
    this.getData();
  },
  watch: {},
  computed: {
    ownersIndividuals() {
      let res = {};
      if(this.owner != null) {
        for (const oszKey in this.owner["owner_size_zones"]) {
          if (Object.hasOwnProperty.call(this.owner["owner_size_zones"], oszKey)) {
            res[this.owner["owner_size_zones"][oszKey]["id"]] = [];
          }
        }

        for (const oszKey in this.owner["owner_size_zones"]) {
          if (Object.hasOwnProperty.call(this.owner["owner_size_zones"], oszKey)) {
            const individuals = this.owner["owner_size_zones"][oszKey]["individuals"];
            for (const individualKey in individuals) {
              if (Object.hasOwnProperty.call(individuals, individualKey)) {
                const individual = individuals[individualKey];
                if (Object.hasOwnProperty.call(res, this.owner["owner_size_zones"][oszKey]["id"])) {
                  res[this.owner["owner_size_zones"][oszKey]["id"]].push(individual["id"]);
                } else {
                  res[this.owner["owner_size_zones"][oszKey]["id"]] = [];
                  res[this.owner["owner_size_zones"][oszKey]["id"]].push(individual["id"]);
                }
              }
            }
          }
        }
      }
      return res;
    }
  },
  methods: {
    linkIndividualsToOwner() {
      IndividualApi.addIndividualsToOwnerSizeZones(this.owner["id"], this.ownersIndividuals);
    },
    getData() {
      Owner.getOwnerByRid(this.rid).then((response) => {
        this.owner = response.data["data"];
      });

    },
    addIndividual(individual, index) {
      this.owner["owner_size_zones"][index]["individuals"].push(individual);
      this.collectIndividuals(this.owner["owner_size_zones"][index]["id"], individual["id"]);
    },

    collectIndividuals(ownerSizeZoneId, individualId) {
      this.$emit("collectIndividuals", ownerSizeZoneId, individualId);
    },
    unlinkIndividual(sizeZoneIndex, individualIndex) {
      this.owner["owner_size_zones"][sizeZoneIndex]["individuals"].splice(individualIndex, 1);
    },
    async updateRanks() {
      let res = [];
      for(let key in this.owner.owner_size_zones) {
        let element = this.owner.owner_size_zones[key];
        res.push({
          "id": element.id,
          "rank": element.rank,
        });
      }

      if(res.length != 0) {
        OwnerSizeZone.update(res);
      }

    }
  },
};
</script>

<style scoped src="vue-multiselect/dist/vue-multiselect.css">
</style>

<style scoped>
a {
  color: #42b983;
}

td {
  border-right: 1px solid #d3dfed;
  border-bottom: 1px solid #d3dfed;
  /* padding: 15px; */
}
.report-body {
  /* overflow-y: auto;
  overflow-x: auto; */
  position: relative;
  margin-top: 10px;
  padding-bottom: 10px;
}

thead {
  background: #f6faff;
}

tbody {
  /* border-top: 1px solid #d3dfed; */
  border-left: 1px solid #d3dfed;
}

tbody > tr:first-child > td {
  border-top: 1px solid #d3dfed;
}

.report-table {
  table-layout: fixed;
  border-collapse: collapse;
  position: relative;
}

.report-table thead tr {
  position: relative;
}

th {
  position: sticky;
  z-index: 1;
  border: 0;
  padding: 8px 16px;
  color: rgb(255, 255, 255);
  -webkit-font-smoothing: antialiased;
  cursor: default;
  vertical-align: middle;

  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  color: #2d3757;
}

.report-table td div {
  white-space: nowrap;
}

.report-table__head {
  position: sticky;
  z-index: 1;
  background: #f6faff;
}

.report-table__sub-head {
  position: sticky;
  z-index: 1;
  background: #f6faff;
}

.report-table__sub-head th {
  padding: 8px;
}

td {
  padding: 5px;
}

.add-individual-wrap {
  text-align: center;
}
.add-individual-btn {
  background: #2d3757;
  color: #f6faff;
  padding: 4px 16px;
}

.sub-table {
  position: relative;
  margin-left: 16px;
}

.sub-table::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  width: 16px;
  background: #2d3757;
  left: -16px;
}

.unlink-field {
  border: 0;
  border-top: 1px #fff solid;
  font-weight: bold;
  cursor: pointer;
}

tbody > tr:first-child > td.unlink-field {
  border-top: 1px #fff solid;
}

.unlink-field span {
  visibility: hidden;
}

tr:hover > .unlink-field span {
  visibility: visible;
}

.unlink-field-header {
  border: 0 !important;
  background: #fff;
}
</style>