// import { createStore } from 'vuex'

import actions from './actions'
import mutations from './mutations'

const state = {
  tables: [
    // { id: "10", name: 'All day event'},
    // { id: 20, title: 'Timed event', start: addDays(new Date(), 1) },
    // { id: 30, title: 'Timed event', start: addDays(new Date(), 2) }
  ],
  holidayRecords: [],
  holidayClist: [],
  holidayReportData: [],
  holidayFilters: {
    country: [],
    state: [],
  }
}

const getters = {
  tables: state => state.tables,
  holidayRecords: state => state.holidayRecords,
  holidayClist: state => state.holidayClist,
  holidayReportData: state => state.holidayReportData,
}

/* export default createStore({
  state,
  getters,
  mutations,
  actions,
  modules: {
  }
}) */

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}