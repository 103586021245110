// import { createStore } from 'vuex'

import actions from './actions'
import mutations from './mutations'

const state = {
  reports: [],
  // tables: [
  //   // { id: "10", name: 'All day event'},
  //   // { id: 20, title: 'Timed event', start: addDays(new Date(), 1) },
  //   // { id: 30, title: 'Timed event', start: addDays(new Date(), 2) }
  // ],
  // records: [],
  // clist: [],
  // reportData: [],
}

const getters = {
  reports: state => state.reports,
  getReportById: (state) => (id) => {
    console.log("getReportById");
    return state.reports.find(report => report.id == id) ?? {
      id: 0,
      data: [],
      fields: [],
      allFields: [],
      clist: [],
      loading: true,
      relationships: [],
    }
  }
  // tables: state => state.tables,
  // records: state => state.records,
  // clist: state => state.clist,
  // reportData: state => state.reportData,
}

/* export default createStore({
  state,
  getters,
  mutations,
  actions,
  modules: {
  }
}) */

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}