import Holiday from '@/api/Holiday'
import * as Mutation from './mutation-types'

export default {
  createTable ({ commit }, event) {
    return commit(Mutation.CREATE_TABLE, event)
  },
  getHolidays ({ commit }, reportId) {
    console.log(commit);
    console.log("getHolidays1");
    Holiday.reportById(reportId).then(response => {
      console.log("getHolidays");
      console.log(response);
      // commit(Mutation.SET_HOLIDAYS_CLIST, response.data["body"]["data"])
      // commit(Mutation.SET_HOLIDAYS_REPORT_DATA, response.data["body"]["data"])
      return commit(Mutation.SET_HOLIDAYS, response.data["body"])

    });
  },
  getCount ({ commit }) {
    return commit(Mutation.COUNT_HOLIDAYS)
  },
  /* createEvent ({ commit }, event) {
    return commit(Mutation.CREATE_EVENT, event)
  },
  updateEvent ({ commit }, updatedEvent) {
    return commit(Mutation.UPDATE_EVENT, updatedEvent)
  },
  deleteEvent ({ commit }, eventId) {
    return commit(Mutation.DELETE_EVENT, eventId)
  },
  setweekendsVisible ({ commit }, enabled) {
    return commit(Mutation.SET_WEEKENDS_ENABLED, enabled)
  },
  setRef ({ commit }, event) {
    return commit(Mutation.CREATE_EVENT, event)
  },
  getCount ({ commit }) {
    return commit(Mutation.COUNT_EVENT)
  }, */
}
