<template>
  <div class="report-panel">
    <!-- <div class="report__title" v-if="gettingReport || reportData.length != 0">
      <h2>{{ title }}</h2>
    </div> -->
    <div class="preloader" v-if="gettingReport">
      <CustomLoader />
    </div>
    <div v-else-if="reportData.length != 0">
      <div class="report-body">
        <!-- <QbReportsItem v-for="report in reports" :report="report" :key="report.id" @getInfo="getInfoReport"/> -->
        <!-- <div v-for="(row, index) in reportData" :key="index">
        <div v-for="(item, index2) in row" :key="index + index2">
        <div>({{index}} {{index2}}) -- {{item.value}}</div>
        </div>
      </div> -->
        <table class="report-table">
          <thead>
            <tr class="report-table__head">
              <th class="report-table__sticky report-table__col-pencil"></th>
              <th
                v-for="item in clist"
                :key="item"
                :class="[this.styleForCol(item)]"
              >
                <div>
                  {{ this.prepNameForColumnTitle(objFields[item].label) }}
                </div>
              </th>
              <!-- <th v-for="item in fields" :key="item.id">
              <div>{{ item.label }}</div>
            </th> -->
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, index) in reportData" :key="index">
              <!-- <td>
                <div
                  class="record__edit-button"
                  @click="() => openPopUpForEditRecord(row[3].value)"
                ></div>
              </td> -->
              <td class="report-table__sticky report-table__col-pencil">
                <div
                  class="record__edit-button"
                  @click="() => openPopUpForDocumentEditRecord(row[3].value)"
                ></div>
              </td>
              <td
                v-for="item in clist"
                :key="item"
                :class="[this.styleForCol(item)]"
              >
                <!-- <div 
                @input="(event) => textareaInput(event, index, item)" contentEditable="true">{{ row[item].value }}</div>
              <textarea v-model="row[item].value"></textarea> -->
                <!-- <textarea
                v-if="
                  this.objFields[item].fieldType == 'text' ||
                  this.objFields[item].fieldType == 'number'
                "
                :value="row[item].value"
                @input="(event) => textareaInput(event, index, item)"
              ></textarea>
              <a
                v-else-if="this.objFields[item].fieldType == 'url'"
                :href="row[item].value"
                >{{ this.objFields[item].label }}</a
              > -->
                <div
                  v-if="this.fidsForFFModal.includes(item) && !!row[item].value"
                  class="item-modal report-table__item-modal"
                  @click="() => FFModal(index, item)"
                >
                  <!-- @click="() => showParentRecords(index, item)" -->

                  <div class="item-modal__title">
                    {{ row[item].value }}
                  </div>
                  <!-- <div class="item-modal__img"></div> -->
                </div>

                <div
                  class="report-table__item-formula-wrap modal-contract-type"
                  v-else-if="this.objFields[item].fieldType == 'duration'"
                >
                  <div class="report-table__item-formula_other">
                    {{
                      row[item].value
                        ? row[item].value / 100 / 60 / 60 / 24 + " days"
                        : ""
                    }}
                  </div>
                </div>

                <div
                  class="report-table__item-formula-wrap modal-contract-type"
                  v-else-if="item == 484"
                >
                  <div
                    :data-row="index"
                    :data-fid="item"
                    v-if="this.objFields[item].fieldType == 'rich-text'"
                    v-html="row[item].value"
                  ></div>
                  <div class="report-table__item-formula_other" v-else>
                    {{ row[item].value }}
                  </div>
                </div>
                <div
                  class="report-table__item-formula-wrap add tasks"
                  v-else-if="item == 173"
                >
                  <div
                    :data-row="index"
                    :data-fid="item"
                    v-if="this.objFields[item].fieldType == 'rich-text'"
                    v-html="row[item].value"
                  ></div>
                  <div class="report-table__item-formula_other" v-else>
                    {{ row[item].value }}
                  </div>
                </div>

                <div
                  class="report-table__item-formula-wrap add add-task"
                  v-else-if="item == 558"
                >
                  <div v-if="!row[item].value"></div>
                  <div v-else>
                    <div
                      :data-row="index"
                      :data-fid="item"
                      v-if="this.objFields[item].fieldType == 'rich-text'"
                      v-html="row[item].value"
                    ></div>
                    <div class="report-table__item-formula_other" v-else>
                      <a :href="row[item].value" target="_blank"
                        >Google Drive</a
                      >
                    </div>
                  </div>
                </div>

                <div
                  class="report-table__item-formula-wrap add add-task"
                  v-else-if="item == 191"
                >
                  <div
                    :data-row="index"
                    :data-fid="item"
                    v-if="this.objFields[item].fieldType == 'rich-text'"
                    v-html="row[item].value"
                  ></div>
                  <div class="report-table__item-formula_other" v-else>
                    {{ row[item].value }}
                  </div>
                </div>
                <!-- <div
                  v-if="item == 173"
                  class="item-modal report-table__item-modal"
                  @click="() => showParentRecords(index, item)"
                >
                  <div class="item-modal__title">
                    {{ row[item].value }}
                  </div>
                  <div class="item-modal__img"></div>
                </div> -->
                <div v-else-if="this.objFields[item].mode == ''">
                  <a
                    v-if="this.objFields[item].fieldType == 'url'"
                    :href="row[item].value"
                    >{{ this.objFields[item].label }}</a
                  >
                  <a
                    v-else-if="this.objFields[item].fieldType == 'dblink'"
                    :href="row[item].value"
                    >{{ this.objFields[item].label }}</a
                  >
                  <div
                    class="report-table__item-text-multiple-choice-wrap"
                    v-else-if="this.objFields[item].fieldType == 'date'"
                  >
                    {{ formatDateFunc(row[item].value) }}
                    <!-- <DatePicker
                      v-model="row[item].value"
                      :enableTimePicker="false"
                      :format="(t) => formatDateFunc(t)"
                      disabled
                      @update:modelValue="
                        () => handleDate(row[item].value, index, item)
                      "
                    /> -->
                    <!-- <input type="date" v-model="row[item].value" /> -->
                  </div>
                  <div
                    class="report-table__item-text-multiple-choice-wrap"
                    v-else-if="this.objFields[item].fieldType == 'timestamp'"
                  >
                    {{ row[item].value }}
                    <!-- <DatePicker v-model="row[item].value" /> -->
                    <!-- <input type="datetime-local" v-model="row[item].value" /> -->
                    <!-- DATETIME -->
                  </div>
                  <div
                    class="report-table__item-boolean-wrap"
                    v-else-if="this.objFields[item].fieldType == 'checkbox'"
                  >
                    <input type="checkbox" disabled v-model="row[item].value" />
                    <!-- {{ row[item].value }} -->
                  </div>
                  <div
                    class="report-table__item-text-multiple-choice-wrap"
                    v-else-if="
                      this.objFields[item].fieldType == 'text-multiple-choice'
                    "
                  >
                    {{ row[item].value }}
                    <!-- <Multiselect
                      v-model="row[item].value"
                      :options="this.objFields[item].properties.choices"
                      clearOnSelect="false"
                      placeholder="Choose"
                      disabled
                      :close-on-select="false"
                    ></Multiselect> -->
                  </div>
                  <div
                    class="report-table__item-text-multiple-choice-wrap"
                    v-else-if="
                      this.objFields[item].fieldType == 'currency'
                    "
                  >
                    {{ this.currencyFormat(row[item].value) }}
                    <!-- <Multiselect
                      v-model="row[item].value"
                      :options="this.objFields[item].properties.choices"
                      clearOnSelect="false"
                      placeholder="Choose"
                      disabled
                      :close-on-select="false"
                    ></Multiselect> -->
                  </div>
                  <div class="report-table__item-wrap" v-else>
                    <!-- @click="(e) => replaceTextToEditField(e, index, item)" -->
                    <div
                      class="report-table__item"
                      style="
                        display: block;
                        height: 100%;
                        width: 100%;
                        padding: 15px;
                      "
                    >
                      <div>{{ row[item].value }}</div>
                    </div>
                    <textarea
                      style="display: none"
                      :value="row[item].value"
                      :data-row="index"
                      :data-fid="item"
                      @input="(event) => textareaInput(event, index, item)"
                    ></textarea>
                    <!-- <div class="red">
                      {{ row[item].value }}
                    </div> -->
                    <!-- <button @click="() => showParentRecords(index, item)">
                      Edit parent record
                    </button>
                    <textarea
                      :value="row[item].value"
                      @input="(event) => textareaInput(event, index, item)"
                    ></textarea> -->
                  </div>
                  <!-- <textarea
                    v-else
                    :value="row[item].value"
                    @input="(event) => textareaInput(event, index, item)"
                  ></textarea> -->
                </div>
                <div v-else-if="this.objFields[item].mode == 'lookup'">
                  <a
                    v-if="this.objFields[item].fieldType == 'url'"
                    :href="row[item].value"
                    >{{ this.objFields[item].label }}</a
                  >
                  <a
                    v-else-if="this.objFields[item].fieldType == 'dblink'"
                    :href="row[item].value"
                    >{{ this.objFields[item].label }}</a
                  >
                  <div class="report-table__item-wrap" v-else>
                    <!-- @click="(e) => replaceTextToEditField(e, index, item)" -->
                    <div
                      class="report-table__item"
                      style="
                        display: block;
                        height: 100%;
                        width: 100%;
                        padding: 15px;
                      "
                    >
                      <div>{{ row[item].value }}</div>
                    </div>
                    <textarea
                      :data-row="index"
                      :data-fid="item"
                      style="display: none"
                      :value="row[item].value"
                      @input="(event) => textareaInput(event, index, item)"
                    ></textarea>
                    <!-- <div class="green">
                      {{ row[item].value }}
                    </div> -->
                    <!-- <button @click="() => showParentRecords(index, item)">
                      Edit parent record
                    </button>
                    <textarea
                      :value="row[item].value"
                      @input="(event) => textareaInput(event, index, item)"
                    ></textarea> -->
                  </div>
                </div>
                <div
                  class="report-table__item-formula-wrap"
                  v-else-if="this.objFields[item].mode == 'formula'"
                >
                  <div
                    v-if="this.objFields[item].fieldType == 'rich-text'"
                    v-html="row[item].value"
                  ></div>
                  <div class="report-table__item-formula_other" v-else>
                    {{ row[item].value }}
                  </div>
                </div>
                <div
                  class="report-table__item-formula-wrap"
                  v-else-if="this.objFields[item].mode == 'summary'"
                >
                  <div class="report-table__item-formula_other">
                    {{ row[item].value }}
                  </div>
                </div>
                <a
                  v-else-if="this.objFields[item].fieldType == 'url'"
                  :href="row[item].value"
                  >{{ this.objFields[item].label }}</a
                >
                <div class="report-table__item-wrap" v-else>
                  <!-- @click="(e) => replaceTextToEditField(e, index, item)" -->
                  <div
                    class="report-table__item"
                    style="
                      display: block;
                      height: 100%;
                      width: 100%;
                      padding: 15px;
                    "
                  >
                    <div>{{ row[item].value }}</div>
                  </div>
                  <textarea
                    style="display: none"
                    :value="row[item].value"
                    :data-row="index"
                    :data-fid="item"
                    @input="(event) => textareaInput(event, index, item)"
                  ></textarea>
                  <!-- <div class="blue">
                    {{ row[item].value }}
                  </div> -->
                  <!-- <button @click="() => showParentRecords(index, item)">
                      Edit parent record
                    </button>
                    <textarea
                      :value="row[item].value"
                      @input="(event) => textareaInput(event, index, item)"
                    ></textarea> -->
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- <div v-if="report" class="btn-save">
        <div class="btn-save__body">
          <custom-loader v-if="saving" />
          <span v-else>Save</span>
        </div>
      </div> -->
    </div>

    <div v-if="showModalWithParentData" class="modal">
      <div class="modal__mask">
        <div class="modal__wrapper">
          <div class="modal__container">
            <div class="modal__header">
              <div class="modal__title">{{ modalName }}</div>
              <div
                class="modal__close"
                v-on:click="showModalWithParentData = false"
              ></div>
              <!-- <button
                
                class="modal__default-button"
              >
                Close
              </button> -->
            </div>

            <div class="modal__body">
              <div class="switch-bar modal__switch-bar">
                <div
                  class="switch-bar__item"
                  v-bind:class="{
                    'switch-bar__item_active': index == selectedSwitcher,
                  }"
                  v-on:click="selectedSwitcher = index"
                  v-for="(value, index) in switcherBody[masterTableId]"
                  :key="index"
                >
                  {{ index }}
                </div>
                <!-- <div class="switch-bar__item switch-bar__item_active">General</div> -->
                <!-- <div class="switch-bar__item">Freight</div>
                <div class="switch-bar__item">Reminders</div> -->
              </div>
              <div class="switcher-content">
                <div class="switcher-content__choose">
                  <div
                    class="switcher-content__item"
                    v-bind:class="{
                      'switcher-content__item_100': value.width == 100,
                      'switcher-content__item_25': value.width == 25,
                    }"
                    v-for="(value, index) in switcherBody[masterTableId][
                      selectedSwitcher
                    ]"
                    :key="index"
                  >
                    <!-- <div class="switcher-content__title">{{ value }}</div> -->
                    <div class="switcher-content__title">
                      {{ this.objParentFields[value.key].label }}
                    </div>
                    <div class="switcher-content__data">
                      <!-- {{this.parentReportData[value.key]}} -->
                      <!-- {{this.parentReportData[value.key].value}} -->
                      <input
                        type="text"
                        v-model="this.parentReportData[value.key].value"
                        class="switcher-content__data-input"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <!-- <table class="report-table">
                <tbody>
                  <tr v-for="(value, index) in parentReportData" :key="index">
                    <td>
                      <b>{{ this.objParentFields[index].label }}</b>
                    </td>
                    <td>
                      <textarea :value="value.value"></textarea>
                    </td>
                  </tr>
                </tbody>
              </table> -->
            </div>

            <div class="modal__footer">
              <div
                class="modal__close-button"
                v-on:click="showModalWithParentData = false"
              >
                Cancel
              </div>
              <div class="modal__save-button">Save</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="showFFModal" class="modal">
      <div class="modal__mask">
        <div class="modal__wrapper">
          <div class="modal__container">
            <div class="modal__header">
              <div class="modal__title">Freight Fixture</div>
              <div class="modal__close" v-on:click="showFFModal = false"></div>
              <!-- <button
                
                class="modal__default-button"
              >
                Close
              </button> -->
            </div>

            <div class="modal__body">
              <div class="switch-bar modal__switch-bar">
                <div
                  class="switch-bar__item"
                  v-bind:class="{
                    'switch-bar__item_active': index == selectedSwitcher,
                  }"
                  v-on:click="selectedSwitcher = index"
                  v-for="(value, index) in switcherFFBody"
                  :key="index"
                >
                  {{ index }}
                </div>
                <!-- <div class="switch-bar__item switch-bar__item_active">General</div> -->
                <!-- <div class="switch-bar__item">Freight</div>
                <div class="switch-bar__item">Reminders</div> -->
              </div>
              <div class="switcher-content">
                <div class="switcher-content__choose">
                  <div
                    class="switcher-content__item"
                    v-bind:class="{
                      'switcher-content__item_100': value.width == 100,
                      'switcher-content__item_25': value.width == 25,
                    }"
                    v-for="(value, index) in switcherFFBody[selectedSwitcher]"
                    :key="index"
                  >
                    <!-- <div class="switcher-content__title">{{ value }}</div> -->
                    <div class="switcher-content__title">
                      {{
                        this.objFields[
                          this.switcherFFBody[selectedSwitcher][index].key
                        ].label
                      }}
                    </div>
                    <div
                      class="switcher-content__data"
                      v-if="
                        this.FFCheckLookup(
                          this.switcherFFBody[selectedSwitcher][index].key
                        )
                      "
                    >
                      <!-- {{this.parentReportData[value.key]}} -->
                      <!-- {{this.parentReportData[value.key].value}} -->
                      <!-- v-model="this.parentReportData[value.key].value" -->
                      <input
                        type="text"
                        class="switcher-content__data-input"
                        disabled
                        v-model="
                          this.switcherFFBody[selectedSwitcher][index].value
                        "
                      />
                    </div>
                    <div class="switcher-content__data" v-else>
                      <!-- {{this.parentReportData[value.key]}} -->
                      <!-- {{this.parentReportData[value.key].value}} -->
                      <!-- v-model="this.parentReportData[value.key].value" -->
                      <input
                        type="text"
                        class="switcher-content__data-input"
                        @update:modelValue="
                          (e) =>
                            colectDataFF(
                              e,
                              this.switcherFFBody[selectedSwitcher][index].key
                            )
                        "
                        v-model="
                          this.switcherFFBody[selectedSwitcher][index].value
                        "
                      />
                    </div>
                  </div>
                </div>
              </div>

              <!-- <table class="report-table">
                <tbody>
                  <tr v-for="(value, index) in parentReportData" :key="index">
                    <td>
                      <b>{{ this.objParentFields[index].label }}</b>
                    </td>
                    <td>
                      <textarea :value="value.value"></textarea>
                    </td>
                  </tr>
                </tbody>
              </table> -->
            </div>

            <div class="modal__footer">
              <div class="modal__close-button" v-on:click="showFFModal = false">
                Cancel
              </div>
              <div @click="() => saveFFModal()" class="modal__save-button">
                Save
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <EditReportRecord
      v-if="showModalEditReportRecord"
      :open="showModalEditReportRecord"
      @showStatus="showStatusEditReportRecordPopUp"
      :rid="ridForEditReportRecord"
      @updateRecord="updateRecord"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";
// import QbReportsItem from "./components/QbReportsItem.vue";

//target="_blank"
import CustomLoader from "../loader/CustomLoader.vue";
// import Multiselect from "@vueform/multiselect";
import EditReportRecord from "@/components/pop-up/EditDocumentRecord.vue";

export default {
  name: "QbDocumentReport",
  components: {
    CustomLoader,
    EditReportRecord,
    // Multiselect,
  },
  props: {
    table: String,
    title: String,
    report: String,
    filter: {},
  },
  emits: ["getInfo"],
  data: function () {
    return {
      //pop-ups
      showModalAddTask: false,
      ridForAddTask: 0,
      showModalTasks: false,
      keyForTasks: "",

      showModalEditReportRecord: false,
      ridForEditReportRecord: 0,

      showModalContractType: false,
      modalContractTypeReportID: 0,

      colors: {
        102: "LightOrange",
        417: "LightOrange",
        233: "LightOrange",
        355: "LightBlue",
        356: "LightBlue",
        101: "LightBlue",
        363: "LightBlue",
        364: "LightBlue",
        344: "LightBlue",
        278: "LightGreen",
        280: "LightGreen",
        282: "LightGreen",
        285: "LightGreen",
        288: "LightGreen",
        290: "LightGreen",
        292: "LightGreen",
        294: "LightGreen",
      },

      showFFModal: false,
      dataFFModalForQB: {},

      chartererNameFilterValue: [],
      reports: [],
      reportData: [],
      fields: [],
      allFields: [],
      clist: [],
      relationships: [],
      forSave: {},
      saving: false,
      gettingReport: true,
      showModalWithParentData: false,

      parentReportData: [],
      parentFields: [],
      parentAllFields: [],

      // fidsForFFModal: [338,365,373,92,339,352,353,375,472,340,341,342,346,486,347,487,343],
      fidsForFFModal: [339],

      masterTableId: "",
      switcher: {
        brxivurjh: ["General", "Price", "Differentials", "Reminders"],
        brxix2mgw: ["General", "Freight", "Reminders"],
      },
      selectedSwitcher: "General",
      switcherBody: {
        // mamming for pop-ups
        brxivurjh: {
          General: [
            {
              key: 44,
              width: 50,
            },
            {
              key: 6,
              width: 50,
            },
            {
              key: 24,
              width: 50,
            },
            {
              key: 29,
              width: 50,
            },
            {
              key: 16,
              width: 50,
            },
            {
              key: 60,
              width: 50,
            },
            {
              key: 61,
              width: 50,
            },
            {
              key: 9,
              width: 50,
            },
            {
              key: 15,
              width: 50,
            },
          ],
          Price: [
            {
              key: 38,
              width: 25,
            },
            {
              key: 40,
              width: 25,
            },
            {
              key: 42,
              width: 25,
            },
          ],
          Differentials: [
            {
              key: 27,
              width: 25,
            },
          ],
          Reminders: [
            {
              key: 25,
              width: 25,
            },
          ],
        },
        brxix2mgw: {
          General: [
            {
              key: 18,
              width: 50,
            },
            {
              key: 107,
              width: 50,
            },
            {
              key: 51,
              width: 50,
            },
            {
              key: 45,
              width: 50,
            },
            {
              key: 35,
              width: 25,
            },
            {
              key: 69,
              width: 25,
            },
            {
              key: 70,
              width: 50,
            },
            {
              key: 50,
              width: 25,
            },
            {
              key: 6,
              width: 25,
            },

            {
              key: 29,
              width: 50,
            },
            {
              key: 31,
              width: 25,
            },
            {
              key: 58,
              width: 25,
            },
            {
              key: 82,
              width: 50,
            },
            {
              key: 66,
              width: 25,
            },

            {
              key: 63,
              width: 25,
            },
            {
              key: 83,
              width: 50,
            },
            {
              key: 67,
              width: 25,
            },
            {
              key: 64,
              width: 25,
            },
            {
              key: 65,
              width: 25,
            },

            {
              key: 7,
              width: 25,
            },
            {
              key: 11,
              width: 25,
            },
            {
              key: 46,
              width: 25,
            },
          ],
          Freight: [
            {
              key: 23,
              width: 50,
            },
            {
              key: 72,
              width: 50,
            },
            {
              key: 74,
              width: 25,
            },
            {
              key: 103,
              width: 25,
            },
            {
              key: 105,
              width: 25,
            },
            {
              key: 25,
              width: 25,
            },
          ],
          Reminders: [
            {
              key: 20,
              width: 50,
            },
            {
              key: 17,
              width: 50,
            },
            {
              key: 84,
              width: 100,
            },

            {
              key: 86,
              width: 100,
            },
            {
              key: 99,
              width: 50,
            },
            {
              key: 101,
              width: 50,
            },
          ],
        },
      },

      keyFFModal: 0,
      switcherFF: ["General", "Freight", "Reminders"],
      switcherFFBody: {
        General: [
          {
            key: 338,
            width: 50,
            label: "",
            value: "",
            type: "",
          },
          {
            key: 365,
            width: 50,
            label: "",
            value: "",
            type: "",
          },
          {
            key: 373,
            width: 50,
            label: "",
            value: "",
            type: "",
          },
          {
            key: 92,
            width: 50,
            label: "",
            value: "",
            type: "",
          },
          {
            key: 339,
            width: 50,
            label: "",
            value: "",
            type: "",
          },
          {
            key: 352,
            width: 50,
            label: "",
            value: "",
            type: "",
          },
          {
            key: 353,
            width: 50,
            label: "",
            value: "",
            type: "",
          },
          {
            key: 375,
            width: 50,
            label: "",
            value: "",
            type: "",
          },
          {
            key: 472,
            width: 50,
            label: "",
            value: "",
            type: "",
          },
          {
            key: 340,
            width: 50,
            label: "",
            value: "",
            type: "",
          },
          {
            key: 341,
            width: 50,
            label: "",
            value: "",
            type: "",
          },
          {
            key: 342,
            width: 50,
            label: "",
            value: "",
            type: "",
          },
          {
            key: 346,
            width: 50,
            label: "",
            value: "",
            type: "",
          },
          {
            key: 486,
            width: 50,
            label: "",
            value: "",
            type: "",
          },
          {
            key: 347,
            width: 50,
            label: "",
            value: "",
            type: "",
          },
          {
            key: 487,
            width: 50,
            label: "",
            value: "",
            type: "",
          },
          {
            key: 343,
            width: 50,
            label: "",
            value: "",
            type: "",
          },
        ],
        Freight: [
          {
            key: 344,
            width: 50,
            label: "",
            value: "",
            type: "",
          },
          {
            key: 355,
            width: 50,
            label: "",
            value: "",
            type: "",
          },
          {
            key: 356,
            width: 50,
            label: "",
            value: "",
            type: "",
          },
          {
            key: 363,
            width: 50,
            label: "",
            value: "",
            type: "",
          },
          {
            key: 364,
            width: 50,
            label: "",
            value: "",
            type: "",
          },
          {
            key: 345,
            width: 50,
            label: "",
            value: "",
            type: "",
          },
        ],
        Reminders: [
          {
            key: 349,
            width: 50,
            label: "",
            value: "",
            type: "",
          },
          {
            key: 359,
            width: 50,
            label: "",
            value: "",
            type: "",
          },
          {
            key: 348,
            width: 50,
            label: "",
            value: "",
            type: "",
          },
          {
            key: 360,
            width: 50,
            label: "",
            value: "",
            type: "",
          },
          {
            key: 361,
            width: 50,
            label: "",
            value: "",
            type: "",
          },
          {
            key: 362,
            width: 50,
            label: "",
            value: "",
            type: "",
          },
          {
            key: 350,
            width: 50,
            label: "",
            value: "",
            type: "",
          },
          {
            key: 351,
            width: 50,
            label: "",
            value: "",
            type: "",
          },
          {
            key: 354,
            width: 50,
            label: "",
            value: "",
            type: "",
          },
        ],
      },
      /* switcherFFBody: {
        General: [
          {
            key: 338,
            width: 50,
          },
        ],
        Freight: [
          {
            key: 365,
            width: 50,
          },
        ],
        Reminders: [
          {
            key: 20,
            width: 50,
          },
          
        ],
      }, */
    };
  },
  watch: {
    report() {
      console.log(this.report);
      this.getQBReport();
    },
    filter() {
      this.getQBReport();
    },
  },
  mounted() {
    // this.getQBTables();
    this.getQBReport();
  },
  updated() {
    console.log(this.$el.querySelector(".add"));
    let allAddTaskBtn = this.$el.querySelectorAll(".add-task .Vibrant");
    allAddTaskBtn.forEach((el) => {
      el.onclick = (e) => {
        console.log("test");
        console.log(this);
        this.showModalAddTask = true;
        let rowId = e.target.parentElement.dataset.row;
        console.log(rowId);
        console.log(this.reportData[rowId][3].value);
        this.ridForAddTask = this.reportData[rowId][3].value;
        // let fid = e.target.parentElement.dataset.fid;
        return false;
      };
    });

    let allTasksBtn = this.$el.querySelectorAll(".tasks .Vibrant");
    allTasksBtn.forEach((el) => {
      el.onclick = (e) => {
        console.log("test");
        console.log(e);
        console.log(e.target);
        console.log(e.currentTarget);
        console.log(e.currentTarget.parentElement);
        console.log(e.target.parentElement.dataset.row);
        console.log(this);
        this.showModalTasks = true;
        this.keyForTasks = this.getKeyForRow(
          e.target.parentElement.dataset.row
        );
        return false;
      };
    });

    let allContractTypeBtn = this.$el.querySelectorAll(
      ".modal-contract-type a"
    );
    allContractTypeBtn.forEach((el) => {
      el.onclick = (e) => {
        console.log("test");
        this.keyForTasks = this.getKeyForRow(
          e.target.parentElement.dataset.row
        );
        if (e.currentTarget.innerHTML.toString().indexOf("Cnf") !== -1) {
          this.modalContractTypeReportID = 15;
          console.log("open Cnf");
        } else if (e.currentTarget.innerHTML.toString().indexOf("Fob") !== -1) {
          this.modalContractTypeReportID = 16;
          console.log("open Fob");
        }
        this.showModalContractType = true;
        return false;
      };
    });
  },
  computed: {
    ...mapGetters(["tables"]),

    modalName() {
      if (this.masterTableId == "brxivurjh") {
        return "Sales Contract";
      } else if (this.masterTableId == "brxix2mgw") {
        return "Freight Fixture";
      } else {
        return "";
      }
    },
    objFields() {
      let res = {};
      // this.fields.forEach((val) => {
      //   res[val["id"]] = val;
      // });
      this.allFields.forEach((val) => {
        res[val["id"]] = val;
      });
      return res;
    },
    objParentFields() {
      let res = {};
      // this.fields.forEach((val) => {
      //   res[val["id"]] = val;
      // });
      this.parentAllFields.forEach((val) => {
        res[val["id"]] = val;
      });
      return res;
    },
    lookups() {
      let res = [];
      for (const key in this.relationships) {
        if ("lookupFields" in this.relationships[key]) {
          for (const k in this.relationships[key].lookupFields) {
            res.push(this.relationships[key].lookupFields[k].id);
          }
        }
      }
      return res;
    },
  },
  methods: {
    ...mapActions(["createTable"]),

    openPopUpForDocumentEditRecord(recordId) {
      console.log(recordId);
      this.ridForEditReportRecord = recordId;
      this.showModalEditReportRecord = true;
    },

    showStatusEditReportRecordPopUp(status) {
      this.showModalEditReportRecord = status;
    },


    formatDateFunc(date) {
      if (date) {
        if (moment(date).year() == moment().year()) {
          return moment(date).format("MMM DD");
        }
        return moment(date).format("MMM DD, yyyy");
      } else {
        return "";
      }
    },
    styleForCol(fid) {
      if (Object.hasOwnProperty.call(this.colors, fid)) {
        return this.colors[fid];
      }
      return "";
    },

    getKeyForRow(row) {
      return this.reportData[row][3]["value"];
    },

    currencyFormat(value) {
      // currencyFormat(value) {
      if (value) {
        /* let formatedValue = value.toLocaleString("en-US", {
            style: 'currency',
          }); */
        let formatter = new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
          maximumFractionDigits: 2,
        });
        return formatter.format(value);
      } else {
        return "";
      }
    },

    async getQBReport() {
      this.gettingReport = true;
      var requestOptions = {
        method: "POST",
        redirect: "follow",
        headers: { 
          "Content-Type": "application/json",
          "Authorization": "Bearer " + localStorage.token
        },
        body: JSON.stringify({
          filter: this.filter,
          token: localStorage.token,
        }),
      };

      fetch(
        process.env.VUE_APP_API_DOMAIN +
        // process.env.VUE_APP_API_DOMAIN1 +
          "/v1/qb/reports/" +
          this.report +
          "/document/run?table_id=" +
          this.table,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          console.log(result);
          this.reportData = result["body"]["data"];
          this.fields = result["body"]["fields"];
          this.allFields = result["body"]["allFields"];
          this.clist = result["body"]["report"]["query"]["fields"];
          this.relationships = result["body"]["relationships"];
          this.gettingReport = false;
          // this.clients = result;
        })
        .catch((error) => {
          console.log("error", error);
          this.gettingReport = false;
        });
    },
    prepNameForColumnTitle(label) {
      let countDash = label.split("-").length - 1;
      if (countDash > 1) {
        let tmp = label.split("-");
        tmp.shift();
        return tmp.join("-").replace(/formula/g, "");
      } else {
        return label.replace(/formula/gi, "");
      }
    },
  },
};
</script>

<style src="@vueform/multiselect/themes/default.css"></style>

<style>
.modal-contract-type * {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #2d3757;
  text-decoration: none;
}
</style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.report-panel {
  max-width: 100%;
  overflow-y: hidden;
  overflow-x: auto;
}

.report-body {
  /* height: 100%; */
  /* height: 700px; */
  overflow-y: auto;
  overflow-x: auto;
  position: relative;
  margin-top: 10px;
  /* display: flex; */
  padding-bottom: 10px;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

td {
  border-right: 1px solid #d3dfed;
  border-bottom: 1px solid #d3dfed;
  /* padding: 15px; */
}

thead {
  background: #f6faff;
}

tbody {
  border-top: 1px solid #d3dfed;
  border-left: 1px solid #d3dfed;
}

.report-table {
  /* margin-top: 50px; */
  /* width: 100%; */
  table-layout: fixed;
  border-collapse: collapse;
  /* height: 90vh; */
  position: relative;
}

/* .report-table thead {
  background-color: #333;
  color: #fdfdfd;
  position: sticky;
} */

.report-table thead tr {
  /* display: block; */
  position: relative;
}

/* .report-table tbody tr:hover {
  background: #000;
} */

.report-table tbody tr td.report-table__col-pencil .record__edit-button {
  background: #eee;
  width: 32px;
  height: 32px;
  border-radius: 5px;
  flex-grow: 0; /* do not grow   - initial value: 0 */
  flex-shrink: 0;
  flex-basis: 16px;
  background: url(@/assets/icon-pencil.svg) no-repeat center center;
  background-size: 16px 16px;
  cursor: pointer;
}

th {
  position: sticky;
  z-index: 1;
  /* border-top: 0px;
  border-right: 1px solid rgb(215, 214, 217);
  border-bottom: 1px solid rgb(215, 214, 217); */
  border: 0;
  padding: 5px 10px;
  color: rgb(255, 255, 255);
  -webkit-font-smoothing: antialiased;
  /* background-color: rgb(120, 117, 124); */
  /* background: #fff; */
  cursor: default;
  vertical-align: middle;

  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  color: #2d3757;
  /* text-align: left; */
}

.LightOrange {
  background-color: #f1d7a7;
}

.LightBlue {
  background-color: #c7e9f3;
}

.LightGreen {
  background-color: #c6ffc6 !important;
}

.report-table td div {
  white-space: nowrap;
}

.report-table__head {
  position: sticky;
  z-index: 1;
}

.btn-save {
  display: flex;
  justify-content: end;
}

.btn-save__body {
  background: rgb(106, 141, 255);
  color: #fff;
  font-size: 1.25em;
  padding: 10px 30px;
  margin-right: 20px;
  cursor: pointer;
}

/* .modal {
  position: absolute;
} */

.modal__mask {
  position: fixed;
  z-index: 5;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* display: table; */
  display: flex;
  justify-content: center;
  /* align-items: center; */
  transition: opacity 0.3s ease;
  padding: 20px;
}

.modal__wrapper {
  margin: 40px;
  margin-bottom: 0;
  overflow-y: auto;
  max-height: 100%;
  /* padding: 20px; */
}

.modal__container {
  /* overflow-y: scroll; */
  /* width: 300px; */
  margin: 0px auto;
  padding: 20px 0;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
  max-width: 800px;
}

.modal__header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal__header {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #d3dfed;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 20px;
}

.modal__title {
  padding: 5px 20px;
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  color: #2d3757;
}

.modal__close {
  position: absolute;
  top: 12px;
  right: 30px;
  cursor: pointer;
  width: 16px;
  height: 16px;
  background: url(@/assets/close.svg) no-repeat center center;
}

.modal__body {
  margin: 20px 0;
  padding-left: 30px;
  padding-right: 30px;
}

.modal__default-button {
  float: right;
}

.modal__footer {
  display: flex;
  justify-content: space-between;

  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;

  color: #2d3757;
  padding-left: 30px;
  padding-right: 30px;
}

.modal__close-button {
  border: 1px solid #2d3757;
  border-radius: 2px;
  padding: 14px 55px;
  margin-right: 15px;
  cursor: pointer;
}

.modal__save-button {
  background: #2d3757;
  border-radius: 2px;
  padding: 14px 55px;
  color: #ffffff;
  margin-left: 15px;
  cursor: pointer;
}

.preloader {
  display: flex;
  justify-content: center;
}

.report-body::-webkit-scrollbar {
  width: 5px;
  height: 10px;
  background-color: #f9f9fd;
}

.report-body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #2d375744;
}

.report-body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 20px;
  /* border: 3px solid #000; */
  background-color: #e3e9f1;
}

.item-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 5px;
}

.item-modal__title {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #2d3757;
}

.item-modal__title2 {
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-decoration-line: underline;
  color: #2d3757;
}

.modal-contract-type {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #2d3757;
  text-decoration: none;
}

.item-modal__img {
  margin-left: 10px;
  height: 16px;
  width: 16px;
  flex-grow: 0; /* do not grow   - initial value: 0 */
  flex-shrink: 0;
  flex-basis: 16px;
  background: url(@/assets/share.svg) no-repeat center center;
  background-size: 16px 16px;
}

.report-table__item {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #2d3757;
}

.report-table__item-wrap {
  display: flex;
}

.switch-bar {
  display: flex;
}

.switch-bar__item {
  flex: 1 1 auto;
  text-align: center;
  border-bottom: 1px solid #d3dfed;
  padding: 8px 0;
  cursor: pointer;
}

.switch-bar__item_active {
  border-bottom: 2px solid #2d3757;
}

.switcher-content {
  /* display: flex;
  flex-wrap: wrap; */
}

.switcher-content__choose {
  display: flex;
  flex-wrap: wrap;
}

.switcher-content__item {
  box-sizing: border-box;
  padding: 20px 20px;
  width: 50%;
  min-width: 100px;
}

.switcher-content__item_100 {
  width: 100%;
}

.switcher-content__item_25 {
  width: 25%;
}

.switcher-content__title {
  padding: 8px 0;
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;

  color: #6c6f7d;
}

.switcher-content__data-input {
  border: 1px solid #d3dfed;
  border-radius: 2px;
  padding: 12px;
  width: 100%;
}

.report-table__item-formula_other {
  /* background: rgba(238, 238, 238, 0.5); */
  padding: 5px 5px;
  margin: 0 5px;
  /* cursor: not-allowed; */
}

.report-table__item-text-multiple-choice-wrap {
  /* min-width: 200px; */
  position: relative;
  padding: 5px 15px;
  /* z-index: 99; */
}

.report-table__item-boolean-wrap {
  position: relative;
  padding: 5px 15px;
  /* z-index: 99; */
}

.multiselect {
  /* z-index: 999; */
}

.multiselect-dropdown {
  /* z-index: 1000; */
}

.red {
  background: red;
}

.green {
  background: green;
}

.blue {
  background: blue;
}
</style>

<style>
.Vibrant {
  padding: 5px 15px;
  display: block;
  border-radius: 5px;
  margin: 5px;
  color: #fff;
  text-decoration: none;
}

.OpenAsPopup {
  padding: 5px 15px;
  display: block;
  border-radius: 5px;
  margin: 5px;
  color: #fff;
  text-decoration: none;
}

/* .record__edit-button {
  background: #eee;
  width: 32px;
  height: 32px;
  border-radius: 5px;
  flex-grow: 0; 
  flex-shrink: 0;
  flex-basis: 16px;
  background: url(@/assets/pencil.png) no-repeat center center;
  background-size: 16px 16px;
  cursor: pointer;
} */

.report__title {
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 48px;
  color: #2d3757;
  padding-top: 30px;
  padding-bottom: 20px;
}
</style>
