
const fieldsMap = [
    {
      id: 1,
      title: "General",
      show: false,
      body: [
        {
          title: "General",
          showTitle: false,
          body: [
            [
              {
                key: 6,
                width: 100,
                value: "",
              },
            ],
            [
              {
                key: 22,
                width: 100,
                value: [],
              },
            ],
            [
              {
                key: 29,
                width: 100,
                value: [],
              },
            ],
          ],
        },
      ],
    },
  ];


export default {
    fieldsMap,
}