<template>
  <!-- <input class="switcher-content__data-input" 
    ref="inputRef" 
    type="text" 
    :disabled=disabled 
    :class="{'disabled': disabled}" />

  /> -->
  <input class="switcher-content__data-input" 
    ref="inputRef" 
    type="text" 
    :disabled="disabled" 
    :class="{'disabled': disabled}"
    v-model="formattedValue" />
</template>

<script>
import { watch } from 'vue';
import { useCurrencyInput } from "vue-currency-input";

export default {
  name: "CurrencyInput",
  props: {
    modelValue: { required: true }, // Vue 2: value
    options: Object,
    disabled: Boolean,
  },
  setup(props, { emit }) {
    let { value, formattedValue, inputRef } = useCurrencyInput(props.options);

    // Проксірування змін
    watch(() => props.modelValue, (newValue) => {
      // Оновлення внутрішнього значення
      value = newValue;
    });

    // Відслідковування змін внутрішнього значення
    watch(value, (newValue) => {
      emit('update:modelValue', newValue);
    });

    return { inputRef, formattedValue };
  },
};
</script>

<style scoped>
.switcher-content__data-input {
  border: 1px solid #d3dfed;
  border-radius: 2px;
  padding: 12px;
  width: 100px;
}

.disabled {
  background: #eee;
}
</style>