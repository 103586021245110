
const fieldsMap = [
    {
      id: 1,
      title: "General",
      show: false,
      body: [
        {
          title: "General",
          showTitle: false,
          body: [
            [
              {
                key: 6,
                width: 100,
                value: "",
              },
            ],
            [
              {
                key: 17,
                width: 100,
                value: "",
              },
            ],
            [
              {
                key: 8,
                width: 100,
                value: "",
              },
            ],
            [
              {
                key: 14,
                width: 100,
                value: "",
              },
            ],

            [
              {
                key: 9,
                width: 100,
                value: "",
              },
            ],

            [
              /* {
                key: 11,
                width: 100,
                value: "",
              }, */

              {
                componentType: 'multichoice-rel',
                labelId: 11,
                nameId: 6, // lookupTargetFieldId
                forFieldId: 10, // lookupReferenceFieldId
                tableId: 'bs7zzssmr', // masterTableId
                key: 10,
                width: 25,
                value: '',
              },
            ],
            
            [
              {
                key: 12,
                width: 100,
                value: "",
              },
            ],
          ],
        },
      ],
    },
  ];


export default {
    fieldsMap,
}