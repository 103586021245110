<template>
  <!-- <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav> -->
  <div class="app">
    <div class="app-menu" v-if="showHeader">
      <Header />
    </div>
    <div class="app-body">
      <router-view/>
    </div>
    <Notification :items="notif" />
  </div>
</template>

<script>
import Header from "./components/layout/Header.vue";
import Notification from "@/components/notification/Notification.vue";

export default {
  name: "App",
  components: {
    Header,
    Notification,
  },
  data() {
    return {
      updatePageAfter: 60*5*1000,
      notif: []
    };
  },
  mounted() {
    this.checkUserActive();
  },
  computed: {
    showHeader() {
      let res = true;

      let hide = [
        "Singin"
      ];
      if(hide.includes(this.$route.name)) res = false;
      
      return res;
    },
  },
  methods: {
    checkUserActive() { 
      let app = document.querySelector(
        "#app"
      );

      app.addEventListener('mousemove', this.mouseMonitor);

      setInterval(this.updatePage, 5000);
    },

    async mouseMonitor() {
      this.lastActiveTime = (new Date()).getTime();
    },

    async updatePage() {
      if((this.lastActiveTime + this.updatePageAfter) < (new Date()).getTime()) {
        location.reload();
      }
    },
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
@import url('vue-multiselect/dist/vue-multiselect.css');
* {
  padding: 0;
  margin: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

.app {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  height: 100vh;
}

.app-menu {
  flex: 0 0 auto;
}

.app-body {
  flex: 1 1 auto;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}
</style>


