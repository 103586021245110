<template>
  <div class="preview-confirmation-overlay">
    <div class="preview-confirmation">
      <div class="modal-preview-button" v-show="!isLoading">
        <div class="modal-close-button"
             @click="cancelPreview">
          <span class="cancel-button">Close</span>
          <div class="close-button-img"></div>
        </div>
      </div>
      <div>
        <p v-if="isLoading" class="loading-message">Loading...</p>
        <iframe :src="this.urlPreview"
                frameborder="1"
                @load="iframeLoading"
                v-show="!isLoading"
        ></iframe>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      deleting: false,
      isLoading: true,
    };
  },
  methods: {
    cancelPreview() {
      if (this.onCancel) {
        this.onCancel();
      }
    },
    iframeLoading() {
      this.isLoading = false;
    }
  },
  props: {
    urlPreview: String,
    onCancel: Function,
  },
};
</script>


<style scoped>

.loading-message {
  color: white;
  font-weight: bold;
  font-size: 36px;
}

iframe {
  width: 1580px;
  margin: 10px auto;
  display: block;
  height: 800px;
}

.preview-confirmation-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.preview-confirmation {
  max-width: 2500px;
  text-align: right;
}

.modal-preview-button {
  display: flex;
  justify-content: flex-end;
}

.modal-close-button {
  border: 2px solid #5D88BE;
  border-radius: 2px;
  padding: 14px 28px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  width: 90px;
  height: 20px;
}

.close-button-img {
  width: 16px;
  height: 16px;
  background: url(@/assets/icon-cancel.svg) no-repeat center center;
}

.cancel-button {
  padding-right: 12px;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  border: none;
  border-radius: 2px;
  cursor: pointer;
}

</style>
