<template>
  <div class="table-item" v-bind:class="{ active: selectedReport == report.id }" v-on:click="selectItem">
    <div>
      {{ report.name }}
    </div>
  </div>
</template>

<script>
export default {
  name: "QbReportsItem",
  emits: ["getInfo"],
  props: {
    report: {},
    selectedReport: String,
  },
  methods: {
    selectItem() {
      console.log("trying");
      this.$emit("getInfo", this.report);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.table-item {
  border: 1px solid #000;
  padding: 5px;
  margin: 0 5px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.table-item:hover {
  background: #eee;
  cursor: pointer;
}

.active {
  background: #2d3757;
  color: #fff;
}

.active:hover {
  background: #2d3757;
  color: #fff;
}

h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
