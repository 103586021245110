<template>
  <div class="home">
    <div class="header">
      <img class="header__logo" alt="Logo" src="../assets/logo.png">
    </div>
    <QbTables @getInfo="getInfoTable" :selectedTable="this.table"/>
    <QbReports @getInfo="getInfoReport" :table="this.table" :selectedReport="this.report"/>
    <QbReport :table="table" :report="report"/>
    <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import QbTables from "@/components/tables/QbTables.vue";
import QbReports from "@/components/reports/QbReports.vue";
import QbReport from "@/components/report/QbReport.vue";
// import { reactive } from 'vue'
export default {
  name: 'ReportView',
  components: {
    QbTables,
    QbReports,
    QbReport,
  },
  data() {
    return {
      table: "",
      report: "",
      // {
      //   id: ""
      // }
    }
  },
  methods: {
    getInfoTable(table) {
      console.log("table");
      console.log(table);
      this.table = table.id;
      this.report = "";
      // this.table.id = table.id;
      // this.table.name = table.name;
    },
    getInfoReport(report) {
      console.log("report");
      console.log(report);
      this.report = report.id;
      // this.table.id = table.id;
      // this.table.name = table.name;
    },
  }
}
</script>

<style scoped>
.header {
  height: 50px;
  background: #2d3757;
}

.header__logo {
  max-height: 100%;
  text-align: left;
}
</style>
