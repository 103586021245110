import Api from "../Api";

export default {
    reportById(table, reportId, filter) {
        var headers = {
            method: "POST",
            redirect: "follow",
            headers: { "Content-Type": "application/json", Authorization: "Bearer " + localStorage.token,  },
          };

        let data = JSON.stringify({
            filter: filter,
            token: localStorage.token,
        })
        
        return Api.post("/v1/qb/trading/reports/" + reportId + "/run?table_id=" + table, 
        data, headers);
    },
    updateOffers(offers) {
        var headers = {
            headers: { "Content-Type": "application/json" },
        };

        let data = JSON.stringify({
            token: localStorage.token,
            offers: offers,
        })
        return Api.put("/v1/offers", 
        data, headers);
    },
    addOffers(offers) {
        var headers = {
            headers: { "Content-Type": "application/json" },
        };

        let data = JSON.stringify({
            token: localStorage.token,
            offers: offers,
        })
        return Api.post("/v1/offers", 
        data, headers);
    },

    deleteByRid(rid) {
        var headers = {
            method: "DELETE",
            redirect: "follow",
            headers: { "Content-Type": "application/json" },
          };

        let data = JSON.stringify({
            token: localStorage.token,
        })
        
        return Api.delete("/v1/offers/rid/" + rid, 
        data, headers);
    },

    getOffersByCargoId(id) {
        var headers = {
            headers: { "Content-Type": "application/json" },
        };

        let data = JSON.stringify({
            token: localStorage.token,
        })

        return Api.get("/v1/cargos/" + id + "/offers", 
        data, headers);
    },

    addOfferQuotes(quotes) {
        var headers = {
            headers: { "Content-Type": "application/json" },
        };

        let data = JSON.stringify({
            token: localStorage.token,
            quotes: quotes,
        })
        return Api.post("/v1/offers/quotes", 
        data, headers);
    },
}