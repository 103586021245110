import * as Mutation from './mutation-types'
import Owner from '@/api/tradingPlatform/owner';

export default {
  getReport ({ commit }, {table, reportId, filter}) {
    Owner.reportById(table, reportId, filter).then(response => {
      return commit(Mutation.ADD_REPORT, {body: response.data["body"], reportId: reportId})
    });
  },
  /* createEvent ({ commit }, event) {
    return commit(Mutation.CREATE_EVENT, event)
  },
  updateEvent ({ commit }, updatedEvent) {
    return commit(Mutation.UPDATE_EVENT, updatedEvent)
  },
  deleteEvent ({ commit }, eventId) {
    return commit(Mutation.DELETE_EVENT, eventId)
  },
  setweekendsVisible ({ commit }, enabled) {
    return commit(Mutation.SET_WEEKENDS_ENABLED, enabled)
  },
  setRef ({ commit }, event) {
    return commit(Mutation.CREATE_EVENT, event)
  },
  getCount ({ commit }) {
    return commit(Mutation.COUNT_EVENT)
  }, */
}
