<template>
  <div class="tables-panel">
    <div class="tables-items">
      <QbTableItem v-for="table in tables" :table="table" :selectedTable="selectedTable" :key="table.id" @getInfo="getInfoTable"/>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import QbTableItem from "./components/QbTableItem.vue";

export default {
  name: "QbTables",
  components: {
    QbTableItem,
  },
  emits: ["getInfo"],
  props: {
    selectedTable: String,
  },
  data: function () {
    return {};
  },
  mounted() {
    this.getQBTables();
  },
  computed: {
    ...mapGetters(["tables"]),
  },
  methods: {
    ...mapActions(["createTable"]),
    getInfoTable(table) {
      console.log(table);
      this.$emit("getInfo", table);
    },
    getQBTables() {
      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      fetch(
        process.env.VUE_APP_API_DOMAIN + "/v1/qb/tables?app_id=brd5p5vs9",
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          console.log(result);
          var data = result["body"];
          for (var n in data) {
            // console.log(result[n]);
            var id = data[n]["id"];
            var name = data[n]["name"];
            // var eventDesc = "Test <div>test<div>";
            this.createTable({
              id,
              name,
            });
          }
          // this.clients = result;
        })
        .catch((error) => console.log("error", error));
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.tables-panel {
  max-width: 100%;
  overflow-y: hidden;
  overflow-x: auto;
  margin-top: 30px;
}
.tables-items {
  display: flex;
}

h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
