import Api from "../Api";

export default {
    reportById(table, reportId, filter) {
        var headers = {
            method: "POST",
            redirect: "follow",
            headers: { "Content-Type": "application/json", Authorization: "Bearer " + localStorage.token,  },
          };

        let data = JSON.stringify({
            filter: filter,
            token: localStorage.token,
        })
        
        return Api.post("/v1/qb/trading/reports/" + reportId + "/run?table_id=" + table, 
        data, headers);
    },

    getAll() {
        var headers = {
            method: "GET",
            redirect: "follow",
            headers: { "Content-Type": "application/json" },
          };

        let data = JSON.stringify({
            token: localStorage.token,
        })
        
        return Api.get("/v1/individuals", 
        data, headers);
    },
    addIndividualsToOwnerSizeZones(id, params) {
        console.log("addIndividualsToOwnerSizeZones");
        var headers = {
            method: "POST",
            redirect: "follow",
            headers: { "Content-Type": "application/json" },
          };

        let data = JSON.stringify({
            data: params,
        })
        console.log(data);
        return Api.post(`/v1/owners/${id}/individuals`, 
        data, headers);
    },

    deleteByRid(rid) {
        var headers = {
            method: "DELETE",
            redirect: "follow",
            headers: { "Content-Type": "application/json" },
          };

        let data = JSON.stringify({
            token: localStorage.token,
        })
        
        return Api.delete("/v1/individuals/rid/" + rid, 
        data, headers);
    }
}