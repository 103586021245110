import Api from "../Api";

export default {
    getAll() {
        var headers = {
            method: "GET",
            redirect: "follow",
            headers: { "Content-Type": "application/json" },
          };

        let data = JSON.stringify({
            token: localStorage.token,
        })
        
        return Api.get("/v1/sizes", 
        data, headers);
    }
}