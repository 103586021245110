<template>
  <div class="home">
    <div class="filter">
      <div class="filter__wrap">
        <div class="filter__body">
          <div class="filter__title">
            <div class="filter__img"></div>
            <div class="filter__title-text">Filters</div>
          </div>
          <div class="filter__items">
            <div class="filter__item" v-if="role == 'Admin'">
              <!-- <div class="filter__item-name">Charterer - Name</div> -->
              <Multiselect
                v-model="chartererNameFilterValue"
                :options="chartererNameFilter"
                mode="tags"
                preserve-search="true"
                searchable="true"
                clearOnSelect="false"
                placeholder="Choose Charterer"
                :close-on-select="false"
              >
                <!-- <template v-slot:multiplelabel="{ values }">
                  <div class="multiselect-multiple-label">
                    <div class="test" v-for="item in values" :key="item">
                      {{ item.value }}
                    </div>
                  </div>
                </template> -->
              </Multiselect>
            </div>

            <div class="filter__item">
              <!-- <div class="filter__item-name">Load Ports</div> -->
              <Multiselect
                v-model="loadPortFilterValue"
                :options="loadPortFilter"
                mode="tags"
                preserve-search="true"
                searchable="true"
                clearOnSelect="false"
                placeholder="Choose Load Port"
                :close-on-select="false"
              >
                <!-- <template v-slot:multiplelabel="{ values }">
                  <div class="multiselect-multiple-label">
                    <div class="test" v-for="item in values" :key="item">
                      {{ item.value }}
                    </div>
                  </div>
                </template> -->
              </Multiselect>
            </div>
            <div class="filter__item">
              <!-- <div class="filter__item-name">Status</div> -->
              <Multiselect
                v-model="statusFilterValue"
                :options="statusFilter"
                mode="tags"
                :multiple="true"
                :close-on-select="false"
                :clear-on-select="false"
                preserve-search="true"
                searchable="true"
                clearOnSelect="false"
                placeholder="Choose Status"
                :preselect-first="true"
              >
                <!-- <template v-slot:multiplelabel="{ values }">
                  <div class="multiselect-multiple-label">
                    <div class="test" v-for="item in values" :key="item">
                      {{ item.value }}
                    </div>
                  </div>
                </template> -->
              </Multiselect>
            </div>
          </div>
          <!-- <div class="btn-apply">
            <div class="btn-apply__body" v-on:click="filterApply">
              <custom-loader v-if="filterApplying" />
              <span v-else>Apply</span>
            </div>
          </div> -->
        </div>
        <div class="filter__footer"></div>
      </div>
    </div>

    <!-- <QbTables @getInfo="getInfoTable" :selectedTable="this.table"/> -->
    <!-- <QbReports @getInfo="getInfoReport" :table="this.table" :selectedReport="this.report"/> -->
    <div class="calender">
      <div class="calender__wrap">
        <div class="calender__body">
          <VCalendar
            class="custom-calendar max-w-full"
            :masks="masks"
            :attributes="attributes"
            disable-page-swipe
            is-expanded
          >
            <template v-slot:day-content="{ day, attributes }">
              <div class="flex flex-col h-full z-10 overflow-hidden">
                <span class="day-label text-sm text-gray-900">{{
                  day.day
                }}</span>
                <div class="flex-grow overflow-y-auto overflow-x-auto">
                  <p
                    v-for="attr in attributes"
                    :key="attr.key"
                    class="event text-xs leading-tight rounded-sm p-1 mt-0 mb-1"
                    :class="attr.customData.class"
                  >
                    {{ attr.customData.title }}
                  </p>
                </div>
              </div>
            </template>
          </VCalendar>
          <!-- <DatePicker v-model="date" /> -->
        </div>
      </div>
    </div>
    <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
// import QbTables from "@/components/tables/QbTables.vue";
// import QbReports from "@/components/reports/QbReports.vue";
import Multiselect from "@vueform/multiselect";
import "v-calendar/dist/style.css";

// import { reactive } from 'vue'
export default {
  name: "CalendarView",
  components: {
    Multiselect,
  },
  data() {
    // const month = new Date().getMonth();
    // const year = new Date().getFullYear();
    return {
      chartererNameFilter: [],
      loadPortFilter: [],
      statusFilter: [],
      chartererNameFilterValue: [],
      loadPortFilterValue: [],
      statusFilterValue: [],

      filterApplying: false,

      masks: {
        weekdays: "WWW",
      },
      attributesAll: [
        /* {
          key: 1,
          customData: {
            title: "Lunch with mom.",
            class: "bg-red-600 text-white",
          },
          dates: new Date(year, month, 1),
        }, */
      ],
      attributes: [
        /* {
          key: 1,
          customData: {
            title: "Lunch with mom.",
            class: "bg-red-600 text-white",
          },
          dates: new Date(year, month, 1),
        }, */
      ],
    };
  },
  beforeMount() {
    console.log("beforeMount");
    if (!localStorage.token) {
      this.$router.push("/signin");
      // this.name = localStorage.name;
    }
  },
  mounted() {
    this.getCalendarData();
    this.getFiltersData();
  },
  computed: {
    role() {
      if (localStorage.role) {
        return localStorage.role;
      } else {
        return "";
      }
    },
  },
  watch: {
    chartererNameFilterValue() {
      this.filterApply();
    },
    loadPortFilterValue() {
      this.filterApply();
    },
    statusFilterValue() {
      this.filterApply();
    }
  },
  methods: {
    logout() {
      localStorage.token = "";
      this.$router.push("/signin");
    },

    filterApply() {
      // let res = {};
      // res["373"] = this.chartererNameFilterValue;
      // res["149"] = this.loadPortFilterValue;
      // res["42"] = this.statusFilterValue;
      // this.filterCollect = res;

      if (
        !!this.chartererNameFilterValue.length ||
        !!this.loadPortFilterValue.length ||
        !!this.statusFilterValue.length
      ) {
        this.attributes = [];
        console.log("filter");
        console.log(this.chartererNameFilterValue);
        console.log(this.loadPortFilterValue);
        console.log(this.statusFilterValue);
        for (const key in this.attributesAll) {
          if (Object.hasOwnProperty.call(this.attributesAll, key)) {
            const element = this.attributesAll[key];
            if (
              (!this.chartererNameFilterValue.length ||
                this.chartererNameFilterValue.includes(
                  element.customData.charterer_name
                )) &&
              (!this.loadPortFilterValue.length ||
                this.loadPortFilterValue.includes(
                  element.customData.load_port
                )) &&
              (!this.statusFilterValue.length ||
                this.statusFilterValue.includes(element.customData.status))
            ) {
              this.attributes.push(element);
            }
          }
        }
      } else {
        console.log("filter all");

        this.attributes = [];
        for (const key in this.attributesAll) {
          if (Object.hasOwnProperty.call(this.attributesAll, key)) {
            const element = this.attributesAll[key];
            this.attributes.push(element);
          }
        }
      }
    },

    getCalendarData() {
      var requestOptions = {
        method: "POST",
        redirect: "follow",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.token,
        },
        // body: JSON.stringify(params),
      };

      fetch(process.env.VUE_APP_API_DOMAIN + "/v1/calendar", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          console.log(result);
          // this.clients = result;
          let data = result["body"]["data"];
          for (const key in data) {
            if (Object.hasOwnProperty.call(data, key)) {
              const element = data[key];
              let tmp = {
                id: element["id"],
                customData: {
                  title: element["title"],
                  charterer_name: element["charterer_name"],
                  load_port: element["load_port"],
                  status: element["status"],
                  date_type: element["date_type"],
                  class: "",
                },
                dates: element["date"],
              };
              // if (element["reminder"] == "Reminder")
              //   this.addCustomClassForDay(tmp, element["charterer_name"]);
                this.addCustomClassForDayByDateType(tmp, element["date_type"]);
              this.attributes.push(tmp);
              this.attributesAll.push(tmp);
            }
          }
        })
        .catch((error) => console.log("error", error));
    },

    addCustomClassForDayByDateType(obj, str) {
      switch (str) {
        case "Reminder":
          obj.customData.class = "Reminder-color";
          break;
        case "Flag":
          obj.customData.class = "Flag-color";
          break;
        case "Aging Warning":
          obj.customData.class = "AgingWarning-color";
          break;
        default:
          break;
      }
    },

    addCustomClassForDay(obj, str) {
      switch (str) {
        case "Fisway":
          obj.customData.class = "Fisway-color";
          break;
        case "Zen-noh":
          obj.customData.class = "Zen-noh-color";
          break;
        case "Perdue":
          obj.customData.class = "Perdue-color";
          break;
        case "ECTP":
          obj.customData.class = "ECTP-color";
          break;
        case "Gavilon":
          obj.customData.class = "Gavilon-color";
          break;
        case "S.Louisiana Rail":
          obj.customData.class = "S-Louisiana-Rail-color";
          break;
        case "Cargill":
          obj.customData.class = "Cargill-color";
          break;
        case "Perdue Organics":
          obj.customData.class = "Perdue-Organics-color";
          break;
        default:
          break;
      }
    },

    getFiltersData() {
      var requestOptions = {
        method: "GET",
        redirect: "follow",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.token,
        },
      };

      fetch(process.env.VUE_APP_API_DOMAIN + "/v1/qb/filter", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          console.log(result);
          // this.clients = result;
          this.chartererNameFilter = result["body"]["data"]["373"];
          this.loadPortFilter = result["body"]["data"]["149"];
          this.statusFilter = result["body"]["data"]["42"];
        })
        .catch((error) => console.log("error", error));
    },
  },
};
</script>

<style scoped src="@/styles/views/calendar.css"></style>
<style src="@vueform/multiselect/themes/default.css"></style>


