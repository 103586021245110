import * as Mutation from './mutation-types'
// import { getEventIndexById } from './utils'

let reportIntf = {
  id: 0,
  data: [],
  fields: [],
  allFields: [],
  clist: [],
  relationships: [],
  loading: true,
}

export default {
  [Mutation.GET_HOLIDAYS] (state, holidayRecord) {
    ["data"]
    return state.holidayRecords.push(holidayRecord)
  },
  // [Mutation.SET_HOLIDAYS] (state, holidayRecords) {
  [Mutation.ADD_REPORT] (state, {body, reportId}) {
    let report = state.reports.find(el => el.id == reportId);
    if(!report) {
      report = Object.assign({}, reportIntf)
      report.id = reportId;
      report.data = body["data"];
      report.fields = body["fields"];
      report.allFields = body["allFields"];
      report.clist = body["report"]["query"]["fields"];
      report.relationships = body["relationships"];
      report.loading = false;
      return state.reports.push(report);
    }
    report.id = reportId;
    report.data = body["data"];
    report.fields = body["fields"];
    report.allFields = body["allFields"];
    report.clist = body["report"]["query"]["fields"];
    report.relationships = body["relationships"];
    report.loading = false;
    return state.reports;
  },

  [Mutation.COUNT_HOLIDAYS] (state) {
    return state.holidayRecords.length;
  },
  // [Mutation.SET_REF] (state, event) {
  //   return state.refCalendar = event
  // },
  // [Mutation.UPDATE_EVENT] (state) {
  //   state.events = [];
  //   // const index = getEventIndexById(state, updatedEvent.id)

  //   // if (index === -1) {
  //   //   return console.warn(`Unable to update event (id ${updatedEvent.id})`)
  //   // }
    
  //   // return state.events.splice(index, 1, {
  //   //   ...state.events[index],
  //   //   title: updatedEvent.title,
  //   //   start: updatedEvent.start,
  //   //   end: updatedEvent.end,
  //   //   date: updatedEvent.date
  //   // })
  // },
  // [Mutation.DELETE_EVENT] (state, eventId) {
  //   const index = getEventIndexById(state, eventId)

  //   if (index === -1) {
  //     return console.warn(`Unable to delete event (id ${eventId})`)
  //   }
    
  //   return state.events.splice(index, 1)
  // },
  // [Mutation.SET_WEEKENDS_ENABLED] (state, enabled) {
  //   state.weekendsVisible = enabled
  // },
  // [Mutation.COUNT_EVENT] (state) {
  //   return state.events.length;
  // },
}
