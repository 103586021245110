<template>
  <div class="notif">
    <NotificationItem 
        v-for="(data, index) in notifs"
        :key="index"
        :text="data"
    />
  </div>
</template>

<script>
import NotificationItem from '@/components/notification/NotificationItem.vue'
// import { eventBus } from '@/components/../event-bus.js';

export default {
  name: 'GlobalNotification',
  props: {
      items: {
        type: Array
      }
  },
  data() {
    return {
      notifs: [],
    };
  },
  components: {
    NotificationItem,
  },
  created () {
    this.notifs = this.items;
    this.emitter.on("messageNotificationSent", message => {
      this.notifs.push(message);
      setTimeout(() => {
        var index = this.notifs.indexOf(message);
        if (index > -1) {
          this.notifs.splice(index, 1);
        }
      }, 3000);
    });
    /* eventBus.$on('messageNotificationSent', (message) => {
      this.notifs.push(message);
    }); */
  },
  beforeUnmount() {
    // Відписуємося від подій при знищенні компонента
    // eventBus.off('messageNotificationSent');
  },
  methods: {
    // deleteFile(event) {
    //   this.$emit('deleteFile', this.index);
    // }
  },
}
</script>

<style scoped>
.notif {
    width: 200px;
    position: fixed;
    right: 15px;
    bottom: 15px;
    z-index: 1000;
}
</style>
