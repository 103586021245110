
const fieldsMap = [
    {
      id: 1,
      title: "General",
      show: false,
      body: [
        {
          title: "General",
          showTitle: false,
          body: [
            [
              {
                key: 6,
                width: 100,
                value: "",
              },
            ],

            [
              {
                key: 40,
                width: 100,
                value: "",
              },
            ],

            /* [
              {
                key: 15,
                width: 100,
                value: "",
              },
            ], */
            [
              {
                componentType: "multichoice-rel",
                labelId: 30,
                nameId: 6, // lookupTargetFieldId
                forFieldId: 29, // lookupReferenceFieldId
                tableId: "bs944vg2k", // masterTableId
                key: 29,
                width: 25,
                value: "",
              },
            ],
            [
              {
                key: 12,
                width: 100,
                value: "",
              },
            ],

            [
              /* {
                key: 13,
                width: 100,
                value: "",
              }, */
              {
                componentType: "multichoice-rel",
                labelId: 24,
                nameId: 6, // lookupTargetFieldId
                forFieldId: 23, // lookupReferenceFieldId
                tableId: "br3isji2e", // masterTableId
                key: 23,
                width: 25,
                value: "",
              },
              {
                key: 44,
                width: 100,
                value: "",
              },
              {
                key: 47,
                width: 100,
                value: "",
              },
            ],

            [
              /* {
                key: 14,
                width: 100,
                value: "",
              }, */
              {
                componentType: "multichoice-rel",
                labelId: 26,
                nameId: 6, // lookupTargetFieldId
                forFieldId: 25, // lookupReferenceFieldId
                tableId: "br3isji2e", // masterTableId
                key: 25,
                width: 25,
                value: "",
              },
              {
                key: 45,
                width: 100,
                value: "",
              },
              {
                key: 48,
                width: 100,
                value: "",
              },
            ],
          ],
        },
        {
          title: 'API',
          showTitle: true,
          body: [
            [
              {
                key: 7,
                width: 100,
                value: "",
              },
              {
                key: 41,
                width: 100,
                value: "",
              },
              {
                key: 42,
                width: 100,
                value: "",
              },
            ],
            [
              {
                key: 49,
                width: 100,
                value: "",
              },
              {
                key: 50,
                width: 100,
                value: "",
              },
              {
                key: 51,
                width: 100,
                value: "",
              },
            ],
          ],
        },
      ],
    },
  ];


export default {
    fieldsMap,
}