import * as Mutation from './mutation-types'
// import { getEventIndexById } from './utils'

let reportIntf = {
  id: 0,
  data: [],
  fields: [],
  allFields: [],
  clist: [],
  relationships: [],
  loading: true,
}

export default {
  [Mutation.GET_HOLIDAYS] (state, holidayRecord) {
    ["data"]
    return state.holidayRecords.push(holidayRecord)
  },
  // [Mutation.SET_HOLIDAYS] (state, holidayRecords) {
  [Mutation.ADD_REPORT] (state, {body, reportId}) {
    let report = state.reports.find(el => el.id == reportId);
    if(!report) {
      report = Object.assign({}, reportIntf)
      report.id = reportId;
      report.data = body["data"];
      report.fields = body["fields"];
      report.allFields = body["allFields"];
      report.clist = body["report"]["query"]["fields"];
      report.relationships = body["relationships"];
      report.loading = false;
      return state.reports.push(report);
    }
    report.id = reportId;
    report.data = body["data"];
    report.fields = body["fields"];
    report.allFields = body["allFields"];
    report.clist = body["report"]["query"]["fields"];
    report.relationships = body["relationships"];
    report.loading = false;
    return state.reports;
  },

  [Mutation.COUNT_HOLIDAYS] (state) {
    return state.holidayRecords.length;
  },
}
