<template>
  <div class="modal">
    <div class="modal__mask">
      <div class="modal__wrapper">
        <div v-if="mappingNotEmpty" class="modal__container">
          <div class="modal__header">
            <div class="modal__header-content">
              <div class="modal__title">
                <span class="edit-record-title">Edit Cargo</span>
                <div class="chartere-name" v-if="mappingNotEmpty" :data-rid="this.rid">
                  {{ this.fields[12].value }}
                </div>
              </div>
              <!-- <div class="modal__title" :data-rid="this.rid">{{ modalName }}</div> -->
              <div>
                <!-- <div v-if="mappingNotEmpty">
                  <a target="_blank" :href="this.fields[558].value"
                    >Google Drive</a
                  >
                </div> -->
              </div>
              <div class="modal__header-panel">
                <div
                  class="modal__delete-button"
                  v-on:click="
                    () => this.showDeleteConfirmation()
                  "
                >
                  <!-- <div class="close-button__img"></div> -->
                  <span>Delete</span>
                </div>
                <div
                  class="modal__close-button"
                  v-on:click="
                    () => this.$router.go(-1)
                  "
                >
                  <div class="close-button__img"></div>
                  <span>Cancel</span>
                </div>
                <div class="modal__save-button" v-on:click="() => this.sendToQB()">
                  <CustomLoader v-if="sendingToQB" />
                  <div v-else class="save-button__body">
                    <div class="save-button__img"></div>
                    <span>Save</span>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="modal__close"></div> -->
            <!-- <div
              class="modal__close"
              v-on:click="
                () => {
                  this.$emit('showStatus', false);
                }
              "
            ></div> -->
          </div>

          <div class="modal__body">
            <div class="switch-bar modal__switch-bar">
              <template v-for="(tab, index) in mapping" :key="index">
                <div
                  class="switch-bar__item"
                  v-bind:class="{'switch-bar__item_active': index == selectedSwitcher,}"
                  v-on:click="selectedSwitcher = index"
                  v-if="isShowTab(tab)"
                >
                  {{ tab.title }}
                </div>
              </template>
                <!-- <div class="switch-bar__item switch-bar__item_active">General</div> -->
                <!-- <div class="switch-bar__item">Freight</div>
                  <div class="switch-bar__item">Reminders</div> -->
            </div>
            <div class="cargo-record-top">

              <div class="left-wrap">
                <div class="switcher-content">
                  <div v-if="mappingNotEmpty" class="switcher-content__choose">
                    <div
                      class="switcher-content__sub-tab"
                      v-for="(subTab, subTabIndex) in mapping[selectedSwitcher][
                        'body'
                      ]"
                      :key="subTabIndex"
                    >
                      <div class="switcher-content__sub-tab-body">
                        <table class="switcher-content__sub-tab-table">
                          <tr
                            v-for="(row, rowIndex) in subTab.body"
                            :key="rowIndex"
                          >
                            <template
                              v-for="(value, valueIndex) in row"
                              :key="valueIndex"
                            >

                              <td
                                v-if="showField(value)"
                                :colspan="
                                  valueIndex == row.length - 1
                                    ? value.hasOwnProperty('colspan')
                                      ? value.colspan
                                      : 100
                                    : 1
                                "
                              >
                                <div v-if="this.getTypeForElement(value) == ''">
                                  <div class="field-title">
                                    {{
                                      this.objAllFields[value.key].fieldType !=
                                      "url"
                                        ? this.getLabel(value) //this.objAllFields[value.key].label // + " " + value.key + " | " + this.objAllFields[value.key].fieldType  + " | " + this.objAllFields[value.key].mode
                                        // ? this.getLabel(value) + " " + value.key //this.objAllFields[value.key].label // + " " + value.key + " | " + this.objAllFields[value.key].fieldType  + " | " + this.objAllFields[value.key].mode
                                        : ""
                                    }}
                                  </div>
                                  
                                  <div
                                    v-if="
                                      this.objAllFields[value.key].mode == 'lookup' && !this.editableField(value)
                                    "
                                  >
                                    <div
                                      v-if="
                                        this.objAllFields[value.key].fieldType ==
                                        'currency'
                                      "
                                    >
                                      <QCurrencyInput
                                        v-model="value.value"
                                        :options="{ currency: 'USD', precision: 2 }"
                                      />
                                    </div>
                                    <div
                                      v-else-if="
                                        this.objAllFields[value.key].fieldType ==
                                        'percent'
                                      "
                                    >
                                      <percent-input :disabled="true" v-model="value.value" />
                                    </div>
                                    <div
                                      v-else-if="
                                        this.objAllFields[value.key].fieldType ==
                                        'timestamp'
                                      "
                                      class="switcher-content__data-multiselect"
                                    >
                                      <DatePicker
                                        :format="(t) => formatDateTimeFunc(t)"
                                        :previewFormat="
                                          (t) => formatDateTimeFunc(t)
                                        "
                                        v-model="value.value"
                                        :enableTimePicker="true"
                                        :value-type="valueType.date2value"
                                      />
                                    </div>
                                    <input
                                      v-else
                                      v-model="value.value"
                                      disabled
                                      type="text"
                                      class="switcher-content__data-input disabled"
                                    />
                                  </div>

                                  

                                  <div
                                    v-else-if="
                                      this.objAllFields[value.key].mode == 'formula'
                                    "
                                  >
                                    <div
                                      v-if="
                                        this.objAllFields[value.key].fieldType ==
                                        'url'
                                      "
                                    >
                                      <a :href="value.value" class="url-btn">{{
                                        this.objAllFields[value.key].properties
                                          .appearsAs
                                      }}</a>
                                    </div>

                                    <div
                                    :data-row="index"
                                    :data-fid="item"
                                    v-else-if="
                                      this.objAllFields[value.key].fieldType ==
                                      'rich-text'
                                    "
                                    @click="() => {
                                      this.copyText(this.removeHtmlTags(value.value));
                                    }" style="cursor: pointer;"
                                    v-html="value.value"
                                  ></div>

                                    <textarea
                                      v-else-if="
                                        this.objAllFields[value.key].fieldType ==
                                        'text'
                                      "
                                      style="display: block"
                                      v-model="value.value"
                                      class="
                                        switcher-content__text-data-textarea
                                        disabled
                                      "
                                      disabled
                                    >
                                    </textarea>
                                    <div
                                      v-else-if="
                                        this.objAllFields[value.key].fieldType ==
                                        'timestamp'
                                      "
                                      class="switcher-content__data-multiselect"
                                    >
                                      <DatePicker
                                        :format="(t) => formatDateTimeFunc(t)"
                                        :previewFormat="
                                          (t) => formatDateTimeFunc(t)
                                        "
                                        v-model="value.value"
                                        :enableTimePicker="true"
                                        :value-type="valueType.date2value"
                                        :disabled="true"
                                      />
                                    </div>
                                    <div
                                      v-else-if="
                                        this.objAllFields[value.key].fieldType ==
                                        'currency'
                                      "
                                    >
                                      <QCurrencyInput
                                        v-model="value.value"
                                        :options="{ currency: 'USD', precision: 2 }"
                                        :disabled="true"
                                      />
                                    </div>
                                    <input
                                      v-else
                                      v-model="value.value"
                                      disabled
                                      type="text"
                                      class="switcher-content__data-input disabled"
                                    />
                                  </div>

                                  <div
                                    v-else-if="
                                      this.objAllFields[value.key].mode == 'summary'
                                    "
                                  >
                                    <div
                                      v-if="
                                        this.objAllFields[value.key].fieldType ==
                                        'currency'
                                      "
                                    >
                                      <QCurrencyInput
                                        v-model="value.value"
                                        :options="{ currency: 'USD', precision: 2 }"
                                        :disabled="true"
                                      />
                                    </div>
                                    <div
                                      v-else-if="
                                        this.objAllFields[value.key].fieldType ==
                                        'timestamp'
                                      "
                                      class="switcher-content__data-multiselect"
                                    >
                                      <DatePicker
                                        :format="(t) => formatDateTimeFunc(t)"
                                        :previewFormat="
                                          (t) => formatDateTimeFunc(t)
                                        "
                                        v-model="value.value"
                                        :value-type="valueType.date2value"
                                        :disabled="true"
                                        :hideInputIcon="true"
                                        inputClassName="dp-custom-input"
                                      />
                                    </div>
                                    <input
                                      v-else
                                      v-model="value.value"
                                      disabled
                                      type="text"
                                      class="switcher-content__data-input disabled"
                                    />
                                  </div>
                                  <div
                                    v-else-if="
                                      this.objAllFields[value.key].fieldType ==
                                      'checkbox'
                                    "
                                  >
                                    <input
                                      v-model="value.value"
                                      type="checkbox"
                                      class="switcher-content__data-input checkbox"
                                    />
                                  </div>

                                  <div
                                    v-else-if="
                                      (this.objAllFields[value.key].fieldType ==
                                      'rich-text') && (value.key == 71 || value.key == 15)
                                    "
                                  >
                                    <textarea
                                      v-model="value.value"
                                      name=""
                                      id=""
                                      cols="30"
                                      rows="1"
                                      class="switcher-content__text-data-textarea textarea-notes"
                                    ></textarea>
                                  </div>

                                  <div
                                    v-else-if="
                                      this.objAllFields[value.key].fieldType ==
                                      'rich-text'
                                    "
                                  >
                                    <textarea
                                      v-model="value.value"
                                      name=""
                                      id=""
                                      cols="30"
                                      rows="1"
                                      class="switcher-content__text-data-textarea"
                                    ></textarea>
                                  </div>

                                  <!-- <div
                                    style="height: 40px"
                                    :data-row="index"
                                    :data-fid="item"
                                    v-else-if="
                                      this.objAllFields[value.key].fieldType ==
                                      'rich-text'
                                    "
                                    v-html="value.value"
                                  ></div> -->

                                  <div
                                    v-else-if="
                                      this.objAllFields[value.key].fieldType ==
                                      'percent'
                                    "
                                  >
                                    <percent-input v-model="value.value" />
                                  </div>

                                  <div
                                    v-else-if="
                                      this.objAllFields[value.key].fieldType ==
                                      'text-multi-line'
                                    "
                                  >
                                    <textarea
                                      v-model="value.value"
                                      name=""
                                      id=""
                                      cols="30"
                                      rows="1"
                                      class="switcher-content__text-data-textarea"
                                    ></textarea>
                                  </div>
                                  <!--  -->
                                  <div
                                    v-else-if="
                                      this.objAllFields[value.key].fieldType ==
                                      'date' && (value.key == 67 || value.key == 68)
                                    "
                                    class="switcher-content__data-multiselect multiselect-layday-сancelling"
                                  >
                                    <DatePicker
                                      :format="
                                        (t, a) => formatDateFunc(t, value.value)
                                      "
                                      v-model="value.value"
                                      :enableTimePicker="false"
                                    />
                                  </div>
                                  <!--  -->
                                  <div
                                    v-else-if="
                                      this.objAllFields[value.key].fieldType ==
                                      'date'
                                    "
                                    class="switcher-content__data-multiselect"
                                  >
                                    <DatePicker
                                      :format="
                                        (t, a) => formatDateFunc(t, value.value)
                                      "
                                      v-model="value.value"
                                      :enableTimePicker="false"
                                    />
                                  </div>
                                  <div
                                    v-else-if="
                                      this.objAllFields[value.key].fieldType ==
                                      'timestamp'
                                    "
                                    class="switcher-content__data-multiselect"
                                  >
                                    <DatePicker
                                      :format="(t) => formatDateTimeFunc(t)"
                                      :previewFormat="(t) => formatDateTimeFunc(t)"
                                      v-model="value.value"
                                      :enableTimePicker="true"
                                      :value-type="valueType.date2value"
                                    />
                                  </div>

                                  <div
                                    class="
                                      report-table__item-text-multiple-choice-wrap
                                    "
                                    v-else-if="
                                      this.objAllFields[value.key].fieldType ==
                                      'text-multiple-choice'
                                    "
                                  >
                                  <template
                                      v-if="
                                        getTextMultipleChoiceType(
                                          this.objAllFields[value.key]
                                        ) == 'list' && value.key == 8 
                                      "
                                    >
                                      <div class="switcher-content__data-multiselect multiselect-status">
                                        <Multiselect
                                          v-model="value.value"
                                          :options="
                                            this.objAllFields[value.key][
                                              'properties'
                                            ]['choices'].sort()
                                          "
                                        />
                                      </div>
                                    </template>
                                    <template
                                      v-else-if="
                                        getTextMultipleChoiceType(
                                          this.objAllFields[value.key]
                                        ) == 'list'
                                      "
                                    >
                                      <div class="switcher-content__data-multiselect">
                                        <Multiselect
                                          v-model="value.value"
                                          :options="
                                            this.objAllFields[value.key][
                                              'properties'
                                            ]['choices'].sort()
                                          "
                                        />
                                      </div>
                                    </template>
                                    <template
                                      v-if="
                                        getTextMultipleChoiceType(
                                          this.objAllFields[value.key]
                                        ) == 'table'
                                      "
                                    >
                                      <div class="switcher-content__data-multiselect">
                                        <Multiselect
                                          v-model="value.value"
                                          :options="
                                            getDataForDropDownField(
                                              this.objAllFields[value.key][
                                                'properties'
                                              ]['masterChoiceTableId'],
                                              this.objAllFields[value.key][
                                                'properties'
                                              ]['masterChoiceFieldId']
                                            ).sort()
                                          "
                                        />
                                      </div>
                                    </template>
                                    <!-- <template v-else>
                                      <div>{{ value.value }}222222</div>
                                    </template> -->
                                    <!-- <Multiselect 
                                  v-model="value.value"
                                  :options="
                                    this.objAllFields[value.key].properties.choices
                                  "
                                  clearOnSelect="false"
                                  placeholder="Choose"
                                  :close-on-select="false"
                                ></Multiselect> -->
                                  </div>

                                  <div
                                    v-else-if="
                                      this.objAllFields[value.key].fieldType ==
                                      'currency'
                                    "
                                  >
                                    <QCurrencyInput
                                      v-model="value.value"
                                      :options="{ currency: 'USD', precision: 2 }"
                                      :disabled="false"
                                    />
                                  </div>

                                  <div v-else-if="value.key == 10">
                                    <input
                                      v-model="value.value"
                                      disabled
                                      type="text"
                                      class="switcher-content__data-input"
                                    />
                                  </div>
                                  <div v-else>
                                    <input
                                      v-model="value.value"
                                      type="text"
                                      class="switcher-content__data-input"
                                    />
                                  </div>
                                </div>
                                <div
                                  v-else-if="
                                    this.getTypeForElement(value) ==
                                    'documentFilter'
                                  " style="display:flex"
                                  class="switcher-content__data-multiselect-document-filter"
                                >
                                  <Multiselect
                                      key="documentFilter"
                                      v-model="documentReportAllModel"
                                      :options="documentReportAllModelOptions"
                                      :searchable="true"
                                      :multiple="true"
                                      mode="tags"
                                      label="name"
                                      trackBy="rid"
                                      track-by="rid"
                                      valueProp="key"
                                    ></Multiselect>

                                    <div class="btn-apply">
                                      <div class="btn-apply__body" v-on:click="filterApply">
                                        <custom-loader v-if="filterApplying" />
                                        <span v-else>Apply</span>
                                      </div>
                                    </div>
                                </div>
                                
                                
                                
                                <div
                                  v-else-if="
                                    this.getTypeForElement(value) ==
                                    'text'
                                  " style="width: 250px"
                                >
                                  <span>{{value.value}}</span>
                                </div>
                                <div
                                  v-else-if="
                                    this.getTypeForElement(value) ==
                                    'multichoice-rel'
                                  "
                                >
                                  <div class="switcher-content__title">
                                    {{
                                      this.objAllFields[value.labelId].fieldType !=
                                      "url"
                                        ? this.objAllFields[value.labelId].label // + " " + value.key + " | " + this.objAllFields[value.key].fieldType  + " | " + this.objAllFields[value.key].mode
                                        : ""
                                    }}
                                  </div>

                                  <div v-if="value.labelId == 64" class="switcher-content__data-multiselect multiselect-voyage-desc">
                                    <Multiselect
                                      v-model="value.value"
                                      :options="sortOptionsById(tablesOprions[value.tableId], value.nameId)"
                                      :label="value.nameId"
                                      :track-by="value.nameId"
                                      :searchable="true"
                                    />
                                  </div>

                                  <div v-else-if="value.labelId == 12" class="switcher-content__data-multiselect multiselect-charterer-name">
                                    <Multiselect
                                      v-model="value.value"
                                      :options="sortOptionsById(tablesOprions[value.tableId], value.nameId)"
                                      :label="value.nameId"
                                      :track-by="value.nameId"
                                      :searchable="true"
                                    />
                                  </div>

                                  <div v-else class="switcher-content__data-multiselect">
                                    <Multiselect
                                      v-model="value.value"
                                      :options="sortOptionsById(tablesOprions[value.tableId], value.nameId)"
                                      :label="value.nameId"
                                      :track-by="value.nameId"
                                      :searchable="true"
                                    />
                                  </div>
                                  <!-- :searchStart="true" -->

                                  <!-- </Multiselect> -->

                                  <!-- <multiselect v-model="value" :options="options" :custom-label="nameWithLang" placeholder="Select one" label="name" track-by="name"></multiselect> -->
                                </div>
                              </td>
                            </template>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <!-- <div class="switcher-content__title">{{ value }}</div> -->
                    <!-- <div class="switcher-content__title">
                        {{ this.objAllFields[value.key].label }}
                      </div> -->
                  </div>
                </div>
              </div>

              <div class="right-wrap">
                <div class="summary-table-wrap" v-if="offers.length != 0" @click="copyHtmlToClipboard">
                  <span class="right-wrap-report-title">SUMMARY</span>
                  <div class="report-body">
                    <div class="tables report-table_summary">
                      <table class="report-table" style="border-collapse: collapse; padding-bottom: 20px;">
                        <thead>
                          <tr class="report-table__head" style="background: #f6faff;">
                            <th class="name-column" style="border: 1px solid #d3dfed;">Offers</th>
                            <th class="offer-column" style="border: 1px solid #d3dfed;"></th>
                            <th class="notes-column" style="border: 1px solid #d3dfed;"></th>
                            <th class="date-column" style="border: 1px solid #d3dfed;"></th>
                          </tr>
                        </thead>
                        <tbody v-if="summaryOffers.length != 0">
                          <template v-for="(offer, index) in summaryOffers" :key="index">
                            <tr>
                              <td style="border: 1px solid #d3dfed; padding: 5px; min-width: 50px;">{{ offer.name }}</td>
                              <td class="td-offer" v-if="offer.offer" style="border: 1px solid #d3dfed; padding: 5px; min-width: 50px; text-align: center;">${{ offer.offer }}</td>
                              <td class="td-offer" style="border: 1px solid #d3dfed; padding: 5px; min-width: 50px; text-align: center;" v-else></td>
                              <td style="border: 1px solid #d3dfed; padding: 5px; min-width: 50px;">{{ offer.notes }}</td>
                              <td class="td-date-update" style="border: 1px solid #d3dfed; padding: 5px; min-width: 50px; text-align: center;">{{ formatDateForDateUpdate(offer.date_updated) }}</td>
                            </tr>
                          </template>
                        </tbody>
                        <tbody v-else>
                          <tr class="no-record">
                            <td>No records found.</td>
                          </tr>
                        </tbody>
                      </table>
                      <br>
                      <table class="report-table" v-if="summaryComments.length != 0" style="border-collapse: collapse; padding-bottom: 20px;">
                        <thead>
                          <tr class="report-table__head" style="background: #f6faff;">
                            <th class="name-column" style="border: 1px solid #d3dfed;">Comments</th>
                            <!-- <th class="offer-column" style="border: 1px solid #d3dfed;"></th> -->
                            <th class="notes-column" style="border: 1px solid #d3dfed;"></th>
                            <!-- <th class="date-column" style="border: 1px solid #d3dfed;"></th> -->
                          </tr>
                        </thead>
                        <tbody>
                          <template v-for="(offer, index) in summaryComments" :key="index">
                            <tr>
                              <td style="border: 1px solid #d3dfed; padding: 5px; min-width: 50px;">{{ offer.name }}</td>
                              <!-- <td class="td-offer" v-if="offer.offer" style="border: 1px solid #d3dfed; padding: 5px; min-width: 50px;">${{ offer.offer }}</td> -->
                              <!-- <td class="td-offer" v-else style="border: 1px solid #d3dfed; padding: 5px; min-width: 50px;"></td> -->
                              <td style="border: 1px solid #d3dfed; padding: 5px; min-width: 50px;">{{ offer.notes }}</td>
                              <!-- <td class="td-date-update" style="border: 1px solid #d3dfed; padding: 5px; min-width: 50px;">{{ formatDateForDateUpdate(offer.date_updated) }}</td> -->
                            </tr>
                          </template>
                        </tbody>
                      </table>
                      <br v-if="summaryComments.length != 0">
                      <table class="report-table" style="border-collapse: collapse; padding-bottom: 20px;">
                        <thead>
                          <tr class="report-table__head" style="background: #f6faff;">
                            <th class="name-column" style="border: 1px solid #d3dfed; min-width: 50px;">Also quoted</th>
                            <!-- <th class="offer-column" style="border: 1px solid #d3dfed; min-width: 50px;"></th> -->
                            <!-- <th class="notes-column" style="border: 1px solid #d3dfed; min-width: 50px;"></th> -->
                            <!-- <th class="date-column" style="border: 1px solid #d3dfed; min-width: 50px;"></th> -->
                          </tr>
                        </thead>
                        <tbody v-if="summaryAlsoQuoted.length != 0">
                          <template v-for="(offer, index) in summaryAlsoQuoted" :key="index">
                            <tr>
                              <td style="border: 1px solid #d3dfed; padding: 5px; min-width: 50px;">{{ offer.name }}</td>
                              <!-- <td class="td-offer" v-if="offer.offer" style="border: 1px solid #d3dfed; padding: 5px; min-width: 50px;">${{ offer.offer }}</td> -->
                              <!-- <td class="td-offer" v-else style="border: 1px solid #d3dfed; padding: 5px; min-width: 50px;"></td> -->
                              <!-- <td style="border: 1px solid #d3dfed; padding: 5px; min-width: 50px;">{{ offer.notes }}</td> -->
                              <!-- <td class="td-date-update" style="border: 1px solid #d3dfed; padding: 5px; min-width: 50px;">{{ formatDateForDateUpdate(offer.date_updated) }}</td> -->
                            </tr>
                          </template>
                        </tbody>
                        <tbody v-else>
                          <tr class="no-record">
                            <td style="border: 1px solid #d3dfed; padding: 5px;">No records found.</td>
                            <!-- <td colspan="3" style="border: 1px solid #d3dfed; padding: 5px;"></td> -->
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

            <!--<div class="history-table-wrap">
                  <span class="right-wrap-report-title">HISTORY</span>
                  <div class="report-body report-body-history">
                    <div class="tables report-table_history">
                      <table class="report-table">
                        <thead>
                          <tr class="report-table__head">
                            <th class="name-column">Freight</th>
                            <th class="offer-column">Date Updated</th>
                            <th class="notes-column">Curve Shape</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Test1</td>
                            <td>Test2</td>
                            <td>Test3</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div> -->


              </div>

            </div>
            
            <!------------ rewrite it -------------> 
            <div class="cargo-record-bottom">

              <div
                class="switcher-content__sub-tab"
                v-for="(subTab, subTabIndex) in mapping[selectedSwitcher][
                  'body'
                ]"
                :key="subTabIndex"
              >

                <div
                  class="switcher-content__sub-tab-title"
                  v-if="subTab.showTitle"
                >
                  {{ subTab.title }}
                </div>
              
                <div class="sub-tab-body"
                  v-for="(row, rowIndex) in subTab.body"
                  :key="rowIndex"
                >
                  <div
                    v-for="(value, valueIndex) in row"
                    :key="valueIndex"
                  >
                    <div class="document-report-wrap" v-if="this.getTypeForElement(value) == 'OwnersForCargoField'">
                      <OwnersForCargoField
                        ref="ownersField1Ref"
                        :rid="this.rid"
                      ></OwnersForCargoField>
                    </div>
                  </div>
                
                </div>

              </div>

            </div>
            <!-- ---------------------------------- --> 
            
            <!-- <table class="report-table">
                <tbody>
                  <tr v-for="(value, index) in parentReportData" :key="index">
                    <td>
                      <b>{{ this.objParentFields[index].label }}</b>
                    </td>
                    <td>
                      <textarea :value="value.value"></textarea>
                    </td>
                  </tr>
                </tbody>
              </table> -->
          </div>

          <div class="modal__footer">
            
          </div>
        </div>
        <div v-else  class="modal__container-loader">
          <CustomLoader/>
        </div>
      </div>
    </div>

    <DeleteConfirmation
      :on-confirm="confirmDelete"
      :on-cancel="cancelDelete"
      :is-report="false"
      v-if="showConfirmation"
    />
    <UpdateRecordPopUp
      v-if="showUpdateRecordPopUp"
      :open="showUpdateRecordPopUp"
      :updatedData="this.updatedData"
      :recordName="this.recordName"
    />
  </div>
</template>

<script>
import Cargo from "@/api/tradingPlatform/cargo";
import Multiselect from "@vueform/multiselect";
import moment from "moment";
import he from "he";
import CustomLoader from "@/components/loader/CustomLoader.vue";
import OwnersForCargoField from "@/components/fields/OwnersForCargo.vue";
// import { reactive } from "@vue/reactivity";
import { reactive } from "vue";
import QCurrencyInput from "@/components/QCurrencyInput.vue";
import PercentInput from "@/components/PercentInput.vue";

import DeleteConfirmation from "@/components/pop-up/DeleteConfirmation.vue";
import UpdateRecordPopUp from "@/components/pop-up/UpdateRecordPopUp.vue";

import { mapState } from "vuex";

import CargoFields  from "@/model/oportunities/CargoFields";
import CargoApi from "@/api/tradingPlatform/cargo";

export default {
  name: "CargoRecord",
  components: {
    Multiselect,
    CustomLoader,
    QCurrencyInput,
    PercentInput,
    OwnersForCargoField,
    DeleteConfirmation,
    UpdateRecordPopUp,
  },
  props: {
    // table: String, // delete
    // selectedReport: String, // delete
    open: Boolean, // delete
    rid: Number,
  },
  emits: ["showStatus", "updateRecord"],
  setup() {
    let dataForDropDownFields = reactive({
      bs7zzssuu: {},
      bs7zzssxt: {},
    });
    return {
      dataForDropDownFields,
    };
  },
  data: function () {
    return {
      offers: [],
      documentReportAllModel: [],
      documentReportAllModelOptions: [],

      filterApplying: false,

      clistForTables: {
        bs7zzssuu: [3, 6],
        bs7zzssxt: [3, 6, 7],
      },
      tablesOprions: {
        bs7zzssuu: [],
        bs7zzssxt: [],
      },
      reports: [],
      modalName: "Lifting Summary", // +  # " + this.rid,
      allFields: [],
      fields: [],
      masterTableId: "bs7zzssh8",

      sendingToQB: false,

      selectedSwitcher: 0,

      showModalAddDocumentTypeCnf: false,
      showModalAddDocumentTypeFob: false,
      showUpdateRecordPopUp: false,

      updatedData: false,

      recordName: "Cargo",

      type: "",

      mapping: [],

      shipPaths: [],

      showConfirmation: false,
    };
  },
  mounted() {
    this.getDataTaskFields();
    this.getData();
    this.getRelData();
  },
  updated() {

    let qbUpdateCompleteCurrentTaskButton = this.$el.querySelectorAll(
      ".qb-update-complete-current-task-button .Vibrant"
    );
    qbUpdateCompleteCurrentTaskButton.forEach((el) => {
      el.onclick = () => {
        console.log("test qb-update-complete-current-task-button");
        console.log(this);
        this.updateCompleteCurrentTask(this.rid);
        return false;
      };
    });

    let allContracts = document.querySelectorAll(".rich-text__484 a");
    allContracts.forEach((el) => {
      el.onclick = () => {
        return false;
      };
    });

    var textarea = document.querySelectorAll(
      ".switcher-content__text-data-textarea"
    );
    textarea.forEach((el) => {
      console.log("WWWWWWWW");
      console.dir(el);
      console.log(el.value);
      console.log(el.scrollTop);
      console.log(el.scrollHeight);
      console.log(el.scrollWidth);

      el.style.height = 0;

      el.dataset.scrollTop = el.scrollTop;
      el.dataset.scrollHeight = el.scrollHeight;
      el.dataset.scrollWidth = el.scrollWidth;

      if (!el.dataset.newHeight) {
        el.dataset.newHeight = el.scrollHeight + 5 + "px";
      }
      if (!el.dataset.newWidth) {
        el.dataset.newWidth = el.scrollWidth + 20 + "px";
      }
      // if(el.scrollTop > 0){
      // el.style.height = el.scrollHeight + "px";
      // el.style.width = el.scrollWidth + "px";
      el.style.height = el.dataset.newHeight;
      el.style.width = el.dataset.newWidth;
      // }
    });

    /* textarea.addEventListener('keyup', function(){
      if(this.scrollTop > 0){
        this.style.height = this.scrollHeight + "px";
      }
    }); */
  },
  computed: {
    ...mapState({
       allHolidayFields: state => state.holidays.holidayRecords,
       holidayClist: state => state.holidays.holidayClist,
       holidayReportData: state => state.holidays.holidayReportData,
    }),

    summaryOffers() {
      return this.offers.filter((item) => item.offer).sort((a, b) => Number(a.offer) - Number(b.offer)); 
    },

    summaryComments() {
        return this.offers.filter((item) => (!item.offer) && (item.notes))
    },

    summaryAlsoQuoted() {
        return this.offers.filter((item) => (!item.offer) && (!item.notes))
    },

    getCountryDischargeFilter() {
      return [this.fields[968].value];
    },
    getCountryLoadFilter() {
      return [this.fields[969].value];
    },
    getStateDischargeFilter() {
      return [this.fields[974].value, ""];
    },
    getStateLoadFilter() {
      return [this.fields[973].value, ""];
    },

    /* :allFields=this.allHolidayFields
    :clist=this.holidayClist
    :reportData=this.holidayReportData */

    mappingNotEmpty() {
      if (this.allFields.length == 0) return false;
      return true;
    },
    objAllFields() {
      let res = {};
      // this.fields.forEach((val) => {
      //   res[val["id"]] = val;
      // });
      this.allFields.forEach((val) => {
        res[val["id"]] = val;
      });
      return res;
    },
    valueType() {
      return {
        value2date: (value) => {
          // transform the binding value to calendar Date Object
          console.log("value2date", value);

          return moment(value).format("LL");
        },
        date2value: (date) => {
          // transform the calendar Date Object to binding value
          console.log("date2value", date);

          return moment(date).format("LL");
        },
      };
    },
  },
  watch: {
    fields() {
      for (const key in this.mapping) {
        if (Object.hasOwnProperty.call(this.mapping, key)) {
          console.log(this.mapping[key]);
          if (this.isShowTab(this.mapping[key])) {
            this.selectedSwitcher = key;
            break;
          }
        }
      }
    },
  },
  methods: {
    showDeleteConfirmation() {
      this.showConfirmation = true;
    },

    async confirmDelete() {
      await CargoApi.deleteByRid(this.rid);
      
      this.$router.go(-1);
      this.showConfirmation = false; 
    },

    cancelDelete() {
      this.showConfirmation = false;
    },

    cp1251ToUtf8(cp1251Text) {
      const cp1251decoder = new TextDecoder('windows-1251');
      const utf8encoder = new TextEncoder();

      const cp1251Data = cp1251decoder.decode(new Uint8Array(Buffer.from(cp1251Text, 'binary')));
      const utf8Data = utf8encoder.encode(cp1251Data);

      return utf8Data;
    },
    replaceNewlinesWithBr(input) {
      return input.replace(/\n/g, '<br>');
    },
    removeHtmlTags(input) {
      // return input.replace(/<[^>]*>/g, '');
      // return input.replace(/<br\s*\/?>/g, '\n');
      input = he.decode(input);
      input = input.replace(/<br>/g, '\n');
      return input.replace(/<[^>]*>/g, '');

    },
    copyText(text) {
        console.log("test2");
        // Створюємо невидимий textarea для копіювання тексту
        const textarea = document.createElement("textarea");
        textarea.style.position = "fixed";
        textarea.style.top = 0;
        textarea.style.left = 0;
        textarea.style.width = "2em";
        textarea.style.height = "2em";
        textarea.style.padding = 0;
        textarea.style.border = "none";
        textarea.style.outline = "none";
        textarea.style.boxShadow = "none";
        textarea.style.background = "transparent";
        textarea.value = text;
        document.body.appendChild(textarea);

        textarea.select();
        document.execCommand("copy");
        document.body.removeChild(textarea);
        this.emitter.emit("messageNotificationSent", "Copied!");
        // this.isCopied = true;
        // setTimeout(() => {
        //   this.isCopied = false;
        // }, 2000);
    },

    sortLogs(logs) {
      let newLogs = [];
      for (const key in logs) {
        let element = logs[key];
        let d = element.split("|");
        newLogs.push(
          {
            title: d[0],
            id: d[1],
          }
        );
      }

      // newLogs = newLogs.sort((a,b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0))
      newLogs = newLogs.sort((a,b) => b.id - a.id)
      return newLogs.map(a => a.title);
    },

    getCountryFilter(value) {
      return value.type == 'load' ? this.getCountryLoadFilter : this.getCountryDischargeFilter;
    },
    getStateFilter(value) {
      return value.type == 'load' ? this.getStateLoadFilter : this.getStateDischargeFilter;
    },
    sortOptionsById(options, id) {
      console.log("options====");
      console.log(options);
      console.log(id);

      for (const key in options) {
        if (Object.hasOwnProperty.call(options, key)) {
          const element = options[key][id];
          options[key][id] = this.removeHtmlTags(he.decode(element));
        }
      }
      
      const sorter = (a, b) => {
        const nameA = a[id].toUpperCase(); // ignore upper and lowercase
        const nameB = b[id].toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      };
      return options.sort(sorter);
    },
    filterApply() {
      if(this.documentReportAllModel.length > 0) {
        this.filterApplying = true;
        var requestOptions = {
          method: "POST",
          redirect: "follow",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            documents: this.documentReportAllModel,
            token: localStorage.token,
          }),
        };

        fetch(
          process.env.VUE_APP_API_DOMAIN + "/v1/qb/lifting/" + this.rid + "/documents/bind",
          requestOptions
        )
          .then((response) => response.json())
          .then((result) => {
            console.log(result);
            this.documentReportAllModel = [];
            this.filterApplying = false;
          })
          .catch((error) => {
            console.log("error", error);
            this.documentReportAllModel = [];
            this.filterApplying = false;
          });
        
      }
    },
    setDocumentReportAllModelOptions(oprions) {
      this.documentReportAllModelOptions = [];
      oprions.forEach(e => {
        this.documentReportAllModelOptions.push(e);
      });
    },
    getLabel(value) {
      if (value.hasOwnProperty.call(value, "label")) {
        return value.label;
      } else {
        return this.objAllFields[value.key].label;
      }
    },
    format42(value) {
      return value.slice(2);
    },
    print(value) {
      console.log("Pring+++");
      console.log(value);
      return value;
    },
    recalculateHeightAndWidthForTextArea() {
      var textarea = document.querySelectorAll(
        ".switcher-content__text-data-textarea"
      );
      textarea.forEach((el) => {
        console.log("WWWWWWWW");
        console.dir(el);
        console.log(el.value);
        console.log(el.scrollTop);
        console.log(el.scrollHeight);
        console.log(el.scrollWidth);
        el.style.height = 0;

        el.dataset.scrollTop = el.scrollTop;
        el.dataset.scrollHeight = el.scrollHeight;
        el.dataset.scrollWidth = el.scrollWidth;

        el.dataset.newHeight = el.scrollHeight + "px";
        el.dataset.newWidth = el.scrollWidth + "px";

        /* if (!el.dataset.newHeight) {
        el.dataset.newHeight = el.scrollHeight + "px";
      }
      if (!el.dataset.newWidth) {
        el.dataset.newWidth = el.scrollWidth + 10 + "px";
      } */
        // if(el.scrollTop > 0){
        // el.style.height = el.scrollHeight + "px";
        // el.style.width = el.scrollWidth + "px";
        el.style.height = el.dataset.newHeight;
        el.style.width = el.dataset.newWidth;
        // }
      });
    },
    async getDataForDropDownFields() {
      let info = {};
      for (const key in this.objAllFields) {
        if (Object.hasOwnProperty.call(this.objAllFields, key)) {
          const element = this.objAllFields[key];
          if (
            element["fieldType"] == "text-multiple-choice" &&
            Object.hasOwnProperty.call(
              element["properties"],
              "masterChoiceTableId"
            )
          ) {
            let masterChoiceTableId =
              element["properties"]["masterChoiceTableId"];
            let masterChoiceFieldId =
              element["properties"]["masterChoiceFieldId"];

            if (Object.hasOwnProperty.call(info, masterChoiceTableId)) {
              if (!info[masterChoiceTableId].includes(masterChoiceFieldId)) {
                info[masterChoiceTableId].push(masterChoiceFieldId);
              }
            } else {
              info[masterChoiceTableId] = [masterChoiceFieldId];
            }
            // info.push({fid: this.objAllFields["properties"]["masterChoiceFieldId"], table: this.objAllFields["properties"]["masterChoiceTableId"]});
          }
        }
      }
      for (const key in info) {
        if (Object.hasOwnProperty.call(info, key)) {
          const element = info[key];
          this.getDataForDropDownFieldsFromQB(key, element);
        }
      }
    },
    async getDataForDropDownFieldsFromQB(table, clist) {
      // this.$set(this.dataForDropDownFields, table, {})
      // var vm = this;
      // this.$set();
      var requestOptions = {
        method: "POST",
        redirect: "follow",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          table: table,
          clist: clist,
        }),
      };

      fetch(
        process.env.VUE_APP_API_DOMAIN + "/v1/qb/table/" + table + "/records",
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          console.log(result);
          let data = result["body"]["data"];
          this.dataForDropDownFields[table] = data;
          // this.dataForDropDownFields[table].push({});
          // let data = result["body"];
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    getDataForDropDownField(table, fid) {
      console.log("getDataForDropDownField table:" + table + " fid:" + fid);
      let result = [];
      if (Object.hasOwnProperty.call(this.dataForDropDownFields, table)) {
        const data = this.dataForDropDownFields[table];
        for (const key in data) {
          if (Object.hasOwnProperty.call(data, key)) {
            const record = data[key];
            for (const fieldId in record) {
              if (Object.hasOwnProperty.call(record, fieldId)) {
                const element = record[fieldId];
                if (fieldId == fid) {
                  result.push(element["value"]);
                }
              }
            }
          }
        }
      }
      return result;
    },
    getTextMultipleChoiceType(prop) {
      if (Object.hasOwnProperty.call(prop["properties"], "choices")) {
        return "list";
      } else if (
        Object.hasOwnProperty.call(prop["properties"], "masterChoiceTableId")
      ) {
        return "table";
      } else {
        return "other";
      }
    },
    async getRelData() {
      for (const key in this.clistForTables) {
        if (Object.hasOwnProperty.call(this.clistForTables, key)) {
          this.getRelDataQB(key, this.clistForTables[key]);
        }
      }
    },
    async getRelDataQB(tableId, clist) {
      var requestOptions = {
        method: "POST",
        redirect: "follow",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          clist: clist,
          token: localStorage.token,
        }),
      };
      console.log(requestOptions);

      fetch(
        process.env.VUE_APP_API_DOMAIN + "/v1/qb/table/" + tableId + "/records",
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          console.log(result);
          let data = result["body"]["data"];
          // let data = result["body"];
          console.log(data);
          let newRes = [];
          for (const key in data) {
            if (Object.hasOwnProperty.call(data, key)) {
              const element = data[key];

              let tmp = {};
              for (const k in element) {
                if (Object.hasOwnProperty.call(element, k)) {
                  if (k == 3) {
                    tmp["value"] = element[k].value;
                  } else {
                    tmp[k] = element[k].value;
                  }
                }
              }
              newRes.push(tmp);
            }
          }

          this.tablesOprions[tableId] = newRes;
        })
        .catch((error) => {
          console.log("error", error);
          this.gettingReport = false;
        });
    },
    getTypeForElement(value) {
      if (Object.hasOwnProperty.call(value, "componentType")) {
        return value["componentType"];
      } else {
        return "";
      }
    },
    isShowElement(value) {
      if (Object.hasOwnProperty.call(value, "componentType")) {
        if (value["componentType"] == "documentReport15") {
          if (
            Object.hasOwnProperty.call(this.fields, 469) &&
            this.fields[469]["value"]
          )
            return true;
          else return false;
        }
        if (value["componentType"] == "documentReport16") {
          if (
            Object.hasOwnProperty.call(this.fields, 470) &&
            this.fields[470]["value"]
          )
            return true;
          else return false;
        }
      }
      return true;
    },
    isShowTab(value) {
      // if(this.fields.length == 0) return true;
      // "Nomination", "Freight Fixtures", "Ports", "Accounting", "Laytime", "Commodity Contract", "Dates", "", ""
  
      if(value.show == false) return false;

      return true;
    },

    formatDateFunc(date, a) {
      // console.log("+++formatDateFunc+++")
      console.log(a);
      // console.log(date)
      // console.log(date.toUTCString())
      // console.log(date.toISOString())
      // console.log( moment(date).tz("US/Pacific").toString())
      if (date) {
        return moment(date).format("MM-DD-YYYY"); //.tz("US/Pacific")
      } else {
        return "";
      }
    },

    formatDateTimeFunc(date) {
      if (date) {
        return (
          moment(date)
            // .tz("US/Pacific")
            .format("MM-DD-yyyy h:mm a")
        ); // America/New_York
      } else {
        return "";
      }
    },

    formatDateForDateUpdate(date) {
      if (date) {
        return (
          moment(date, "MM-DD-YYYY hh:mm A")
            // .tz("US/Pacific")
            .format("MMM-DD")
        ); // America/New_York
      } else {
        return "";
      }
    },

    updateValuesForFields() {
      this.mapping.forEach((tabs) => {
        tabs.body.forEach((subTubs) => {
          subTubs.body.forEach((row) => {
            row.forEach((field) => {
              if (
                Object.hasOwnProperty.call(field, "key") &&
                !Object.hasOwnProperty.call(field, "forFieldId")
              ) {
                this.fields[field.key]["value"] = field.value;
                // console.log(field.key + " =|= " + field.value);
              } else if(Object.hasOwnProperty.call(field, "forFieldId")) {
                this.fields[field.forFieldId]["value"] = field.value;
              }
            });
          });
        });
      });
    },

    isEmpty(str) {
      if (str == "" || str == null || str === 0) return true;
      return false;
    },

    showFieldWithCustomId(key) {
      console.log(key);
      return true;

    },

    showField(record) {
      console.log("=== showField ===");
      // console.log(record);
      this.updateValuesForFields();
      // let res = true;
      // 103, 526
      let key = record.key;

      if (Object.hasOwnProperty.call(record, "customId")) {
        return this.showFieldWithCustomId(record.customId);
      }

      if (Object.hasOwnProperty.call(record, "labelId")) {
        key = record.labelId;
      }
      console.log(key);
      
      return true;
    },

    getObjArrWithIdKey(fields) {
      let res = {};
      // this.fields.forEach((val) => {
      //   res[val["id"]] = val;
      // });
      fields.forEach((val) => {
        res[val["id"]] = val;
      });
      return res;
    },
    getData() {
      Cargo.getCargoSummaryByRid(this.rid).then((response) => {
        this.offers = response.data["data"];

        console.log("OFFERS", this.offers)
      });

      this.mapping = CargoFields.fieldsMap;

      let params = {};
      params["key"] = this.rid;
      params["clist"] = [
        12
      ];

      for (let header in this.mapping) {
        if (Object.hasOwnProperty.call(this.mapping, header)) {
          for (const subTab in this.mapping[header]["body"]) {
            if (
              Object.hasOwnProperty.call(this.mapping[header]["body"], subTab)
            ) {
              for (const keyRow in this.mapping[header]["body"][subTab][
                "body"
              ]) {
                if (
                  Object.hasOwnProperty.call(
                    this.mapping[header]["body"][subTab]["body"],
                    keyRow
                  )
                ) {
                  for (const key in this.mapping[header]["body"][subTab][
                    "body"
                  ][keyRow]) {
                    if (
                      Object.hasOwnProperty.call(
                        this.mapping[header]["body"][subTab]["body"][keyRow],
                        key
                      )
                    ) {
                      let k = this.mapping[header]["body"][subTab]["body"][keyRow][
                          key
                        ].key;
                        if(k) {
                          params["clist"].push(k);
                        }
                    }
                  }
                  /* params["clist"].push(
                    this.mapping[header]["body"][subTab]["body"][keyRow].key
                  ); */
                }
              }
            }
          }
        }
      }
      var requestOptions = {
        method: "POST",
        redirect: "follow",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(params),
      };

      fetch(
        process.env.VUE_APP_API_DOMAIN + "/v1/qb/table/" + this.masterTableId + "/rid/" + this.rid + "/query",
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          let data = result["body"]["data"];
          let fields = result["body"]["fields"];
          if (data.length) {
            data = data.shift();
            this.fields = data;
          }
          console.log(this.mapping);
          for (let header in this.mapping) {
              console.log("mapping1||| " + this.mapping[header].id);
            if (Object.hasOwnProperty.call(this.mapping, header)) {
              for (const subTab in this.mapping[header]["body"]) {
                if (
                  Object.hasOwnProperty.call(
                    this.mapping[header]["body"],
                    subTab
                  )
                ) {
                  for (const keyRow in this.mapping[header]["body"][subTab][
                    "body"
                  ]) {
                    if (
                      Object.hasOwnProperty.call(
                        this.mapping[header]["body"][subTab]["body"],
                        keyRow
                      )
                    ) {
                      for (const key in this.mapping[header]["body"][subTab][
                        "body"
                      ][keyRow]) {
                        if (
                          Object.hasOwnProperty.call(
                            this.mapping[header]["body"][subTab]["body"][
                              keyRow
                            ][key],
                            "key"
                          )
                        ) {
                          let keyForField =
                            this.mapping[header]["body"][subTab]["body"][
                              keyRow
                            ][key].key;
                          if (
                            this.getObjArrWithIdKey(fields)[keyForField].type ==
                              "timestamp" &&
                            data[keyForField].value
                          ) {
                            this.mapping[header].body[subTab].body[keyRow][
                              key
                            ].value = moment(data[keyForField].value)
                              .tz("US/Pacific")
                              .format("MM-DD-yyyy h:mm a");
                          } else if (
                            this.getObjArrWithIdKey(fields)[keyForField].type ==
                              "date" &&
                            data[keyForField].value
                          ) {
                            this.mapping[header].body[subTab].body[keyRow][
                              key
                            ].value = moment(data[keyForField].value).format(
                              "MM-DD-yyyy"
                            );
                          } else {
                            this.mapping[header].body[subTab].body[keyRow][
                              key
                            ].value = data[keyForField].value;
                            // console.log(keyForField + " ||| " +data[keyForField].value);
                          }
                        }
                      }

                    }
                  }
                }
              }
              // this.set(this.mapping, header, this.mapping[header]);
            }
          }

        })
        .catch((error) => {
          console.log("error", error);
          this.gettingReport = false;
        });
    },

    /**
     * For open pop-up Document
     *  */ 
    openPopUpForAddDocumentTypeCnf(type) {
      this.type = type;
      this.showModalAddDocumentTypeCnf = true;
    },
    openPopUpForAddDocumentTypeFob(type) {
      this.type = type;
      this.showModalAddDocumentTypeFob = true;
    },
    showStatusAddDocumentTypeCnfPopUp(status) {
      this.showModalAddDocumentTypeCnf = status;
    },
    showStatusAddDocumentTypeFobPopUp(status) {
      this.showModalAddDocumentTypeFob = status;
    },
    openPopUpForUpdateRecord(serverResponse) {
      if(serverResponse === 200) {
        this.updatedData = true
      } else {
        this.updatedData = false
      }

      this.showUpdateRecordPopUp = true;

      setTimeout(() => {
        this.showUpdateRecordPopUp = false;
      }, 3000);
    },

    getDataTaskFields() {
      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      fetch(
        process.env.VUE_APP_API_DOMAIN +
          // "http://127.0.0.1:8000/api" +
          "/v1/qb/table/" +
          this.masterTableId +
          "/info",
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          this.allFields = result["body"]["allFields"];
          this.getDataForDropDownFields();
          console.log(this.objAllFields[3]);
        })
        .catch((error) => console.log("error", error));
    },


    /**
     * Collect data for send to quickbase
     */
    sendToQB() {
      if (!this.sendingToQB) {
        this.sendingToQB = true;
        let prop = {};
        prop["to"] = this.masterTableId;
        prop["data"] = [];

        let dataTmp = {};
        dataTmp[3] = this.rid;
        this.mapping.forEach((tabs) => {
          tabs.body.forEach((subTubs) => {
            subTubs.body.forEach((row) => {
              row.forEach((field) => {
                if (Object.hasOwnProperty.call(field, "key")) {
                  if (
                    // field.value != "" &&
                    // field.value != null &&
                    ((this.objAllFields[field.key].mode == "" || Object.hasOwnProperty.call(field, "editable")) &&
                      this.objAllFields[field.key].fieldType != "dblink") /*  &&
                      this.objAllFields[field.key].fieldType !=
                        "text-multiple-choice" */ ||
                    //&& this.objAllFields[field.key].doesDataCopy
                    this.objAllFields[field.key].fieldType == "recordid"
                  ) {
                    if (this.objAllFields[field.key].fieldType == "date") {
                      if (field.value != "" && field.value != null) {
                        dataTmp[field.key] = moment(field.value).format(
                          "YYYY-MM-DD"
                        );
                      } else {
                        dataTmp[field.key] = "";
                      }
                    } else if (
                      this.objAllFields[field.key].fieldType == "timestamp"
                    ) {
                      if (field.value != "" && field.value != null) {
                        dataTmp[field.key] = moment(field.value).format(
                          //2019-12-18T12:00:00 YYYY-MM-DD h:mm a
                          "YYYY-MM-DDTHH:mm:ss"
                        );
                      } else {
                        dataTmp[field.key] = "";
                      }
                    } else {
                      dataTmp[field.key] = field.value;
                    }
                  }
                }
              });
              /* console.log(row);
              if (Object.hasOwnProperty.call(row, "key")) {
                if (
                  (row.value != "" &&
                    row.value != null &&
                    this.objAllFields[row.key].mode == "" &&
                    this.objAllFields[row.key].doesDataCopy &&
                    this.objAllFields[row.key].fieldType !=
                      "text-multiple-choice") ||
                  this.objAllFields[row.key].fieldType == "recordid"
                ) {
                  dataTmp[row.key] = row.value;
                }
              } */
            });
          });
          /* if (el.value != "") {
            dataTmp[el.key] = el.value;
          } */
        });
        prop["data"].push(dataTmp);
        console.log("Seve data:");
        console.log(prop);
        this.saveDataForQB(prop);
      }
      // this.$emit('showStatus', false);
    },

    editableField(field) {
      if (Object.hasOwnProperty.call(field, "editable")) {
        return field.editable;
      } else {
        return false;
      }
    },

    async copyHtmlToClipboard() {
      try {
        // Find the table with the class 'report-table'
        const htmlElement = this.$el.querySelector('.report-table_summary');
        if (htmlElement) {
          // Get the outer HTML of the table
          const html = htmlElement.outerHTML;
          console.log(html);
          // Convert the HTML to a Blob object
          const blob = new Blob([html], { type: 'text/html' });
          // Create a new ClipboardItem object
          // eslint-disable-next-line
          const data = [new ClipboardItem({ 'text/html': blob })];
          // Write the ClipboardItem to the clipboard
          await navigator.clipboard.write(data);
          console.log('HTML table copied to clipboard');
          this.emitter.emit("messageNotificationSent", "Summary table copied to clipboard");
        }
      } catch (err) {
        console.log('Failed to copy table to clipboard: ', err);
      }
    },

    async saveDataForQBWithoutClosePopUp(params) {

      var requestOptions = {
        method: "POST",
        redirect: "follow",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(params), // to, data
      };

      fetch(
        process.env.VUE_APP_API_DOMAIN + "/v1/qb/records/save",
        // process.env.VUE_APP_API_DOMAIN1 + "/v1/qb/records/save",
        // "http://127.0.0.1:8000/v1/qb/records/save",
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          console.log(result);
          // this.clients = result;
        })
        .catch((error) => {
          console.log("error", error);
        });
    },

    /**
     * Save all data to quickbase
     */
    async saveDataForQB(params) {
      this.$refs.ownersField1Ref[0].sendToQB();
      console.log(JSON.stringify(params));

      var requestOptions = {
        method: "POST",
        redirect: "follow",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(params), // to, data
      };
      fetch(
        process.env.VUE_APP_API_DOMAIN + "/v1/qb/records/save",
        // process.env.VUE_APP_API_DOMAIN1 + "/v1/qb/records/save",
        // "http://127.0.0.1:8000/v1/qb/records/save",
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          console.log(result);
          this.$emit("showStatus", false);
          this.$emit("updateRecord", this.rid);

          this.openPopUpForUpdateRecord(result.code)

          setTimeout(() => {
            this.$router.go(0);
          }, 3000);
        })
        .catch((error) => {
          this.sendingToQB = false;
          console.log("error", error);
          
          this.openPopUpForUpdateRecord(error)
        });
    },

    updateCompleteCurrentTask(rid) {
      var requestOptions = {
        method: "POST",
        redirect: "follow",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          token: localStorage.token,
        }),
      };

      fetch(
        process.env.VUE_APP_API_DOMAIN +
          "/v1/qb/button/completeCurrentTask/record/" +
          rid,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          console.log(result);
        })
        .catch((error) => {
          console.log("error", error);
        });
    },

  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* * {
  box-sizing: border-box;
} */
* {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
}

.switcher-content * {
  font-size: 14px;
}
.tables-panel {
  max-width: 100%;
  overflow-y: hidden;
  overflow-x: auto;
  padding-top: 20px;
}
.tables-items {
  display: flex;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.modal {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  width: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
}

.modal__mask {
  display: flex;
  flex-grow: 1;
  overflow-y: hidden;
  flex-direction: column;
}

.modal__wrapper {
  max-height: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

.modal__container {
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.modal__container::-webkit-scrollbar {
  width: 5px;
  height: 10px;
  background-color: #f9f9fd;
}

.modal__container::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #2d375744;
}

.modal__container::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 20px;
  /* border: 3px solid #000; */
  background-color: #e3e9f1;
}

.modal__container-loader {
  overflow-y: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}

@media screen and (max-width: 1680px) {
  .modal__container {
    /* overflow-y: scroll; */
    /* width: 300px; */
    /* width: 1070px; */
  }
}

@media screen and (max-width: 1200px) {
  .modal__container {
    /* overflow-y: scroll; */
    /* width: 300px; */
    margin: 0px auto;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
    font-family: Helvetica, Arial, sans-serif;
    width: 100%;
    /* max-width: 800px; */
  }
}

.modal__header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal__header {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  /* border-bottom: 1px solid #d3dfed; */
  background: #F6FAFF;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 10px;
  flex: 0 0 auto;
}

.modal__header-content {
  padding-top: 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-right: 40px;
  align-items: center;
}

.modal__header-panel {
  display: flex;
}

.modal__title {
  padding: 5px 20px;
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  color: #2d3757;
  max-width: 70%;
  justify-items: center;
  text-align: left;
  flex-grow: 1;
}

.edit-record-title {
  font-weight: 600;
}

.chartere-name {
  font-size: 20px;
  margin-top: 5px;
}
.modal__close {
  position: absolute;
  top: 12px;
  right: 30px;
  cursor: pointer;
  width: 16px;
  height: 16px;
  background: url(@/assets/close.svg) no-repeat center center;
}

.modal__body {
  flex: 1 1 auto;
  overflow-y: auto;
  flex-direction: column;
  display: flex;
}

.cargo-record-top { 
  display: flex;
}
.cargo-record-top .left-wrap {
  width: max-content;
  padding-right: 100px;
}

.cargo-record-top .right-wrap {
  width: max-content;
  padding: 25px 0 0 0;
  display: flex;
  justify-content: flex-start;
}

.right-wrap-report-title {
  font-size: 16px;
  font-weight: bold;
  display: block;
  margin-bottom: 2px;
}
.right-wrap .report-table {
  border-collapse: collapse; 
}

.right-wrap .report-table:not(:last-child) {
  margin-bottom: 20px;
}
.right-wrap th {
  background-color: #f6faff;
  text-align: left;
  font-weight: bold;
}
.right-wrap th.name-column {
  width: max-content;
  min-width: 50px;
}
.right-wrap th.offer-column {
  width: max-content;
  min-width: 50px;
}

.right-wrap th.date-column {
  width: max-content;
  min-width: 50px;
}
.right-wrap th.notes-column {
  width: max-content;
  min-width: 50px;
}
.right-wrap th,
.right-wrap td {
  padding: 5px;
}

.right-wrap td,
.right-wrap thead tr{
  border: 1px solid #d3dfed;
}
.right-wrap tr.no-record td {
  border: none;
}

.right-wrap td.td-offer,
.right-wrap td.td-date-update {
  text-align: center;
}

.right-wrap tr.no-record {
  border: 1px solid #d3dfed;
}

.right-wrap .report-body {
  max-height: 600px;
  overflow: auto;
  margin-right: 40px;
  padding: 20px;
  border: 1px solid #000
}

.right-wrap .report-body.report-body-history {
  margin: 0;
}
.cargo-record-bottom {
  padding: 0 30px;
  font-size: 14px;
}
.switcher-content__sub-tab-title + .sub-tab-body {
  padding: 10px 0 0 0;
}

.modal__default-button {
  float: right;
}

.modal__footer {
  display: flex;
  justify-content: space-between;

  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;

  color: #2d3757;
  padding-left: 30px;
  padding-right: 30px;
  flex: 0 0 auto;
}

.modal__delete-button {
  border: 1px solid #2d3757;
  border-radius: 2px;
  padding: 14px 28px;
  margin-right: 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: rgba(218, 52, 0, 1);
  /*height: 10px;*/
}

.modal__delete-button:hover {
  color: #fff;
  background: rgba(218, 52, 0, 1);
}

.modal__close-button {
  border: 1px solid #2d3757;
  border-radius: 2px;
  padding: 14px 28px;
  margin-right: 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
  box-sizing: border-box;
}

.modal__close-button:hover {
  background-color: #50629b;
  color: white;
}

.modal__close-button:hover .close-button__img {
  filter: invert(100%) brightness(1000%);
}

.close-button__img {
  width: 16px;
  height: 16px;
  background: url(@/assets/btn-close.svg) no-repeat center center;
  margin-right: 10px;
}

.modal__save-button {
  background: #2d3757;
  border-radius: 2px;
  padding: 14px 28px;
  color: #ffffff;
  margin-left: 15px;
  cursor: pointer;
  border: 1px solid transparent;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 116px;
}

.modal__save-button:hover {
  background-color: #50629b;
  border: 1px solid transparent;
  outline: none;
}

.save-button__body {
  display: flex;
  align-items: center;
}

.save-button__img {
  width: 16px;
  height: 16px;
  background: url(@/assets/btn-save.svg) no-repeat center center;
  margin-right: 10px;
}

.preloader {
  display: flex;
  justify-content: center;
}

.report-body::-webkit-scrollbar {
  width: 5px;
  height: 10px;
  background-color: #f9f9fd;
}

.report-body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #2d375744;
}

.report-body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 20px;
  /* border: 3px solid #000; */
  background-color: #e3e9f1;
}

.item-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 5px;
}

.item-modal__title {
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-decoration-line: underline;
  color: #2d3757;
}

.item-modal__img {
  margin-left: 12px;
  height: 20px;
  width: 20px;
  background: url(@/assets/share.svg) no-repeat center center;
}

.switch-bar {
  display: flex;
  flex: 0 0 auto;
  background: #F6FAFF;
  padding: 0 20px;
  padding-bottom: 12px;
  border-bottom: 1px solid #D3DFED;
}

.switch-bar__item {
  /* flex: 1 1 auto; */
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 10px;
  cursor: pointer;
  margin-right: 15px;
  border: 1px solid transparent;
}

.switch-bar__item:hover {
  background: #ffffff;
  border: 1px solid #5D88BE;
  border-radius: 2px;
}

.switch-bar__item_active {
  /* border-bottom: 2px solid #2d3757; */
  
  background: #D3DFED;
  border: 1px solid #5D88BE;
  border-radius: 2px;
}

.switcher-content {
  flex: 1 1 auto;
  max-height: 100%;
  overflow-y: auto;
  padding-left: 30px;
  padding-right: 30px;
}

.switcher-content::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background-color: #f9f9fd;
}

.switcher-content::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #2d375744;
}

.switcher-content::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 20px;
  /* border: 3px solid #000; */
  background-color: #e3e9f1;
}

.switcher-content__choose {
  /* display: flex; */
  /* flex-wrap: wrap; */
}

.switcher-content__sub-tab-body {
  /* display: flex; */
  /* flex-wrap: wrap; */
  /* align-items: end; */
  /* overflow: auto; */
  /* overflow-x: auto; */
}

.disabled {
  background: #eee;
}

.switcher-content__sub-tab-title {
  color: #333;
  font-size: 18px;
  font-weight: bold;
  border-bottom: #2d3757 1px solid;
}

.switcher-content__item {
  box-sizing: border-box;
  padding: 20px 20px;
  width: 50%;
  min-width: 100px;
}

.switcher-content__item_100 {
  box-sizing: border-box;
  width: 100%;
  padding: 20px 20px;
}

.switcher-content__item_25 {
  box-sizing: border-box;
  width: 25%;
  padding: 20px 20px;
}

.switcher-content__title {
  padding: 8px 0;
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;

  color: #6c6f7d;
}

.field-title {
  padding: 8px 0;
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;

  color: #6c6f7d;

  display: flex;
  align-items: center;
}

.field-title__btn-log {
  cursor: pointer;
  margin-right: 5px;
  border-radius: 50px;
  background:  #2d3757;
  color: #fff;
  padding: 2px 7px;
}

.switcher-content__data-input {
  border: 1px solid #d3dfed;
  border-radius: 2px;
  padding: 12px;
  /* width: 100%; */
  font-size: 16px;
  font-family: "Lato";
}

.switcher-content__data-input.checkbox {
  width: auto;
}

.switcher-content__text-data-textarea {
  /* box-sizing: content-box; */
  display: flex;
  box-sizing: border-box;
  vertical-align: bottom;
  /* box-sizing: initial; */
  /* display: table-column; */
  padding: 10px;
  /* word-wrap: break-word; */
  white-space: pre;
  /* padding-bottom: 0; */
  /* width: 100%; */
  font-size: 16px;
  font-family: "Lato";
}

.switcher-content__text-data-textarea.textarea-notes {
  width: 500px !important;
}

.switcher-content__text-data-textarea.disabled {
  /* resize: none; */
  outline: none;
  /* border: none; */
  /* border-left: 1px solid #777; */
  /* border-right: 1px solid #777; */
  /* border-bottom: 1px solid #777; */
  /* margin: 0; */
  /* padding: 0; */
  /* position: absolute; */
  /* top: 40px; */
  /* bottom: 0; */
  /* left: 0; */
  /* right: 0;  */
  /* width:-moz-availabel; height:100%;  */
  resize: none;
}

.url-btn {
  display: block;
  border: 1px solid #2d3757;
  background: #2d375715;
  text-decoration: none;
  border-radius: 5px;
  padding: 5px 15px;
  cursor: pointer;
  text-align: center;
}
.url-btn:hover {
  color: #fff;
  background: #333;
}

.switcher-content__sub-tab-table {
  /* width: 100%; */
}

.switcher-content__sub-tab-table td {
  padding: 5px;
  border: 5px solid transparent;
  vertical-align: top;
}

/* .switcher-content__sub-tab-table td * {
  box-sizing: border-box;
  max-width: 100%;
} */

.switcher-content__sub-tab-table td .field-title {
  box-sizing: border-box;
  max-width: 100%;
  position: relative;
}


.switcher-content__sub-tab-table td .switcher-content__data-input {
  box-sizing: border-box;
  /* max-width: 100%; */
  width: 240px;
  max-width: 240px;
}

.switcher-content__sub-tab-table td .switcher-content__data-multiselect {
  box-sizing: border-box;
  /* max-width: 100%; */
  width: 240px;
  max-width: 240px;
}

.switcher-content__sub-tab-table td .switcher-content__data-multiselect.multiselect-charterer-name {
  width: 210px;
}

.switcher-content__sub-tab-table td .switcher-content__data-multiselect.multiselect-layday-сancelling {
  width: 155px;
}

.switcher-content__sub-tab-table td .switcher-content__data-multiselect.multiselect-status {
  width: 150px;
}

.switcher-content__sub-tab-table td .multiselect-voyage-desc {
  width: 500px;
  max-width: 500px;
}

.switcher-content__sub-tab-table td .multiselect-voyage-desc .multiselect {
  height: 80px !important;
}

.switcher-content__sub-tab-table td .switcher-content__data-multiselect-document-filter {
  box-sizing: border-box;
  /* max-width: 100%; */
  width: 100%;
  max-width: 320px;
}


.switcher-content__sub-tab-table td .switcher-content__data-input.checkbox {
  width: auto;
}

.switcher-content__text-data-textarea {
  min-width: 240px;
  border: 1px solid #d3dfed;
  border-radius: 2px;
}

.v-toolbar {
  z-index: 20 !important;
}
.multiselect--active {
  z-index: 20 !important;
}
.multiselect__content-wrapper {
  z-index: 20 !important;
}


.btn-log {
}

.btn-log__body {
  display: none;
  width: max-content;
  position: absolute;
  background: #2d3757;
  color: #fff;
  z-index: 5;
  padding: 5px;
  left: 0;
  bottom: 30px;
  border-radius: 2px;
  box-shadow: -1px -1px 10px 0px #2d375780;
  border: 1px solid #d3dfed;
}

.btn-log:hover .btn-log__body {
  display: block;
}

</style>

<style>
.multiselect-voyage-desc .multiselect-single-label-text {
  white-space: normal !important;
}
.rich-text__484 div a {
  padding: 8px 8px;
  text-decoration: none;
  /* color: #6c6f7d; */

  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #2d3757;
  cursor: default;
}

.dp__input_wrap .dp-custom-input {
  font-size: 14px !important;
  width: 100% !important;
}

.v-toolbar {
  z-index: 20 !important;
}
.multiselect--active {
  z-index: 20 !important;
}
.multiselect__content-wrapper {
  z-index: 20 !important;
}

.btn-apply {
  /* display: flex; */
  margin-left: 20px;
  /* margin-top: 1px; */
}

.btn-apply__body {
  /* background: rgb(106, 141, 255); */
  color: #fff;
  /* font-size: 1.25em; */
  padding: 10px 30px;
  margin-right: 20px;
  cursor: pointer;

  background: #2d3757;
  border-radius: 2px;

  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  /* line-height: 19px; */

  color: #ffffff;
}

.report-table__item-text-multiple-choice-wrap {
  min-width: 240px;
}

.document-report-wrap {
  /* max-width: 1185px; */
  /* width: 1185px; */
}

/* .report-body {
  width: 1100px;
}

@media screen and (max-width: 1200px) {
  .report-body {
    width: 700px;
  }
} */


.google-drive {
  display: flex;
  align-items: center;
}

.google-drive__left-img {
  width: 16px;
  height: 16px;
  background: url(@/assets/link.svg) no-repeat center center;
  margin-right: 8px;
}

</style>

<style scoped>
.dp__input_wrap .dp__input {
  font-size: 14px;
  padding: 8px 12px;
  padding-left: 35px;
  border: 1px solid #d3dfed !important;
  border-radius: 2px !important;
}

.multiselect {
  height: 43px !important;
  border: 1px solid #d3dfed !important;
  border-radius: 2px !important;
}
</style>

<style scoped src="@vueform/multiselect/themes/default.css"></style>

