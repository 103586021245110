<template>
  <div class="report-panel">
    <div class="report__title">
      <div class="report__collapse" @click="showBody = !showBody">
        <span v-if="showBody">-</span>
        <span v-else>+</span>
      </div>
      <h2 @click="showBody = !showBody">{{ title }}</h2>
      <div class="create-record-wrap">
        <button
          class="create-record"
          @click="
            () => {
              this.$router.push({
                name: 'CreateUserRecord',
                params: {},
              });
            }
          "
        >
          New User
        </button>
      </div>
    </div>
    <div class="preloader" v-if="gettingReport">
      <CustomLoader />
    </div>
    <div v-else-if="reportData.length != 0 && showBody">

      <input v-model="searchText" class="search-input" placeholder="Search..." @change="filteredItems()">

      <div class="report-body wrap-scroll">
        <table class="report-table">
          <thead>
            <tr class="report-table__head" style="position: sticky">
              <th class="report-table__sticky report-table__col-pencil"></th>
              <th>
                <div>User Name</div>
              </th>
              <th>
                <div>Email</div>
              </th>
              <th class="column-role">
                <div>Role</div>
              </th>
              <th class="column-approved">
                <div>Approved</div>
              </th>
              <th class="column-charterer">
                <div>Charterer(s)</div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, index) in filteredItems" :key="index">
              <td class="report-table__sticky report-table__col-pencil">
                <div
                  class="record__edit-button"
                  @click="() => {
                    this.$router.push({ name: 'EditUserRecord', params: { id: row.id, open: true } })
                  }"
                ></div>
              </td>
              <td>
                <div class="report-table__item">
                  {{ row.name }}
                </div>
              </td>
              <td>
                <div class="report-table__item">
                  {{ row.email }}
                </div>
              </td>
              <td class="column-role">
                <div class="report-table__item">
                  {{ row.role }}
                </div>
              </td>
              <td class="column-approved">
                <div class="report-table__item">
                  <input type="checkbox" :checked="row.approved">
                </div>
              </td>
              <td class="column-charterer">
                <div class="report-table__item">
                    <span>
                      {{
                        row.charterers
                          .map(id => this.charterers.find(c => c.id === id)?.name)
                          .filter(name => name)
                          .join(', ')
                      }}
                    </span>
                </div>
              </td>
            </tr>
          </tbody>
          
        </table>
      </div>

    </div>

  </div>
</template>

<script>
// import Users from "@/api/tradingPlatform/users";
import { mapGetters, mapActions } from "vuex";
import moment from "moment-timezone";
import CustomLoader from "../loader/CustomLoader.vue";

export default {
  name: "UsersRecord",
  components: {
    CustomLoader,
  },
  props: {
    table: String,
    title: String,
    report: String,
    filter: {},
    showBodyProp: Boolean,
    stickyColumns: Number,
  },
  emits: ["getInfo"],
  data: function () {
    return {
      users: [],
      charterers: [],
      showBody: true,
      showModalWithParentData: false,
      parentReportData: [],
      parentFields: [],
      parentAllFields: [],
      fidsForFFModal: [],
      masterTableId: "",
      searchText: "",
    };
  },
  watch: {
    report() {
      console.log(this.report);
      this.getQBReport();
    },
    filter() {
      this.getQBReport();
    },
  },
  mounted() {
    this.getData();
    this.getCharterers();
    this.getQBReport();
    this.showBody = this.showBodyProp;
  },
  updated() {

  },
  
  computed: {
    ...mapGetters("owners", ["getReportById"]),

    reportData() {
      return this.getReportById(this.report).data ?? [];
    },
    fields() {
      return this.getReportById(this.report).fields;
    },
    allFields() {
      return this.getReportById(this.report).allFields;
    },
    clist() {
      return this.getReportById(this.report).clist;
    },
    relationships() {
      return this.getReportById(this.report).relationships;
    },

    gettingReport() {
      return this.getReportById(this.report).loading ?? true;
    },

    filteredItems() {
      return this.users.filter(item => {
        let strValue = ""

        for (let key of Object.keys(item)) {
          if (typeof item[key] === "string") {
            strValue += `${item[key]?.toLowerCase()} `
          } else if (typeof item[key] === "object" && item[key] !== null && item[key].length) {
            let internalObject = item[key]

            for (let internalKey of Object.keys(internalObject)) {
              strValue += `${internalObject[internalKey].toString().toLowerCase()}`
            }
          }
        }
        return strValue.includes(this.searchText.toLowerCase())
      });
    },

  },
  methods: {
    ...mapActions('owners', ["getReport"]),

    currencyFormat(value, prop) {
      // currencyFormat(value) {
      if (value) {
        /* let formatedValue = value.toLocaleString("en-US", {
            style: 'currency',
          }); */
        let formatter = new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
          maximumFractionDigits: prop.properties.decimalPlaces,
        });
        return formatter.format(value);
      } else {
        return "";
      }
    },

    formatDateFunc(date) {
      if (date) {
        if (moment(date).year() == moment().year()) {
          return moment(date).format("MMM DD");
        }
        return moment(date).format("MMM DD, yyyy");
      } else {
        return "";
      }
    },

    formatDateTimeFunc(date, props) {
      if (date) {
        if (props["properties"]["displayTime"]) {
          return moment(date).tz("US/Pacific").format("MM-DD-yyyy h:mm a");
        } else {
          if(props["properties"]["displayMonth"] == "name") {
            if(props["properties"]["displayRelative"]) {
              if (moment(date).year() == moment().year()) {
                return moment(date).tz("US/Pacific").format("MMM DD");
              } else {
                return moment(date).tz("US/Pacific").format("MMM DD, yyyy");
              }
            } else {
              return moment(date).tz("US/Pacific").format("MMM-DD-yyyy");
            }
          } else {
            return moment(date).tz("US/Pacific").format("MM-DD-yyyy");
          }
        }
      } else {
        return "";
      }
    },

    formatMultitext(value) {
      if(value) {
        return value.join(", ");
      } else {
        return "";
      }
    },

    async updateRecord(rid) {
      this.getRecordByRidForReport(rid, this.table);
    },


    async showModal() {
      this.showModalWithParentData = true;
    },

    getInfoReport(report) {
      console.log(report);
      this.$emit("getInfo", report);
    },

    getKeyForRow(row) {
      return this.reportData[row][3]["value"];
    },

    async getQBReport() {
      this.getReport({table: this.table, reportId: this.report, filter: this.filter});
      // this.gettingReport = true;
      this.gettingReport = false;
    },

    getData() {
      var requestUsers = {
        method: "GET",
        redirect: "follow",
        headers: { 
          "Content-Type": "application/json",
          "Authorization": "Bearer " + localStorage.token
        },
      };

      fetch(
        process.env.VUE_APP_API_DOMAIN + `/v1/users`,
        requestUsers
      )
        .then((response) => response.json())
        .then((result) => {
          this.users = result["data"]
          
          console.log("Users", result["data"]);
        })
        .catch((error) => {
          console.log("Error", error);
        });
    },

    getCharterers() {
      var requestUsers = {
        method: "GET",
        redirect: "follow",
        headers: { 
          "Content-Type": "application/json",
          "Authorization": "Bearer " + localStorage.token
        },
      };

      fetch(
        process.env.VUE_APP_API_DOMAIN + `/v1/fm/charterers`,
        requestUsers
      )
        .then((response) => response.json())
        .then((result) => {
          this.charterers = result["data"]
          
          console.log("Charterers", this.charterers);
        })
        .catch((error) => {
          console.log("Error", error);
        });
    },

  },
};
</script>

<style src="@vueform/multiselect/themes/default.css"></style>

<style>
.modal-contract-type * {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #2d3757;
  text-decoration: none;
}
</style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.report-panel {
  max-width: 100%;
  overflow-y: hidden;
  overflow-x: auto;
}

.search-input {
  margin-left: 32px;
  width: 420px;
  height: 40px;
  padding: 10px;
  box-sizing: border-box;
  outline: none;
  border: var(--ms-border-width,1px) solid var(--ms-border-color,#d1d5db);
  border-radius: var(--ms-radius,4px);
  background: var(--ms-bg,#fff);
  font-size: var(--ms-font-size,1rem);
  min-height: calc(var(--ms-border-width, 1px)*2 + var(--ms-font-size, 1rem)*var(--ms-line-height, 1.375) + var(--ms-py, .5rem)*2);
}

.search-input::placeholder {
  color: #9ca3af;
}

.search-input:focus {
  box-shadow: 0 0 0 var(--ms-ring-width,3px) var(--ms-ring-color,rgba(16,185,129,.18823529411764706));
}

.report-body {
  overflow-y: auto;
  overflow-x: auto;
  position: relative;
  margin-top: 10px;
  padding: 0 5px 45px 0;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

td {
  border-bottom: 1px solid #d3dfed;
}

tr td {
  border-right: 1px solid #d3dfed4c;
}

tr td:last-child {
  border-right: 1px solid #d3dfed;
}

thead {
  background: #fff;
}

tbody tr:first-child td {
  border-top: 1px solid #d3dfed;
}

tbody tr:first-child td:first-child {
  border-top: none;
}

.report-table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  position: relative;
}

.report__collapse {
  border: 3px solid #d3dfed;
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.75em;
  margin-right: 15px;
  color: #2d3757;
  background: #f6faff;
}

.report-table thead tr {
  position: relative;
}

th {
  position: sticky;
  z-index: 1;
  border: 0;
  padding: 5px 10px;
  color: rgb(255, 255, 255);
  -webkit-font-smoothing: antialiased;
  cursor: default;
  vertical-align: middle;
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  color: #2d3757;
  min-height: 70px;
}

.report-table .report-table__head th > div {
  min-height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.report-table td div {
  white-space: nowrap;
}

.report-table__head {
  top: 0;
  z-index: 999;
  background-color: #fff;
  /* border-bottom: 1px solid #d3dfed; */
}

.btn-save {
  display: flex;
  justify-content: end;
}

.btn-save__body {
  background: rgb(106, 141, 255);
  color: #fff;
  font-size: 1.25em;
  padding: 10px 30px;
  margin-right: 20px;
  cursor: pointer;
}

.modal__mask {
  position: fixed;
  z-index: 5;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  transition: opacity 0.3s ease;
  padding: 20px;
}

.modal__wrapper {
  margin: 40px;
  margin-bottom: 0;
  overflow-y: auto;
  max-height: 100%;
}

.modal__container {
  margin: 0px auto;
  padding: 20px 0;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
  max-width: 800px;
}

.modal__header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal__header {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #d3dfed;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 20px;
}

.modal__title {
  padding: 5px 20px;
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  color: #2d3757;
}

.modal__close {
  position: absolute;
  top: 12px;
  right: 30px;
  cursor: pointer;
  width: 16px;
  height: 16px;
  background: url(@/assets/close.svg) no-repeat center center;
}

.modal__body {
  margin: 20px 0;
  padding-left: 30px;
  padding-right: 30px;
}

.modal__default-button {
  float: right;
}

.modal__footer {
  display: flex;
  justify-content: space-between;

  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;

  color: #2d3757;
  padding-left: 30px;
  padding-right: 30px;
}

.modal__close-button {
  border: 1px solid #2d3757;
  border-radius: 2px;
  padding: 14px 55px;
  margin-right: 15px;
  cursor: pointer;
}

.modal__save-button {
  background: #2d3757;
  border-radius: 2px;
  padding: 14px 55px;
  color: #ffffff;
  margin-left: 15px;
  cursor: pointer;
}

.preloader {
  display: flex;
  justify-content: center;
}

.report-body::-webkit-scrollbar {
  width: 5px;
  height: 10px;
  background-color: #f9f9fd;
}

.report-body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #2d375744;
}

.report-body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 20px;
  /* border: 3px solid #000; */
  background-color: #e3e9f1;
}

.item-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 5px;
}

.item-modal__title {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #2d3757;
}

.item-modal__title2 {
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-decoration-line: underline;
  color: #2d3757;
}

.modal-contract-type {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #2d3757;
  text-decoration: none;
}

.item-modal__img {
  margin-left: 10px;
  height: 16px;
  width: 16px;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 16px;
  background: url(@/assets/share.svg) no-repeat center center;
  background-size: 16px 16px;
}

.report-table__item {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #2d3757;
  display: block;
  height: 100%;
  width: 100%;
  padding: 15px;
  box-sizing: border-box;
}

.charterers {
  display: flex;
}

.report-table__item-wrap {
  display: flex;
}

.report-table__sticky {
  position: -webkit-sticky; /* for Safari */
  position: sticky;
  background: #fff;
  left: 0;
  z-index: 998;
}

td.report-table__sticky {
  background: #FFF;
}

th.report-table__col-pencil {
  background: #fff;
}

.report-table__sticky > div::after,
.report-table__sticky > div::before {
  content: '';
  position: absolute;
  left: 0;
  width: 100%;
}

.report-table__sticky > div::before {
  top: -1px;
  border-top: 1px solid #d3dfed;
}

th.report-table__sticky > div::before {
  border-top: 0px solid #d3dfed;
}

.report-table__sticky > div::after {
  bottom: -1px;
  border-bottom: 1px solid #d3dfed;
}

.report-table__sticky::after,
.report-table__sticky::before {
  content: '';
  position: absolute;
  top: -1px;
  height: 100%;
}

.report-table__sticky::before {
  left: -1px;
  border-left: 1px solid #d3dfed4c;
}

.report-table__sticky::after {
  right: -1px;
  border-right: 1px solid #d3dfed4c;
}

th.report-table__sticky::before {
  left: -1px;
  border-left: 1px solid #f6faff4c;
}

th.report-table__sticky::after {
  right: 0;
  border-right: 1px solid #f6faff4c;
}

.switch-bar {
  display: flex;
}

.switch-bar__item {
  flex: 1 1 auto;
  text-align: center;
  border-bottom: 1px solid #d3dfed;
  padding: 8px 0;
  cursor: pointer;
}

.switch-bar__item_active {
  border-bottom: 2px solid #2d3757;
}

.switcher-content__choose {
  display: flex;
  flex-wrap: wrap;
}

.switcher-content__item {
  box-sizing: border-box;
  padding: 20px 20px;
  width: 50%;
  min-width: 100px;
}

.switcher-content__item_100 {
  width: 100%;
}

.switcher-content__item_25 {
  width: 25%;
}

.switcher-content__title {
  padding: 8px 0;
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: #6c6f7d;
}

.switcher-content__data-input {
  border: 1px solid #d3dfed;
  border-radius: 2px;
  padding: 12px;
  width: 100%;
}

.report-table__item-formula_other {
  padding: 15px;
  margin: 0 5px;
}

.report-table__item-text-multiple-choice-wrap {
  position: relative;
  padding: 5px 15px;
}

.report-table__item-boolean-wrap {
  position: relative;
  padding: 5px 15px;
}

.red {
  background: red;
}

.green {
  background: green;
}

.blue {
  background: blue;
}

.LightOrange {
  background-color: #deecfc;
}

.LightBlue {
  background-color: #b9ceeb;
}

.LightGreen {
  background-color: #c3b4d2 ;
}

.LightGray {
  background-color: #ECEEEA;
}

.report-table tbody tr:hover td{
  background: #f2f7fc;
}

.report-table tbody tr:hover td.LightOrange {
  background-color: #F2F7FE;
}

.report-table tbody tr:hover td.LightBlue {
  background-color: #E9EFF9;
}
/* E9EFF9 */
.report-table tbody tr:hover td.LightGreen {
  background-color: #EEEAF3;
}

.report-table tbody tr:hover td.LightGray {
  background-color: #F7F8F7;
}

.report-table tbody tr:hover td.report-table__col-pencil {
  background: none;
}

.report-table tbody tr th.report-table__col-pencil {
  background: #fff;
}

.report-table__col-pencil {
  border: 0;
  width: 32px;
  padding: 0;
}

.column-approved {
  width: 80px;
}

.column-approved .report-table__item {
    display: flex;
    justify-content: center;
    align-items: center;
  }

.column-role {
  width: 110px;
}

.column-role .report-table__item {
  display: flex;
  justify-content: center;
}

.report-table__sticky > div::after,
.report-table__sticky > div::before {
  border: 0;
}

.report-table tbody tr:hover td.report-table__col-pencil .record__edit-button {
  background: #eee;
  width: 32px;
  height: 32px;
  border-radius: 5px;
  flex-grow: 0; /* do not grow   - initial value: 0 */
  flex-shrink: 0;
  flex-basis: 16px;
  background: url(@/assets/icon-pencil.svg) no-repeat center center;
  background-size: 16px 16px;
  cursor: pointer;
}

.report-table tbody tr:hover td.report-table__col-pencil {
  background: #fff;
}

.contract-document {
  cursor: pointer;
  background: #eef0f3;
  display: inline-block;
  border-radius: 15px;
  padding: 3px 10px;
  margin-right: 5px;
}

.contract-document:last-child {
  margin-right: 0;
}

</style>

<style>
.Vibrant {
  cursor: pointer;
  display: inline-block;
  padding: 5px 15px;
  border-radius: 5px;
  margin: 5px;
  color: #fff;
  text-decoration: none;
}

.OpenAsPopup {
  padding: 5px 15px;
  display: block;
  border-radius: 5px;
  margin: 5px;
  color: #fff;
  text-decoration: none;
}

.record__edit-button {
  width: 32px;
  height: 32px;
}

.report__title {
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 48px;
  color: #2d3757;
  padding-top: 30px;
  padding-bottom: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.wrap-scroll {
  height: calc(100vh - 270px);
  overflow-x: auto;
  overflow-y: auto;
  margin: 0;
  position: relative;
}

.create-record-wrap {
  flex-grow: 1;
  display: flex;
  justify-content: end;
}

.create-record {
  padding: 12px 24px;
  background: #2d3757;
  border-radius: 2px;
  color: #ffffff;
  cursor: pointer;
  font-size: 16px;
}


.report-table__item-formula-rich-text-wrap {
  padding-left: 15px;
  padding-right: 15px;
}
</style>

<style>
.rich-text__484 div a {
  padding: 8px 8px;
  text-decoration: none;
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #2d3757;
  cursor: default;
}

.rich-text__956 div {
  padding: 8px 8px;
  text-decoration: none;
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #2d3757;
  cursor: default;
}
</style>
