 <template>
  <div class="modal__container">
    <div class="modal__header">
      <div class="modal__header-content">
        <div>
          <span class="header__title">Reports</span>
        </div>
        <div class="modal__header-panel">
          <div class="modal__save-button" v-on:click="() => this.createReport()">
            <CustomLoader v-if="sendingToQB" />
            <div v-else class="save-button__body">
              <div class="save-button__img"></div>
              <span>Create Report</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal__body">
      <div class="reports-panel">
        <CustomLoader v-if="waitingData"/>
        <p class="no-reports" v-if="reports.length===0 && !waitingData">No reports have been created yet.</p>
        <div class="report-item" v-for="(report, index) in this.reports" :key="index">
          <div class="report-item__container">
            <div class="report-item__header">
              <div class="report-item__title">{{ report.name }}</div>
            </div>
            <div class="report-item__body">
              <div class="fields">
                <div class="field" v-for="(field, i) in report.clist" :key="i">{{ this.getFieldName(field) }}</div>
              </div>
            </div>
          </div>
          <div class="report-item__buttons-panel">
            <div class="report-item__buttons" v-on:click="() => this.editReport(report.id)">
              <div class="report-item__edit-button"></div>
              <p class="report-item__text">Edit</p>
            </div>
            <div class="report-item__buttons"  v-on:click="() => this.downloadReport(report.id)">
              <div class="report-item__download-button"></div>
              <p class="report-item__text">Download</p>
            </div>
            <div class="report-item__buttons" v-on:click="() => this.showDeleteConfirmation(report.id)">
              <div class="report-item__delete-button"></div>
              <p class="report-item__text">Delete</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <DeleteConfirmation
        :on-confirm="confirmDelete"
        :on-cancel="cancelDelete"
        :is-report="true"
        v-if="showConfirmation"
    />
  </div>
</template>

<script>
import ReportGenerator from '@/api/ReportGenerator';
import DeleteConfirmation from "@/components/pop-up/DeleteConfirmation.vue";
import CustomLoader from "@/components/loader/CustomLoader.vue";


export default {
  components: {
    DeleteConfirmation,
    CustomLoader,
    // eslint-disable-next-line vue/no-unused-components
  },
  props: {
    open: Boolean, // delete
  },
  data() {
    return {
      name: "",
      firstList: [
        /* { id: 1, name: 'Елемент 1' },
        { id: 2, name: 'Елемент 2' },
        { id: 3, name: 'Елемент 3' } */
      ],
      secondList: [],
      selectedItem: null,
      selectedItem2: null,

      fields: {},
      reports: [],
      reportId: Number,
      showConfirmation: false,
      waitingData: true,
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      /* RecordGenerator.getTableFields("brxisk55z").then((response) => {
        this.fields = response["data"];
      }); */
      let response = await ReportGenerator.getTableFields("brxisk55z");
      let fields = response["data"];

      fields.forEach((item) => {
        this.fields[item.id] = item;
      });

      let reportsResponse = await ReportGenerator.getReports();
      reportsResponse.data["data"].forEach((item) => {
        this.reports.push({
          id: item.id,
          name: item.name,
          clist: JSON.parse(item.clist),
        });
      });
      this.reports.sort((a, b) => {
        return b.id - a.id;
      })

      this.waitingData = false
    },
    moveToSecondList(item) {
      this.secondList.push(item);
      this.firstList = this.firstList.filter(i => i !== item);
      this.selectedItem = null;
    },
    moveToFirstList(item) {
      this.firstList.push(item);
      this.firstList.sort((a, b) => {
        return a.name.localeCompare(b.name);
      })
      this.secondList = this.secondList.filter(i => i !== item);
      this.selectedItem2 = null;
    },
    changeOrder(item, direction) {
      const list = this.secondList;
      const index = list.indexOf(item);
      const newIndex = direction === 'up' ? index - 1 : index + 1;
      if (newIndex >= 0 && newIndex < list.length) {
        list.splice(index, 1);
        list.splice(newIndex, 0, item);
      }
    },
    downloadPdf() {
      let clist = [];
      this.secondList.forEach((item) => {
        clist.push(item.id);
      });

      var url = process.env.VUE_APP_API_DOMAIN + "/v1/report-generator/table/brxisk55z/generate?name=" + this.name + "&clist=" + JSON.stringify(clist);

      window.open(url, "_blank");
    },

    leave() {
      // this.$router.push({ name: 'individuals-view' });
    },
    createReport() {
      this.$router.push('/generator/report/create')
    },
    getFieldName(id) {
      return this.fields[id].label
    },
    editReport(id) {
      // this.$router.push("/generator/report/" + id + "/edit")
      this.$router.push({
        // path: "/generator/report/" + id + "/edit",
        name: 'reportGeneratorEditView',
        params: { id: id },
      })
      
    },
    downloadReport(id) {
      var url = process.env.VUE_APP_API_DOMAIN + "/v1/report-generator/reports/" + id + "/download";

      window.open(url, "_blank");
    },

    showDeleteConfirmation(reportId) {
      this.reportId = reportId;
      this.showConfirmation = true;
    },

    async confirmDelete() {
      await ReportGenerator.deleteReport(this.reportId);
      this.reports = this.reports.filter(item => item.id !== this.reportId);
      this.showConfirmation = false;
    },

    cancelDelete() {
      this.showConfirmation = false;
    }
  }
};
</script>


<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');


.modal__save-button {
  background: #2d3757;
  border-radius: 2px;
  padding: 14px 28px;
  color: #ffffff;
  margin-left: 15px;
  cursor: pointer;
}

.modal__save-button:hover {
  background-color: #50629b;
  outline: none;
}

.save-button__body {
  display: flex;
  align-items: center;
}

.save-button__img {
  width: 16px;
  height: 16px;
  background: url(@/assets/btn-add.svg) no-repeat center center;
  margin-right: 10px;
}

.modal__header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal__header {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  /* border-bottom: 1px solid #d3dfed; */
  background: #F6FAFF;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 15px;
  flex: 0 0 auto;
}

.modal__header-content {
  padding-top: 25px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-right: 40px;
  align-items: center;
}

.modal__header-panel {
  display: flex;
}

.modal__title {
  padding: 5px 20px;
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  color: #2d3757;
  max-width: 70%;
  justify-items: center;
  text-align: left;
  flex-grow: 1;
}

.modal__close {
  position: absolute;
  top: 12px;
  right: 30px;
  cursor: pointer;
  width: 16px;
  height: 16px;
  background: url(@/assets/close.svg) no-repeat center center;
}

.modal__body {
  flex: 1 1 auto;
  overflow-y: auto;
  flex-direction: column;
  display: flex;
}

.header__title {
  padding: 12px;
  font-family: Lato;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
}

.buttons-panel {
  display: flex;
  width: 960px;
  max-width: 100%;
  justify-content: space-around;
  margin: 20px auto;
}

.reports-panel {
  width: 960px;
  margin: 20px auto;
}

.report-item__container {
  width: 790px;
  min-height: 108px;
}

.report-item {
  display: flex;
  justify-items: center;
  box-sizing: border-box;
  background: #f6faff;
  border: 2px solid #d3dfed;
  border-radius: 2px;
  padding: 15px 20px;
  margin-top: 20px;
  position: relative;
  min-height: 132px;
}
.report-item:hover {
  border-color: #ABB1C8;
}

.report-item__header {
  display: flex;
  justify-content: space-between;
}

.report-item__buttons-panel {
    /* flex-shrink: 0; */
  flex-grow: 0;

}

.report-item__title {
  padding: 12px;
  padding-top: 0;
  padding-left: 5px;
  font-family: Lato;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  flex-grow: 1;
}

.fields {
  display: flex;
  flex-wrap: wrap;
}

.field {
  background: #fff;
  border: 1px solid #d3dfed;
  border-radius: 15px;
  padding: 3px 10px;
  margin-right: 10px;
  margin-top: 5px;
  font-size: 14px;
}

.report-item__buttons-panel {
  display: none;
}

.report-item:hover .report-item__buttons-panel {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.report-item__buttons {
  display: flex;
  align-items: center;
  cursor: pointer;
  border: 1px solid transparent;
}

/* .report-item__buttons:hover .report-item__edit-button {
  background: url(@/assets/Edit_fill_hover.svg) no-repeat center center;
} */

/* .report-item__buttons:hover .report-item__download-button {
  background: url(@/assets/Download_circle_fill_hover.svg) no-repeat center center;
} */

/* .report-item__buttons:hover .report-item__delete-button{
  background: url(@/assets/TrashHover.svg) no-repeat center center;
} */

.report-item__buttons:hover {
  border-color: #5D88BE;
  border-radius: 4px;
}

.report-item__edit-button {
  background: #eee;
  width: 32px;
  height: 32px;
  border-radius: 5px;
  flex-grow: 0; /* do not grow   - initial value: 0 */
  flex-shrink: 0;
  flex-basis: 32px;
  background: url(@/assets/Edit_fill.svg) no-repeat center center;
  background-size: 24px 24px;
  margin-left: 10px;
}

.report-item__text {
  color: #2D3757;
  font-family: Lato;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  text-align: left;
  margin-right: 15px;
}

.report-item__download-button {
  background: #eee;
  width: 32px;
  height: 32px;
  border-radius: 5px;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 32px;
  background: url(@/assets/Download_circle_fill.svg) no-repeat center center;
  background-size: 24px 24px;
  margin-left: 10px;
}

.report-item__delete-button {
  background: #eee;
  width: 32px;
  height: 32px;
  border-radius: 5px;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 32px;
  background: url(@/assets/Trash.svg) no-repeat center center;
  background-size: 24px 24px;
  margin-left: 10px;
  fill: #ff0000;
}

.modal__container {
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.modal__body::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background-color: #f9f9fd;
}

.modal__body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #2d375744;
}

.modal__body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 20px;
  /* border: 3px solid #000; */
  background-color: #e3e9f1;
}

.no-reports {
  text-align: center;
  padding-top: 100px;
}

.loader {
  display: flex;
  justify-content: center;
}

</style>
