<template>
  <div class="modal">
    <div class="modal__mask">
      <div class="modal__wrapper">
        <div class="modal__container">
          <div class="modal__header">
            <div class="modal__title">{{ modalName }}</div>
            <div class="modal__close"></div>
            <div
              class="modal__close"
              v-on:click="
                () => {
                  this.$emit('showStatus', false);
                }
              "
            ></div>
          </div>

          <div class="modal__body">
            <div class="report-body">
              <div class="filter">
                <div class="filter__wrap">
                  <div class="filter__body">
                    <div class="filter__title">
                      <div class="filter__img"></div>
                      <div class="filter__title-text">Filters</div>
                    </div>
                    <div class="filter__items">
                      <div class="filter__item">
                        <!-- <div class="filter__item-name">Charterer - Name</div> -->
                        <Multiselect
                          v-model="countryFilterValue"
                          :options="countryFilter"
                          preserve-search="true"
                          mode="tags"
                          searchable="true"
                          clearOnSelect="false"
                          placeholder="Choose country"
                          :close-on-select="false"
                          :multiple="true"
                        >
                          <!-- <template v-slot:multiplelabel="{ values }">
                  <div class="multiselect-multiple-label">
                    <div class="test" v-for="item in values" :key="item">
                      {{ item.value }}
                    </div>
                  </div>
                </template> -->
                        </Multiselect>
                      </div>

                      <div class="filter__item">
                        <!-- <div class="filter__item-name">Load Ports</div> -->
                        <Multiselect
                          v-model="stateFilterValue"
                          :options="stateFilter"
                          mode="tags"
                          preserve-search="true"
                          searchable="true"
                          clearOnSelect="false"
                          placeholder="Choose state"
                          :close-on-select="false"
                          :multiple="true"
                        >
                          <!-- <template v-slot:multiplelabel="{ values }">
                  <div class="multiselect-multiple-label">
                    <div class="test" v-for="item in values" :key="item">
                      {{ item.value }}
                    </div>
                  </div>
                </template> -->
                        </Multiselect>
                      </div>
                      <div class="filter__item">
                        <div class="filter__item-title">Start Holiday Date</div>
                        <DatePicker
                          :format="(t) => formatDateFunc(t)"
                          :previewFormat="(t) => formatDateFunc(t)"
                          v-model="startHolidayDateFilterValue"
                          :enableTimePicker="false"
                        />
                      </div>
                      <div class="filter__item">
                        <div class="filter__item-title">End Holiday Date</div>
                        <DatePicker
                          :format="(t) => formatDateFunc(t)"
                          :previewFormat="(t) => formatDateFunc(t)"
                          v-model="endHolidayDateFilterValue"
                          :enableTimePicker="false"
                        />
                      </div>
                    </div>
                    <!-- <div class="btn-apply">
                      <div class="btn-apply__body" v-on:click="filterApply">
                        <custom-loader v-if="filterApplying" />
                        <span v-else>Apply</span>
                      </div>
                    </div> -->
                  </div>
                  <div class="filter__footer"></div>
                </div>
              </div>

              <HolidaysReport
                :reportDataOriginal=this.reportDataOriginal
                :countryFilterValue=this.countryFilterValue
                :stateFilterValue=this.stateFilterValue
                :startHolidayDateFilterValue=this.startHolidayDateFilterValue
                :endHolidayDateFilterValue=this.endHolidayDateFilterValue
                :timeForFilterRequire=false
              />
            </div>

            <div class="switch-bar modal__switch-bar">
              <!-- <div class="switch-bar__item switch-bar__item_active">General</div> -->
              <!-- <div class="switch-bar__item">Freight</div>
                <div class="switch-bar__item">Reminders</div> -->
            </div>
            <div class="switcher-content">
              <div class="switcher-content__choose"></div>
            </div>
            <!-- <table class="report-table">
                <tbody>
                  <tr v-for="(value, index) in parentReportData" :key="index">
                    <td>
                      <b>{{ this.objParentFields[index].label }}</b>
                    </td>
                    <td>
                      <textarea :value="value.value"></textarea>
                    </td>
                  </tr>
                </tbody>
              </table> -->
          </div>

          <div class="modal__footer">
            <div
              class="modal__close-button"
              v-on:click="
                () => {
                  this.$emit('showStatus', false);
                }
              "
            >
              Cancel
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "@vueform/multiselect";
import HolidaysReport from "./HolidaysReport.vue";
import moment from "moment";

import { mapState } from "vuex";

export default {
  name: "ShowBimcoHolidaysPopUp",
  components: {
    Multiselect,
    HolidaysReport,
  },
  props: {
    table: String,
    selectedReport: String,
    open: Boolean,
    recordKey: String || Number,
  },
  emits: ["showStatus"],
  data: function () {
    return {
      chartererNameFilterValue: [],
      reports: [],
      // reportData: [],
      // reportDataOriginal: [],
      fields: [],
      // allFields: [],
      // clist: [3,6,12],
      // clist: [],
      relationships: [],
      forSave: {},
      saving: false,
      gettingReport: true,
      showModalWithParentData: false,

      modalName: "Bimco Holidays",

      // countryFilter: [],
      // stateFilter: [],
      countryFilterValue: [],
      stateFilterValue: [],
      startHolidayDateFilterValue: null,
      endHolidayDateFilterValue: null,
    };
  },
  watch: {
    /* countryFilterValue() {
      this.filterApply();
    },
    stateFilterValue() {
      this.filterApply();
    },
    startHolidayDateFilterValue() {
      this.filterApply();
    },
    endHolidayDateFilterValue() {
      this.filterApply();
    }, */
  },
  mounted() {
    // this.getQBReport();
  },
  computed: {
    ...mapState("holidays", {
       allHolidayFields: state => state.holidayRecords,
       holidayClist: state => state.holidayClist,
       holidayReportData: state => state.holidayReportData,

       'allFields': state => state.holidayRecords,
       clist: state => state.holidayClist,
       reportData: state => state.holidayReportData,
       reportDataOriginal: state => state.holidayReportData,
       countryFilter: state => state.holidayFilters.country,
       stateFilter: state => state.holidayFilters.state,
    }),

    objFields() {
      let res = {};
      // this.fields.forEach((val) => {
      //   res[val["id"]] = val;
      // });
      this.allFields.forEach((val) => {
        res[val["id"]] = val;
      });
      return res;
    },
    lookups() {
      let res = [];
      for (const key in this.relationships) {
        if ("lookupFields" in this.relationships[key]) {
          for (const k in this.relationships[key].lookupFields) {
            res.push(this.relationships[key].lookupFields[k].id);
          }
        }
      }
      return res;
    },

    /* countryFilter2() {

    }, */
  },
  methods: {
    formatDateFunc(date, a) {
      console.log(a);
      if (date) {
        return moment(date).format("MM-DD-YYYY"); //.tz("US/Pacific")
      } else {
        return "";
      }
    },

    filterApply() {
      // let res = {};
      // res["373"] = this.chartererNameFilterValue;
      // res["149"] = this.loadPortFilterValue;
      // res["42"] = this.statusFilterValue;
      // this.filterCollect = res;

      if (!!this.countryFilterValue.length || !!this.stateFilterValue.length || !!this.startHolidayDateFilterValue || !!this.endHolidayDateFilterValue) {
        this.reportData = [];
        console.log("filter");
        console.log(this.countryFilterValue);
        console.log(this.stateFilterValue);
        console.log(this.reportDataOriginal);
        for (const key in this.reportDataOriginal) {
          if (Object.hasOwnProperty.call(this.reportDataOriginal, key)) {
            const element = this.reportDataOriginal[key];
            if (
              (!this.countryFilterValue.length ||
                this.countryFilterValue.includes(element[28]["value"])) &&
              (!this.stateFilterValue.length ||
                this.stateFilterValue.includes(element[32]["value"])) &&
              (!this.startHolidayDateFilterValue ||
                moment(element[9]["value"]).add(1, 'days').isSameOrAfter(this.startHolidayDateFilterValue)) &&
              (!this.endHolidayDateFilterValue ||
                moment(element[9]["value"]).isSameOrBefore(this.endHolidayDateFilterValue))
                // this.startHolidayDateFilterValue.includes(element[18]["value"]))
            ) {
              // this.reportData.push(element);
              console.log(element);
            }
          }
        }
      } else {
        this.reportData = [];
        for (const key in this.reportDataOriginal) {
          if (Object.hasOwnProperty.call(this.reportDataOriginal, key)) {
            const element = this.reportDataOriginal[key];
            // this.reportData.push(element);
              console.log(element);

          }
        }
      }
    },

    handleCheckbox(value, rowId, fid) {
      console.log(
        "handleCheckbox(row: " + rowId + " | fid: " + fid + ") " + value
      );
      return;
      /* let params = {};
      let data = [];
      let dataTmp = {};

      if (this.lookups.includes(fid)) {
        for (const relKey in this.relationships) {
          for (const relLFKey in this.relationships[relKey].lookupFields) {
            if (fid == this.relationships[relKey].lookupFields[relLFKey].id) {
              console.log(relKey);
              console.log(relLFKey);
              params["to"] = this.relationships[relKey].parentTableId;
              console.log(this.relationships[relKey].parentTableId);

              // get the key of the parent table on backend side
              // console.log("key for parent: " + this.objFields[this.relationships[relKey].parentTableId].properties.masterTableKeyFid);
              for (const key in this.allFields) {
                // if (Object.hasOwnProperty.call(this.allFields, key)) {
                if (
                  this.allFields[key].id == fid &&
                  this.allFields[key].mode == "lookup"
                ) {
                  console.log("Mode: " + this.allFields[key].mode);
                  console.log(this.allFields[key]);
                  console.log(
                    this.allFields[key].properties.lookupTargetFieldId
                  );
                  console.log(
                    "key: " +
                      this.reportData[rowId][
                        this.allFields[key].properties.lookupReferenceFieldId
                      ].value
                  );

                  dataTmp["key"] =
                    this.reportData[rowId][
                      this.allFields[key].properties.lookupReferenceFieldId
                    ].value;
                  dataTmp[this.allFields[key].properties.lookupTargetFieldId] =
                    value;
                } else if (this.allFields[key].id == fid) {
                  console.log("Mode: " + this.allFields[key].mode);
                  console.log(this.allFields[key]);
                  console.log(
                    this.allFields[key].properties.lookupTargetFieldId
                  );
                  console.log(
                    "key: " +
                      this.reportData[rowId][
                        this.allFields[key].properties.lookupReferenceFieldId
                      ].value
                  );
                  dataTmp["key"] =
                    this.reportData[rowId][
                      this.allFields[key].properties.lookupReferenceFieldId
                    ].value;
                  dataTmp[this.allFields[key].properties.lookupTargetFieldId] =
                    value;
                }
                // }
              }
            }
          }
        }
        data.push(dataTmp);
        params["data"] = data;
        console.log(params);
        console.log(JSON.stringify(params));
        // this.saveDataForQB(params);
        // this.saveDataForQB(params);
      } else {
        console.log("not lookup");
        params["to"] = "br3iujhxx";
        for (const key in this.allFields) {
          // if (Object.hasOwnProperty.call(this.allFields, key)) {
          if (this.allFields[key].id == fid) {
            console.log("Mode: " + this.allFields[key].mode);
            console.log(this.allFields[key]);
            console.log(this.allFields[key].properties.lookupTargetFieldId);
            console.log("key: " + this.reportData[rowId][3].value);
            dataTmp["key"] = this.reportData[rowId][3].value;
            dataTmp[fid] = value;
          }
          // }
        }
        data.push(dataTmp);
        params["data"] = data;
        console.log(params);
        console.log(JSON.stringify(params));
        this.saveDataForQB(params);
      } */
    },
    async saveDataForQB(params) {
      var requestOptions = {
        method: "POST",
        redirect: "follow",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(params), // to, data
      };

      fetch(
        process.env.VUE_APP_API_DOMAIN + "/v1/qb/records/save",
        // process.env.VUE_APP_API_DOMAIN1 + "/v1/qb/records/save",
        // "http://127.0.0.1:8000/v1/qb/records/save",
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          console.log(result);
          // this.clients = result;
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    prepNameForColumnTitle(label) {
      let countDash = label.split("-").length - 1;
      if (countDash > 1) {
        let tmp = label.split("-");
        tmp.shift();
        return tmp.join("-").replace(/formula/g, "");
      } else {
        return label.replace(/formula/gi, "");
      }
    },
    async getQBReport() {
      this.gettingReport = true;
      var requestOptions = {
        method: "POST",
        redirect: "follow",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          filter: this.filter,
          table_id: "bstkuuphz",
          token: "12345",
        }),
      };

      fetch(
        // process.env.VUE_APP_API_DOMAIN +
        process.env.VUE_APP_API_DOMAIN + "/v1/qb/reports/" + 6 + "/bh/run",
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          console.log(result);
          this.reportData = result["body"]["data"];
          this.reportDataOriginal = result["body"]["data"];
          // this.fields = result["body"]["fields"];
          this.fields = result["body"]["fields"];
          this.allFields = result["body"]["allFields"];
          // this.clist = result["body"]["report"]["query"]["fields"];
          this.clist = result["body"]["clist"];
          this.relationships = result["body"]["relationships"];
          this.gettingReport = false;

          let countries = result["body"]["filters"]["country"];

          for (const key in countries) {
            const element = countries[key];
            this.countryFilter.push(element);
          }

          let states = result["body"]["filters"]["state"];
          for (const key in states) {
            const element = states[key];
            this.stateFilter.push(element);
          }
          // this.clients = result;
        })
        .catch((error) => {
          console.log("error", error);
          this.gettingReport = false;
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.tables-panel {
  max-width: 100%;
  overflow-y: hidden;
  overflow-x: auto;
  padding-top: 20px;
}
.tables-items {
  display: flex;
}
.report-panel {
  max-width: 100%;
  overflow-y: hidden;
  overflow-x: auto;
}

.report-body {
  /* height: 100%; */
  /* height: 700px; */
  overflow-y: auto;
  overflow-x: auto;
  position: relative;
  margin-top: 10px;
  /* display: flex; */
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

td {
  border-right: 1px solid #d3dfed;
  border-bottom: 1px solid #d3dfed;
  /* padding: 15px; */
}

thead {
  background: #f6faff;
}

tbody {
  border-top: 1px solid #d3dfed;
  border-left: 1px solid #d3dfed;
}

.report-table {
  /* margin-top: 50px; */
  /* width: 100%; */
  table-layout: fixed;
  border-collapse: collapse;
  /* height: 90vh; */
  position: relative;
}

/* .report-table thead {
  background-color: #333;
  color: #fdfdfd;
  position: sticky;
} */

.report-table thead tr {
  /* display: block; */
  position: relative;
}

th {
  position: sticky;
  z-index: 1;
  /* border-top: 0px;
  border-right: 1px solid rgb(215, 214, 217);
  border-bottom: 1px solid rgb(215, 214, 217); */
  border: 0;
  padding: 5px 10px;
  color: rgb(255, 255, 255);
  -webkit-font-smoothing: antialiased;
  /* background-color: rgb(120, 117, 124); */
  /* background: #fff; */
  cursor: default;
  vertical-align: middle;

  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  color: #2d3757;
  /* text-align: left; */
}

.report-table__head {
  position: sticky;
  z-index: 1;
}

.btn-save {
  display: flex;
  justify-content: end;
}

.btn-save__body {
  background: rgb(106, 141, 255);
  color: #fff;
  font-size: 1.25em;
  padding: 10px 30px;
  margin-right: 20px;
  cursor: pointer;
}
.modal {
  position: absolute;
}

.modal__mask {
  position: fixed;
  z-index: 11;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* display: table; */
  display: flex;
  justify-content: center;
  /* align-items: center; */
  transition: opacity 0.3s ease;
  padding: 20px;
}

.modal__wrapper {
  margin: 40px;
  margin-bottom: 0;
  overflow-y: auto;
  max-height: 100%;
  /* padding: 20px; */
}

.modal__container {
  /* overflow-y: scroll; */
  /* width: 300px; */
  margin: 0px auto;
  padding: 20px 0;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
  width: 1170px;
}

.modal__header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal__header {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #d3dfed;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 20px;
}

.modal__title {
  padding: 5px 20px;
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  color: #2d3757;
}

.modal__close {
  position: absolute;
  top: 12px;
  right: 30px;
  cursor: pointer;
  width: 16px;
  height: 16px;
  background: url(@/assets/close.svg) no-repeat center center;
}

.modal__body {
  margin: 20px 0;
  padding-left: 30px;
  padding-right: 30px;
}

.modal__default-button {
  float: right;
}

.modal__footer {
  display: flex;
  justify-content: space-between;

  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;

  color: #2d3757;
  padding-left: 30px;
  padding-right: 30px;
}

.modal__close-button {
  border: 1px solid #2d3757;
  border-radius: 2px;
  padding: 14px 55px;
  margin-right: 15px;
  cursor: pointer;
}

.modal__save-button {
  background: #2d3757;
  border-radius: 2px;
  padding: 14px 55px;
  color: #ffffff;
  margin-left: 15px;
  cursor: pointer;
}

.preloader {
  display: flex;
  justify-content: center;
}

.report-body::-webkit-scrollbar {
  width: 5px;
  height: 10px;
  background-color: #f9f9fd;
}

.report-body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #2d375744;
}

.report-body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 20px;
  /* border: 3px solid #000; */
  background-color: #e3e9f1;
}

.item-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 5px;
}

.item-modal__title {
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-decoration-line: underline;
  color: #2d3757;
}

.report-table__item {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #2d3757;
  display: block;
  height: 100%;
  width: 100%;
  padding: 15px;
  box-sizing: border-box;
  white-space: nowrap;
}

.item-modal__img {
  margin-left: 12px;
  height: 20px;
  width: 20px;
  background: url(@/assets/share.svg) no-repeat center center;
}

/* filter */

.filter {
  box-sizing: border-box;
  /* padding: 15px; */
  padding-bottom: 15px;
}

.filter__wrap {
  box-sizing: border-box;
  background: #f6faff;
  border: 1px solid #d3dfed;
  border-radius: 2px;
  padding: 10px 10px;
}

.filter__body {
  display: flex;
  align-items: center;
}

.filter__img {
  height: 24px;
  width: 24px;
  background: url(@/assets/filter.svg) no-repeat center center;
}

.filter__title {
  display: flex;
  align-items: center;
  padding-left: 5px;
}

.filter__title-text {
  padding-left: 15px;
  padding-right: 30px;
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #2d3757;
}

.filter__items {
  display: flex;
  flex: 1 1 auto;
  flex-wrap: wrap;
}

.filter__item {
  padding: 10px 10px;
  flex: 1 1 auto;
  width: 40%;
}

.filter__item-title {
  padding-left: 10px;
  padding-bottom: 5px;
  font-size: 14px;
  color: #2d3757;
  font-weight: bold;
}

.filter__body .multiselect {
  width: 100%;
  z-index: 3;
}

.btn-apply {
  /* display: flex; */
  justify-content: end;
}

.btn-apply__body {
  /* background: rgb(106, 141, 255); */
  color: #fff;
  /* font-size: 1.25em; */
  padding: 10px 30px;
  margin-right: 20px;
  cursor: pointer;

  background: #2d3757;
  border-radius: 2px;

  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  /* line-height: 19px; */

  color: #ffffff;
}
</style>
