<template>
  <div class="header">
    <img
      class="header__logo"
      v-bind:class="{
        'header__logo_active': this.notHome,
      }"
      @click="() => routeToHome()"
      alt="Logo"
      src="@/assets/logo.svg"
    />
    <HeaderMenu />
    <!-- <div class="header-bar header__bar">
        <div class="header-bar__item" @click="() => this.showModalAddBimcoHolidays = true">
            <div class="header-bar__item-img header-bar__item-img_flag"></div>
            <div class="header-bar__item-text">Bimco Holidays</div>
        </div>
        <div class="header-bar__item" @click="() => this.$router.push('/')">
            <div class="header-bar__item-img header-bar__item-img_list"></div>
            <div class="header-bar__item-text">Summary</div>
        </div>
        <div class="header-bar__item" @click="() => this.$router.push('/calendar')">
            <div class="header-bar__item-img header-bar__item-img_calendar"></div>
            <div class="header-bar__item-text">Calendar</div>
        </div>
        <div class="header-bar__item log-out" @click="() => logout()">
            <div class="header-bar__item-img header-bar__item-img_user"></div>
            <div class="header-bar__item-text">Log out</div>
        </div>
        </div> -->
  </div>
</template>

<script>
import HeaderMenu from "@/components/menu/HeaderMenu.vue";

export default {
  name: "HeaderLoyout",
  components: {
    HeaderMenu,
  },
  props: {},
  data: function () {
    return {};
  },
  watch: {},
  mounted() {},
  computed: {
    notHome() {
      let res = false;
      let hide = ["main"];
      if (!hide.includes(this.$route.name)) res = true;

      return res;
    },
  },
  methods: {
    routeToHome() {
      let hide = ["main"];
      if (!hide.includes(this.$route.name)) this.$router.push("/");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.header {
  box-sizing: border-box;
  /* height: 80px; */
  background: #2d3757;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 30px;
}

.header__logo {
  max-height: 100%;
  text-align: left;
  height: 50px;
}

.header__logo_active {
  cursor: pointer;
}

.header__bar {
  color: #fff;
  display: flex;
}

.header-bar__item {
  margin: 0 15px;
}

@media only screen and (min-width: 600px) {}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {}

@media only screen and (max-width: 768px) {}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 992px) {
  .header {
    box-sizing: border-box;
    background: #2d3757;
    display: block;
    padding: 8px 14px;
  }

  .header-bar__item {
    margin: 0 8px;
  }
}
</style>
