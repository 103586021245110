<template>
    <div class="sign-in">
        <div class="sign-in__wrap">
            <div class="sign-in__body">
                <div class="left-bar sign-in__left-bar">
                    <img class="left-bar__logo" src="@/assets/logo.png" alt="">
                </div>
                <div class="right-bar sign-in__right-bar">
                    <div class="right-bar__wrap">
                        <div class="right-bar__body">
                            <div class="auth">
                                <div class="auth__header">
                                    <div class="auth__subtitle">Welcome to Newton Commodities</div>
                                    <div class="auth__title">Log Into your Account</div>
                                </div>
                                <div class="auth__body">
                                    <form class="sign-in__form" @submit.prevent="handleSubmit">
                                        <div class="sign-in__wrap-item">
                                            <!-- <p class="sign-in__label">Email</p> -->
                                            <input class="sign-in__input" placeholder="Enter your Email" type="text" name="email" v-model="email">
                                        </div>
                                        <div class="sign-in__wrap-item sign-in__wrap-item_password">
                                            <!-- <p class="sign-in__label">Password</p> -->
                                            <input class="sign-in__input" placeholder="Enter your Password" type="password" name="password" v-model="password">
                                        </div>
                                        <p class="sign-in__error" v-if="!!errorMessage">{{ errorMessage }}</p>
                                        <div class="sign-in__wrap-btn">
                                            <button :class="`sign-in__btn sign-in__btn_${btnState}`">
                                                <span>Log in</span>
                                                <!-- <img src="@/assets/arrow.png" alt="arrow"> -->
                                            </button>
                                        </div>
                                    </form>
                                </div>
                                <div class="auth__footer"></div>
                            </div>
                            
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
        
    </div>
</template>

<script>

export default {
    name: "SigninView",
    data: function() {
        return {
            email: "",
            password: "",
            errorMessage: "",
        }
    },
    components: {
    },
    beforeMount() {
        console.log("beforeMount");
        // if(this.$storew.getters.isLogged)
        //    this.$router.push("/import") 
        if (localStorage.token) {
            // this.name = localStorage.name;
        }
    },
    computed: {
        btnState() {
            if(!this.email || !this.password)
                return "disabled"
            else
                return "active"
        }
    },
    methods: {
        handleSubmit(e) {
            let data = {}

            ;[...e.currentTarget.elements].forEach(el => {
                if(el.name)
                    data[el.name] = el.value
            })

            console.log("Sign In Data:", data)

            fetch(
                process.env.VUE_APP_API_DOMAIN + "/v1/user/auth",
                // process.env.VUE_APP_API_DOMAIN + "/v1/qb/user/auth",
                {
                    method: "POST",
                    headers: { "Content-Type": "application/json"},
                    body: JSON.stringify(data),
                }
            ).then(res => res.json())
            .then(res => {
                console.log("Response:", res)
                /* if(res.code != 200) {
                    this.errorMessage = "Login or password entered incorrectly"
                } else {
                    console.log(data);
                    // this.$store.commit("auth/setUserData", {
                    //     email: data.email,
                    //     firstName: data.firstName,
                    //     lastName: data.lastName,
                    // })
                    localStorage.email = data.email;
                    localStorage.token = res.body.token;
                    localStorage.role = res.body.role;
                    localStorage.charterer = res.body.charterer;
                    // this.$store.commit("auth/setTokens", { accessToken: res.body.token })
                    this.$router.push("/")
                } */
                
                console.log(res);
                console.log(data);
                console.log(data.token);
                // this.$store.commit("auth/setUserData", {
                //     email: data.email,
                //     firstName: data.firstName,
                //     lastName: data.lastName,
                // })
                localStorage.email = res.data.email;
                localStorage.token = res.token;
                localStorage.role = res.data.role;
                // localStorage.charterer = res.body.charterer;
                // this.$store.commit("auth/setTokens", { accessToken: res.body.token })
                this.$router.push("/")
            }).catch(err => {
                console.error(err)
                this.errorMessage = "Login or password entered incorrectly"
            })
        },
    },
}
</script>

<style scoped src="@/styles/views/signin.css"></style>