 <template>
  <div>
    <div class="modal__header">
      <div class="modal__header-content">
        <div class="modal__header-info">
          <p class="modal__header-name">Create Report</p>
          <div class="modal__header-input">
            <p class="modal__header-text">Report Name</p>
            <input type="text" v-model="this.name" placeholder="Name" class="title__data-input">
          </div>
          <div class="modal__header-text">
            <p>Last Save</p> <!-- add field for last save from qb! -->
            <p></p>
          </div>
        </div>

        <div class="modal__header-panel">
          <div class="report-item__buttons" v-on:click="() => this.showDeleteConfirmation(reportId)">
            <div class="report-item__delete-button"></div>
            <p class="report-item__text">Delete</p>
          </div>

          <div class="report-item__buttons" v-on:click="() => this.preview()">
            <CustomLoader v-if="sendingToQB" />
            <div v-else class="save-button__body">
              <div class="report-item__preview-button"></div>
              <p class="report-item__text">Preview</p>
            </div>
          </div>

          <div class="report-item__buttons" v-on:click="() => this.downloadPdf()">
            <CustomLoader v-if="sendingToQB" />
            <div v-else class="save-button__body">
              <div class="report-item__download-button"></div>
              <p class="report-item__text">Download</p>
            </div>
          </div>

          <div
            class="modal__close-button"
            v-on:click="
              () => {
                this.$router.push({
                  name: 'reportGeneratorMainView',
                  params: {},
                });
              }
            "
          >
            <div class="close-button__img"></div>
            <span>Cancel</span>
          </div>
          <div class="modal__save-button" v-on:click="() => this.createReport()">
            <CustomLoader v-if="savingStatus" />
            <div v-else class="save-button__body">
              <div class="save-button__img"></div>
              <span>Save</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="list-panel">
      <div class="list-panel-column">
        <h2 class="list-panel-header">Select Fields</h2>
        <input type="search" class="search" @input="filterList" v-model="searchText" placeholder="Search">
        <div class="first-list custom-scroll">
          <ul>
            <li v-for="(item, index) in firstList" :key="index" @click="selectedItem = item" class="list-item" :class="{ 'selected': selectedItem === item, 'hidden': this.checkVisibleForItem(item) }">
              {{ item.name }}
            </li>
          </ul>
        </div>

      </div>
      <div class="move-btns-panel">
        <div style="width: 50px;">
          <div>
            <button :disabled="!selectedItem" class="control-button" :class="{ 'control-button__disabled': !selectedItem}" @click="moveToSecondList(selectedItem)">
              <img src="../../assets/arrow-white-right.svg" alt="">
            </button>
          </div>
          <div>
            <button :disabled="!selectedItem2" class="control-button" :class="{ 'control-button__disabled': !selectedItem2}" @click="moveToFirstList(selectedItem2)">
              <img src="../../assets/arrow-white-left.svg" alt="">
            </button>
          </div>
        </div>
      </div>
      <div class="list-panel-column">
        <h2 class="list-panel-header">Fields in the Report</h2>
        <input type="search" class="search" @input="filterListForSecond" v-model="secondSearchText" placeholder="Search">
        <div class="second-list custom-scroll">
          <ul>
            <li v-for="(item, index) in secondList" :key="index" @click="selectedItem2 = item" class="list-item" :class="{ 'selected': selectedItem2 === item, 'hidden': this.checkVisibleForItem(item) }">
              {{ item.name }}
            </li>
          </ul>
        </div>
      </div>
      <div class="move-btns-panel">
        <div style="width: 50px;">
          <button :disabled="!selectedItem2" :class="{ 'control-button__disabled': !selectedItem2}" class="control-button" @click="changeOrder(selectedItem2, 'up')">
            <img src="../../assets/arrow-white-up.svg" alt="">
          </button>
          <button :disabled="!selectedItem2" :class="{ 'control-button__disabled': !selectedItem2}" class="control-button" @click="changeOrder(selectedItem2, 'down')">
            <img src="../../assets/arrow-white-down.svg" alt="">
          </button>
        </div>
      </div>
    </div>
    <button v-if="this.filters.length == 0 || this.filterType == null" class="add-filter-button" v-on:click="() => { this.addFiltersItem()}">
      <img src="../../assets/Add_ring.svg" class="filter-item__create-button_img" alt="">
      <p class="add-filter-text">Add Filters</p>
    </button>
    <div v-else class="filter-panel">
      <h2 class="filter-panel__header">Filters</h2>
      <div class="filter-panel__type">
        <Multiselect
          :options="this.filterTypes"
          v-model="this.filterType"
          class="select-custom"
          placeholder="Choose type"
        />
      </div>
      <div class="filter-item" v-for="(item, index) in this.filters" :key="index">
        <div class="filter-item__operator">
          <span v-if="this.filterType == 'Any'">or</span>
          <span v-else-if="this.filterType == 'All'">and</span>
        </div>
          <Multiselect
            :options="this.fields"
            :searchable="true"
            :label="'label'"
            :track-by="'label'"
            trackBy="label"
            v-model="item.fid"
            :multiple="true"
            :clear-on-select="false"
            preserve-search="true"
            clearOnSelect="false"
            placeholder="Choose field"
            :preselect-first="true"
            :valueProp="'id'"
            class="select-custom"
          />

          <Multiselect
            :options="this.filterOperators"
            :searchable="true"
            :label="'name'"
            :track-by="'name'"
            trackBy="name"
            v-model="item.operator"
            placeholder=""
            class="select-value"
          />

        <input type="text" v-model="item.value" class="filter-item__value" placeholder="the value">
        <div class="filter-item__delete-button_img" v-on:click="() => {
            this.removeFilterItem(index);
          }"></div>
        <div class="filter-item__add-button_img" v-on:click="() => {
            this.addFilterItem();
          }"></div>
      </div>
    </div>

    <DeleteConfirmation
        :on-confirm="confirmDelete"
        :on-cancel="cancelDelete"
        :is-report="true"
        v-if="showConfirmation"
    />

    <ShowReportPreviewPopUp
        :url-preview="this.urlPreview"
        :on-cancel="cancelPreview"
        v-if="showPreview"
    />

  </div>
</template>

<script>
import ReportGenerator from '@/api/ReportGenerator';
import CustomLoader from "@/components/loader/CustomLoader.vue";
import Multiselect from "@vueform/multiselect";
import DeleteConfirmation from "@/components/pop-up/DeleteConfirmation.vue";
import ShowReportPreviewPopUp from "@/components/pop-up/ShowReportPreviewPopUp.vue";
// import Multiselect from 'vue-multiselect'

export default {
  data() {
    return {
      name: "",
      firstList: [
        /* { id: 1, name: 'Елемент 1' },
        { id: 2, name: 'Елемент 2' },
        { id: 3, name: 'Елемент 3' } */
      ],
      secondList: [],
      selectedItem: null,
      selectedItem2: null,

      fields: [],
      showConfirmation: false,
      showPreview: false,
      savingStatus: false,

      searchText: "",
      secondSearchText: "",

      filterType: "Any",
      filterTypes: ["Any", "All"],
      filters: [
        /* {
          fid: 3,
          operator: 'CT',
          value: "123",
        } */
      ],
      filterOperators: [
        {
          name: "Contains",
          value: "CT",
        },
        {
          name: "is equal to",
          value: "EX",
        }
      ],
      test: "",
      urlPreview: "",
    };
  },
  mounted() {
    this.init();
  },
  components: {
    CustomLoader,
    // Multiselect,
    Multiselect,
    DeleteConfirmation,
    ShowReportPreviewPopUp,
  },
  watch: {
    /* searchText() {
      this.filterList();
    } */
  },
  props: {
    id: Number,
    open: Boolean, // delete
  },
  methods: {
    async init() {
      /* RecordGenerator.getTableFields("brxisk55z").then((response) => {
        this.fields = response["data"];
      }); */
      let response = await ReportGenerator.getTableFields("brxisk55z");
      this.fields = response["data"];

      this.fields.forEach((item) => {
        this.firstList.push({
          id: item.id,
          name: item.label,
        })
      });
      this.firstList.sort((a, b) => {
        return a.name.localeCompare(b.name);
      })
    },
    moveToSecondList(item) {
      this.secondList.push(item);
      this.firstList = this.firstList.filter(i => i !== item);
      this.selectedItem = null;
    },
    moveToFirstList(item) {
      this.firstList.push(item);
      this.firstList.sort((a, b) => {
        return a.name.localeCompare(b.name);
      })
      this.secondList = this.secondList.filter(i => i !== item);
      this.selectedItem2 = null;
    },
    changeOrder(item, direction) {
      const list = this.secondList;
      const index = list.indexOf(item);
      const newIndex = direction === 'up' ? index - 1 : index + 1;
      if (newIndex >= 0 && newIndex < list.length) {
        list.splice(index, 1);
        list.splice(newIndex, 0, item);
      }
    },
    downloadPdf() {
      let clist = [];
      this.secondList.forEach((item) => {
        clist.push(item.id);
      });
      
      let filterURL = {
        type: this.filterType,
        items: [],
      }
      this.filters.forEach((item) => {
        if(item.fid) {
          filterURL.items.push(item);
        }
      });

      var url = process.env.VUE_APP_API_DOMAIN + "/v1/report-generator/table/brxisk55z/generate?name=" + this.name + "&clist=" + JSON.stringify(clist) + "&filter=" + JSON.stringify(filterURL);

      window.open(url, "_blank");
    },

    leave() {
      // this.$router.push({ name: 'individuals-view' });
    },
    async createReport() {
      if(this.savingStatus) return;
      this.savingStatus = true;
      let clist = [];
      this.secondList.forEach((item) => {
        clist.push(item.id);
      });

      let filterURL = {
        type: this.filterType,
        items: [],
      }
      this.filters.forEach((item) => {
        if(item.fid) {
          filterURL.items.push(item);
        }
      });

      await ReportGenerator.createReport(this.name, "brxisk55z", clist, filterURL);
      this.$router.go(-1)
      this.savingStatus = false;
    },
    filterList() {
      const search = this.searchText.toLowerCase();
      this.firstList.forEach(item => {
        if (item.name.toLowerCase().includes(search)) {
          item.visible = true;
        } else {
          item.visible = false;
        }
      });
    },
    filterListForSecond() {
      const search = this.secondSearchText.toLowerCase();
      this.secondList.forEach(item => {
        if (item.name.toLowerCase().includes(search)) {
          item.visible = true;
        } else {
          item.visible = false;
        }
      });
    },
    checkVisibleForItem(item) {
      if(!("visible" in item)) return false;
      return !item.visible;
    },
    addFiltersItem() {
      this.filters = [];
      this.filterType = null;
      this.filters.push({
          fid: null,
          operator: 'EX',
          value: "",
        });
      this.filterType = 'Any';
    },
    addFilterItem() {
      this.filters.push({
        fid: null,
        operator: 'EX',
        value: "",
      });
    },
    removeFilterItem(index) {
      this.filters.splice(index, 1)
    },
    preview() {
      let clist = [];
      this.secondList.forEach((item) => {
        clist.push(item.id);
      });

      let filterURL = {
        type: this.filterType,
        items: [],
      }
      this.filters.forEach((item) => {
        if(item.fid) {
          filterURL.items.push(item);
        }
      });

      this.urlPreview = process.env.VUE_APP_API_DOMAIN + "/v1/report-generator/table/brxisk55z/generate?name=" + this.name + "&clist=" + JSON.stringify(clist) + "&filter=" + JSON.stringify(filterURL) + "&preview=1";
      this.showPreview = true;
      },

    showDeleteConfirmation() {
      this.showConfirmation = true;
    },

    async confirmDelete() {
      await ReportGenerator.deleteReport(this.id);
      this.$router.go(-1);
      // this.showConfirmation = false;
    },

    cancelDelete() {
      this.showConfirmation = false;
    },

    cancelPreview() {
      this.showPreview = false;
    }
  }
};
</script>

 <style>

 .select-custom, .select-value {
   font-family: Lato;
   font-size: 14px;
   font-style: normal;
   font-weight: 400;
   line-height: 16px;
   color: #2D3757;
   border: 1px solid #D3DFED !important;
   border-radius: 2px !important;
 }

 .select-custom:hover, .select-value:hover {
   border-radius: 2px;
   border: 1px solid #5D88BE !important;
 }

 .select-value {
   margin: 0 12px !important;
   width: 40% !important;
 }

 .select-custom .multiselect-clear-icon, .select-value .multiselect-clear-icon{
   width: 20px;
   height: 20px;
   mask-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M15.5303 5.53033C15.8232 5.23744 15.8232 4.76256 15.5303 4.46967C15.2374 4.17678 14.7626 4.17678 14.4697 4.46967L10 8.93934L5.53033 4.46967C5.23744 4.17678 4.76256 4.17678 4.46967 4.46967C4.17678 4.76256 4.17678 5.23744 4.46967 5.53033L8.93934 10L4.46967 14.4697C4.17678 14.7626 4.17678 15.2374 4.46967 15.5303C4.76256 15.8232 5.23744 15.8232 5.53033 15.5303L10 11.0607L14.4697 15.5303C14.7626 15.8232 15.2374 15.8232 15.5303 15.5303C15.8232 15.2374 15.8232 14.7626 15.5303 14.4697L11.0607 10L15.5303 5.53033Z" fill="%232D3757" stroke="%232D3757" stroke-width="0.5"/></svg>');
   background-color: #2D3757;
 }

 .select-custom:hover .multiselect-caret, .select-value:hover .multiselect-caret {
   background-color: #5D88BE;
 }

 .select-custom .multiselect-dropdown .multiselect-options .multiselect-option.is-selected,
 .select-value .multiselect-dropdown .multiselect-options .multiselect-option.is-selected
 {
   background-color: #5D88BE !important;
 }

 .select-custom .multiselect-dropdown .multiselect-options .multiselect-option.is-selected.is-pointed,
 .select-value .multiselect-dropdown .multiselect-options .multiselect-option.is-selected.is-pointed
 {
   background-color: #5D88BE !important;
 }

 .select-custom.is-active,
 .select-value.is-active {
   box-shadow: 0 0 0 3px #d3dfed !important;
 }

 </style>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

.container {
  display: flex;
}

.list {
  flex: 1;
  padding: 10px;
}

.selected {
  background-color: lightgrey;
}

.list-panel {
  display: flex;
  justify-items: center;
  margin: auto;
  width: 960px;
  box-sizing: border-box;
  border-radius: 2px;
}

.list-panel-header {
  color: #6C6F7D;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  margin-top: 32px;
}

.list-panel-column {
  width: 100%;
}

.first-list, .second-list {
  height: 200px;
  overflow-y: scroll;
  padding: 5px 0px;
  background: #fff;
  border: 1px solid #d3dfed;
  width: 100%;
}

.custom-scroll::-webkit-scrollbar {
  width: 4px;
  height: 10px;
  background-color: #f9f9fd;
}

.custom-scroll::-webkit-scrollbar-thumb {
  background-color: #2D3757;
  width: 4px;
  height: 31px;
}

.custom-scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  padding: 20px;
  background-color: #C0C0C0;
}

.move-btns-panel {
  align-items: center;
  padding: 15px;
  display: flex;
}

.modal__header-panel {
  display: flex;
}

.modal__close-button {
  border: 1px solid #2d3757;
  border-radius: 2px;
  padding: 14px 28px;
  margin-right: 6px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.modal__close-button:hover {
  background-color: #50629b;
  color: white;
}

.modal__close-button:hover .close-button__img {
  filter: invert(100%) brightness(1000%);
}

.close-button__img {
  width: 16px;
  height: 16px;
  background: url(@/assets/btn-close.svg) no-repeat center center;
  margin-right: 10px;
}

.modal__save-button {
  background: #2d3757;
  border-radius: 2px;
  padding: 14px 28px;
  color: #ffffff;
  margin-left: 6px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 58px;
}

.modal__save-button:hover {
  background-color: #50629b;
  outline: none;
}

.save-button__body {
  display: flex;
  align-items: center;
}

.save-button__img {
  width: 16px;
  height: 16px;
  background: url(@/assets/btn-save.svg) no-repeat center center;
  margin-right: 10px;
}

.modal__header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal__header {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  background: #F6FAFF;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 15px;
  flex: 0 0 auto;
}

.modal__header-content {
  padding-top: 25px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-right: 40px;
  align-items: center;
}

.modal__header-info {
  display: flex;
  align-items: flex-end;
}

.modal__header-name {
  font-family: Lato;
  font-size: 24px;
  font-weight: 600;
  line-height: 28px;
  text-align: left;
  margin-right: 30px;
}

.modal__header-text {
  color: #6C6F7D;
  font-weight: 400;
  font-size: 12px;
}

.modal__header-input {
  margin-right: 10px;
}

.report-item__text {
  color: #2D3757;
  font-family: Lato;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  text-align: left;
  margin-right: 10px;
}

.report-item__buttons {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 12px;
}

.report-item__buttons:hover {
  border: 1px solid #5D88BE;
  background-color: #FFF;
  margin: -0.5px 11.5px -0.5px -0.5px;
}

.report-item__delete-button {
  background: #eee;
  width: 32px;
  height: 32px;
  border-radius: 5px;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 32px;
  background: url(@/assets/Trash.svg) no-repeat center center;
  background-size: 24px 24px;
  cursor: pointer;
  margin-left: 5px;
  margin-right: 3px;
  fill: #ff0000;
}

.report-item__download-button {
  background: #eee;
  width: 32px;
  height: 32px;
  border-radius: 5px;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 32px;
  background: url(@/assets/Download_circle_fill.svg) no-repeat center center;
  background-size: 24px 24px;
  margin-left: 5px;
  margin-right: 3px;
}

.report-item__preview-button {
  background: #eee;
  width: 32px;
  height: 32px;
  border-radius: 5px;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 32px;
  background: url(@/assets/btn-preview.svg) no-repeat center center;
  background-size: 24px 24px;
  margin-left: 5px;
  margin-right: 3px;
}

.modal__title {
  padding: 5px 20px;
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  color: #2d3757;
  max-width: 70%;
  justify-items: center;
  text-align: left;
  flex-grow: 1;
}

.modal__close {
  position: absolute;
  top: 12px;
  right: 30px;
  cursor: pointer;
  width: 16px;
  height: 16px;
  background: url(@/assets/close.svg) no-repeat center center;
}

.modal__body {
  flex: 1 1 auto;
  overflow-y: auto;
  flex-direction: column;
  display: flex;
}

.title__data-input {
  border: 1px solid #D3DFED;
  border-radius: 2px;
  min-width: 350px;
  font-family: Lato;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  display: flex;
  padding: 8px 11px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  cursor: pointer;
}

.title__data-input:hover {
  border: 1px solid #5D88BE;
}

.title__data-input::selection {
  border: 2px solid #5D88BE;
}

.filter-panel {
  width: 960px;
  max-width: 100%;
  margin: auto;
}

.filter-panel__header {
  color: #6C6F7D;
  font-family: Lato;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  margin: 24px 0;
}

.buttons-panel {
  display: flex;
  width: 960px;
  max-width: 100%;
  justify-content: space-around;
  margin: 20px auto;
}

.list-item {
  text-decoration: none;
  list-style: none;
  display: block;
  padding: 3px 10px;
  cursor: pointer;
  user-select: none;
}

.list-item:hover {
  background-color: #EEEEEE;
}

.control-button {
  box-sizing: border-box;
  background: #2d3757;
  border-radius: 2px;
  padding: 10px 11px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-top: 28px;
}

.control-button:hover {
  background: #3F4D79;
}

.hidden {
  display: none;
}

.search {
  display: block;
  width: 100%;
  padding: 5px 11px;
  margin: 8px 0;
  border: 1px solid #D3DFED;
  border-radius: 2px;
}

.search:hover {
  border: 1px solid #5D88BE;
}

.search::selection {
  border: 2px solid #5D88BE;
}

.filter-item {
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.filter-item__operator {
  padding: 0 12px;
  color: #6C6F7D;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
}

.filter-panel__type {
  margin-top: 10px;
  margin-bottom: 24px;
  width: 124px;
}

.filter-item__value {
  all: unset;
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  background: none;
  color: inherit;
  font: inherit;
  line-height: inherit;
  box-shadow: none;
  width: auto;

  border: 1px solid #d3dfed;
  border-radius: 2px;
  padding: 10px;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
}

.filter-item__value:hover {
  border-radius: 2px;
  border: 1px solid #5D88BE;
}

.filter-item__value:focus {
  border: 1px solid #5D88BE;
  box-shadow: 0 0 0 3px #d3dfed;
}

.filter-item__value::placeholder {
  color: #2D3757;
}

.filter-item__add-button_img {
  width: 120px;
  height: 40px;
  border-radius: 2px;
  background: url(@/assets/Add_ring.svg) no-repeat center center;
  background-color: #2d3757;
  cursor: pointer;
  margin-left: 10px;
}

.filter-item__add-button_img:hover {
  background-color: #3F4D79;
}

.filter-item__delete-button_img {
  width: 120px;
  height: 40px;
  border-radius: 2px;
  background: url(@/assets/Remove.svg) no-repeat center center;
  background-color: #2d3757;
  margin-left: 10px;
  cursor: pointer;
}

.filter-item__delete-button_img:hover {
  background-color: #3F4D79;
}

.filter-item__create-button_img {
  margin: auto;
  width: 16px;
  height: 16px;
  margin-right: 6px;
  transition: filter 0.3s ease;
  filter: invert(0%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(0%) contrast(100%);
}

.add-filter-button {
  color: #2d3757;
  display: flex;
  background: none;
  border-radius: 2px;
  border: 1px solid #5D88BE;
  margin-left: calc(100% - (960px + ((100% - 960px) / 2)));
  margin-top: 40px;
  padding: 8px;
  cursor: pointer;
}

.add-filter-button:hover {
  background-color: #F6FAFF;
}

.add-filter-text {
  margin: auto;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
}

</style>

<style src="@vueform/multiselect/themes/default.css"></style>
