import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import mitt from 'mitt';
const emitter = mitt();

import axios from 'axios'
import VueAxios from 'vue-axios'

import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

// import { SetupCalendar, Calendar, DatePicker } from 'v-calendar';
import { SetupCalendar, Calendar } from 'v-calendar';

const app = createApp(App);

app.config.globalProperties.emitter = emitter;

app.use(store).use(router);
app.component('DatePicker', Datepicker);

app.use(SetupCalendar, {})
app.component('VCalendar', Calendar)
// app.component('DatePicker', DatePicker)

app.use(VueAxios, axios)

app.mount('#app');

// createApp(App).use(store).use(router).mount('#app')
